import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useLazyLoadSelector = (selectorFunc, func, flags = [], byKeys = true) => {
  const dispatch = useDispatch();
  const selector = useSelector(selectorFunc);

  useEffect(() => {
    if (byKeys) {
      if (Object.keys(selector).length === 0) {
        dispatch(func());
      }
    } else {
      if (selector.length === 0) {
        dispatch(func());
      }
    }
    
  }, flags);

  return selector;
}