import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, IconButton, Toolbar, Collapse } from '@material-ui/core';
// import SortIcon from '@material-ui/icons/Sort';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link as Scroll } from 'react-scroll';
import ScrollToColor from './ColorChangeOnScroll';
import Button from '@material-ui/core/Button';
// import Box from '@material-ui/core/Box';
//import '../../Styles/Styles.css';
import { useMediaQuery } from 'react-responsive'
import { slide as Menu } from 'react-burger-menu'
import "../../../style/burgermenustyle.css";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Nunito',
  },
  appbar: {
    position: 'fixed',
    zIndex: '9999',
    background: 'black',
  },
  appbarTitle: {
    flexGrow: '1',
    fontSize: '2vw',
  },
  appbarMobileTitle: {
    flexGrow: '1',
    fontSize: '4vw',
  },
  appbarTitle2: {
    flexGrow: '1',
    fontSize: '1.5vw',
  },
  appbarMobileTitle2: {
    flexGrow: '1',
    fontSize: '3vw',
    marginLeft: '10px',
  },
  appbarWrapper: {
    width: '100%',
    margin: '0 auto',
  },
  icon: {
    // color: "#34cbc2",
    color: '#2bbcd4',
    fontSize: '2vw',
  },
  colorText: {
    color: '#2bbcd4',
  },
  container: {
    textAlign: 'center',
    height: '80vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerMobile: {
    textAlign: 'center',
    height: '100vh',
    marginTop: '-50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    color: '#fff',
    fontSize: '4vw',
  },
  goDown: {
    // color: "#34cbc2",
    color: '#2bbcd4',
    fontSize: '4vw',
  },
  button: {
    borderRadius: 30,
    background: '#2bbcd4',
  },
  buttontext: {
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    fontSize: '1vw',
    color: 'white',
  },
  links: {
    textDecoration: 'none',
    color: 'inherit',
  },
  rightToolbar: {
    marginLeft: 'auto',
    marginRight: -12,
    display: 'flex',
  },
}));

export default function Header() {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  const isDesktop = useMediaQuery({ query: '(min-width: 740px)'})
  const isMobile = useMediaQuery({ query: '(max-width: 740px)'})


  useEffect(() => {
    setChecked(true);
  }, []);
  return (
    <section>
      <div className={classes.root} id="header">
        <ScrollToColor>
          <AppBar className={classes.appbar} elevation={0}>
            <Toolbar className={classes.appbarWrapper}>
            {isDesktop &&
              <a href="/landing" className={classes.links}>
                <h1 className={classes.appbarTitle}>
                  Conservation X Labs:{' '}
                  <span className={classes.colorText}>Sentinel</span>
                </h1>
              </a>}

             
              {isDesktop && 
              <section className={classes.rightToolbar}>
                <a
                  href="https://conservationxlabs.com/contact" className={classes.links}
                >
                 
                  <h2
                    className={classes.appbarTitle2}
                    style={{ marginRight: '50px' }}
                  >
                    Contact Sales
                  </h2>
                </a>
                <a
                  href="/login"
                  className={classes.links}
                >
                  <h2
                    className={classes.appbarTitle2}
                    style={{ marginRight: '50px' }}
                  >
                    Sign In
                  </h2>
                </a>
                <Button
                  className={classes.button}
                  size="large"
                  variant="contained"
                  href="/signup"
                  style={{ marginRight: '10px' }}
                >
                  <span className={classes.buttontext}>
                    Get started for free{' '}
                  </span>
                </Button>
              </section>}

              {isMobile &&
              <a href="/landing" className={classes.links}>
                <h1 className={classes.appbarMobileTitle}>
                  Conservation X Labs:{' '}
                  <span className={classes.colorText}>Sentinel</span>
                </h1>
              </a>}

              {isMobile && 
              <section className={classes.rightToolbar}>
               <Menu right width={ '50%' } style={{ background: '#FFF' }}>
               <a
                  href="https://conservationxlabs.com/contact" className={classes.links}
                >
                 
                  <h2
                    className={classes.appbarMobileTitle2}
                  >
                    Contact Sales
                  </h2>
                </a>        
                <a
                  href="/login"
                  className={classes.links}
                >
                  <h2
                    className={classes.appbarMobileTitle2}
                  >
                    Log In
                  </h2>
                </a>
                <a
                  href="/signup"
                  className={classes.links}
                >
                  <h2
                    className={classes.appbarMobileTitle2}
                  >
                    Sign Up
                  </h2>
                </a>               
                </Menu>
              </section>}
            </Toolbar>
          </AppBar>
        </ScrollToColor>

        <Collapse
          in={checked}
          {...(checked ? { timeout: 1000 } : {})}
          collapsedHeight={50}
        >
          {isDesktop &&
          <div id="mainheader" className={classes.container}>
            <h1 className={classes.title}>
              The power of machine learning <br />
              at{' '}
              <span className={classes.colorText}>the click of a button.</span>
            </h1>

            <Scroll to="info-card" smooth={true}>
              <IconButton>
                <ExpandMoreIcon className={classes.goDown} />
              </IconButton>
            </Scroll>
          </div>}

          {isMobile &&
          <div id="mainheader" className={classes.containerMobile}>
            <h1 className={classes.title}>
              The power of machine learning <br />
              at{' '}
              <span className={classes.colorText}>the click of a button.</span>
            </h1>

            <Scroll to="info-card" smooth={true}>
              <IconButton>
                <ExpandMoreIcon className={classes.goDown} />
              </IconButton>
            </Scroll>
          </div>}

        </Collapse>
      </div>
    </section>
  );
}
