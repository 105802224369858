import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import { Typography } from '@material-ui/core';
import { dropDeviceUpdate, fetchDeviceUpdates } from '../../actions/deviceUpdateActions';
import { useDispatch } from 'react-redux';
import { fetchDevice } from '../../actions/deviceActions';

export default function UpdateDropModal(props) {
  const { updateId, devices, open, handleClose } = props;
  const dispatch = useDispatch();

  const handleDrop = async () => {
    await dropDeviceUpdate(updateId);
    dispatch(fetchDeviceUpdates());
    dispatch(fetchDevice());
    handleClose(true);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Drop Update: {updateId}</DialogTitle>
        <DialogContent>
        <Grid item xs={12}>
          <Typography variant='body1'>{`Assigned Devices: ${devices.map((device) => device.deviceId).join(" ,")}`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1'>Would you like to drop this device update?</Typography>
          <Typography variant='body1' color="secondary">A dropped update will no longer be processed by devices, but will still exist on this page with the dropped status.</Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleDrop}>
          Drop
        </Button>
      </DialogActions>
    </Dialog>
  );
}
