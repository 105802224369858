import { Box, Button, Card, Checkbox, Chip, FormControlLabel, FormGroup, ListItemText, MenuItem, Paper, Switch, TextField, Typography, withStyles, Icon, IconButton} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { NotificationConstants } from '../../../util/constants';
import { Autocomplete } from '@material-ui/lab';
import NotificationSection from './NotificationSection';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
// Style
const styles = (theme) => ({
  filterContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  row: {
    padding: 0,
    margin: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  icon: {
    marginLeft: "20px",
    flexShrink: 0
  },
  addComponentButton: {
    marginTop: "10px"
  },
  componentBox: {
    marginTop: "10px",
    padding: "5px",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    width: "100%"
  }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  getContentAnchorEl: () => null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
};

const blankComponent = {
  group: null,
  groupCompoundName: "",
  notificationClasses: []
}

const GroupsSection = (props) => {
  const { classes, notificationGroups, availableGroups, availableClasses, onGroupChange } = props;
  const [processedNotificationGroups, setProcessedNotificationGroups] = useState([{...blankComponent}]);
  const [groupIdMap, setGroupIdMap] = useState({});
  const [groupNameMap, setGroupNameMap] = useState({});

  const saveGroupNotifications = (values) => {
    setProcessedNotificationGroups(values);
    const rawGroupNotifications = [];
    for (const value of values) {
      if (value && value.group && value.notificationClasses.length > 0) {
        rawGroupNotifications.push({
          groupId: value.group.group_id,
          groupNotificationClasses: value.notificationClasses ? value.notificationClasses :  [NotificationConstants.NONE] 
        });
      }
    }
    onGroupChange(rawGroupNotifications);
  }

  const handleSelectedClassChange = (index, value) => {
    const newGroups = [...processedNotificationGroups];
    newGroups[index].notificationClasses = value;
    saveGroupNotifications(newGroups);
  };

  const handleSelectedDeviceGroupChange = (index, value) => {
    const newGroups = [...processedNotificationGroups];
    newGroups[index].groupCompoundName = value;
    if (Object.keys(groupNameMap).includes(value)) {
      newGroups[index].group = groupNameMap[value];
    } else {
      newGroups[index].group = null;
    }
    saveGroupNotifications(newGroups);
  };

  const getGroupCompoundName = (group) => {
    return `${group.group_id.toString()} - ${group.group_name}`;
  }

  const addGroupNotification = () => {
    saveGroupNotifications([...processedNotificationGroups, JSON.parse(JSON.stringify(blankComponent))]);
  }

  const deleteGroupNotification = (index) => {
    const newGroups = [...processedNotificationGroups];
    newGroups.splice(index, 1);
    if (newGroups.length === 0) {
      newGroups.push({...blankComponent});
    }
    saveGroupNotifications(newGroups);
  }

  useEffect(() => {
    if (availableGroups) {
      const newGroupNameMap = {};
      const newGroupIdMap = {};
      for (const group of availableGroups) {
        newGroupNameMap[getGroupCompoundName(group)] = group;
        newGroupIdMap[group.group_id] = group;
      }
      setGroupIdMap(newGroupIdMap);
      setGroupNameMap(newGroupNameMap);
    }
  }, [availableGroups]);

  useEffect(() => {
    if (notificationGroups && groupIdMap) {
      const processedGroups = [];
      for (const group of notificationGroups) {
        const groupId = group.groupId.toString();
        processedGroups.push({
          group: Object.keys(groupIdMap).includes(groupId) ? groupIdMap[groupId] : null,
          groupCompoundName: Object.keys(groupIdMap).includes(groupId) ? getGroupCompoundName(groupIdMap[groupId]) : "",
          notificationClasses: group.groupNotificationClasses || []
        });
      }
      if (processedGroups.length === 0) {
        processedGroups.push({...blankComponent});
      }
      console.log(processedGroups)
      setProcessedNotificationGroups(processedGroups);
    }
  }, [notificationGroups, groupIdMap]);
 
  return (
    <div className={classes.filterContainer}>
      {
        processedNotificationGroups.map((processedGroup, index) => {
          return (
            <div className={classes.row}>
              <div className={classes.componentBox}>
                <Autocomplete
                  onChange={(event, value) => handleSelectedDeviceGroupChange(index, value)}
                  value={processedGroup.groupCompoundName}
                  options={availableGroups.filter((group) => !processedNotificationGroups.map((notificationGroup) => notificationGroup?.group?.group_id).includes(group.group_id)).map((group) => getGroupCompoundName(group))}
                  renderInput={(params) => <TextField {...params} label="Device Group" />}
                />
                <NotificationSection notificationClasses={processedGroup.notificationClasses} onNotificationChange={(value) => handleSelectedClassChange(index, value)} availableClasses={availableClasses}/>
              </div>
              {index < processedNotificationGroups.length - 1 ?
                (
                  <IconButton className={classes.icon} onClick={() => deleteGroupNotification(index)}>
                    <DeleteIcon/>
                  </IconButton>
                ) :
                (
                  <IconButton className={classes.icon} disabled={!(processedGroup.group && processedGroup.notificationClasses && processedGroup.notificationClasses.length > 0)} onClick={addGroupNotification}>
                    <AddIcon/>
                  </IconButton>
                )
              }
            </div>
          );
        })
      }
    </div>
  );
};

export default withStyles(styles)(GroupsSection);
