import React, {useState} from 'react';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles } from '@material-ui/core';
import CustomTableFilters from '../../Common/Table/CustomTableFilters';
import CustomizeDashboard from './CustomizeDashboard';
import DownloadPDFModal from './downloadPDF';
import { useMediaQuery } from 'react-responsive';
import ButtonFilters from '../../Common/Table/ButtonFilters';
import { FormControlLabel, Switch } from '@mui/material';

const useStyles = makeStyles({
  spacing: {
    marginRight: "10px"
  }
});

export default function DashboardToolbar(props) {
  const { headers, updateFilters, filters, selectedWidgets, setSelectedWidgets, showArchived, setShowArchived } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 625px)' });

  const classes = useStyles();

  const handleArchivedToggle = () => {
    setShowArchived(!showArchived);
  }

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 }
      }}
    >
      <CustomTableFilters headers={headers} filters={filters} updateFilters={updateFilters} ButtonFilters={ButtonFilters} handleArchivedToggle={handleArchivedToggle} showArchived={showArchived}/>
      <div className={classes.spacing} />
      {!isMobile && <DownloadPDFModal element="#pdf-container" />}
      <div style={{ flex: 1 }} />
      {
        !isMobile ?
        <FormControlLabel checked={showArchived} onChange={handleArchivedToggle} control={<Switch />} label="Show archived" />
        : null
      }
      <CustomizeDashboard selectedWidgets={selectedWidgets} setSelectedWidgets={setSelectedWidgets} />
    </Toolbar>
  );
}