import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { updateDeploy } from '../../../actions/deviceActions';
import { getDisplayName } from '../../../util/deviceHelpers';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
    minWidth: 150,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  rootSelect: {
    padding: '0px 1px',
  },
  inputLabel: {
    fontSize: 13,
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      // maxHeight: 400,
      // minWidth: 250,
      // maxWidth: 300,
    },
  },
  getContentAnchorEl: null,
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
};

export default function DeviceDrawer(props) {
  const classes = useStyles();
  const { algId } = props;

  const deviceData = useSelector((state) => state.device.deviceData); // list of all devices the user has access to

  /** @type {DeployedModelData[]} A list of all device / model pairs that the user has access to */
  const deployedModelData = useSelector(
    (state) => state.device.deployedModelData
  );

  /** Ids of devices that have thie model deployed */
  const deployedDevicesId = useMemo(() => {
    const devIds = deployedModelData
      .slice()
      .filter((d) => d.alg_id === algId)
      .map((d) => d.device_id);
    return [...new Set(devIds)];
  }, [deployedModelData]);

  const dispatch = useDispatch();

  // Save changes
  const handleChange = (event) => {
    // new list of device ids to deploy model to
    const newSet = new Set(event.target.value);

    // original list of device ids the model was deployed to
    const origSet = new Set(deployedDevicesId);

    // subtract newSet - origSet to determine if a new device/model pair should be added
    let toAdd = [...event.target.value].filter((x) => !origSet.has(x));

    // subtract origSet - newSet to determine if a device/model should be deleted
    let toDel = [...deployedDevicesId].filter((x) => !newSet.has(x));

    console.log('toADd ', toAdd);
    console.log('toDel ', toDel);

    if (toAdd.length > 0) {
      dispatch(
        updateDeploy({
          alg_id: algId,
          device_id: toAdd[0],
          deployed_status: 'Deploy',
        })
      );
    }

    if (toDel.length > 0) {
      dispatch(
        updateDeploy({
          alg_id: algId,
          device_id: toDel[0],
          deployed_status: 'Remove',
        })
      );
    }
  };

  return (
    <FormControl
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      variant="outlined"
      size="small"
      className={classes.formControl}
    >
      <InputLabel id="demo-mutiple-checkbox-label" align="left" margin="dense">
        Select Devices
      </InputLabel>
      <Select
        multiple
        labelId="demo-mutiple-checkbox-label"
        label="Select Models"
        value={deployedDevicesId}
        onChange={handleChange}
        renderValue={(selected) => {
          if (selected.length === 1) {
            const label =
              getDisplayName(deviceData.find((d) => d.device_id === selected[0])) || 'Unnamed';
            return label;
          } else if (selected.length > 1) {
            return 'Multiple Devices';
          } else {
            return 'None';
          }
        }}
        MenuProps={MenuProps}
        className={classes.rootSelect}
      >
        {deviceData.map((device, index) => (
          <MenuItem
            key={`${device.device_id}-deviceDrawer`}
            value={device.device_id}
          >
            <Checkbox checked={deployedDevicesId.includes(device.device_id)} />
            <ListItemText primary={getDisplayName(device)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
