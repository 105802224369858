import React, { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import { MenuItem, withStyles } from '@material-ui/core';

import AccountCircle from '@material-ui/icons/AccountCircle';
import { Page } from '../../../util/constants';

const styles = (theme) => ({});

const MenuIcon = (props) => {
  const { classes, logoutHandler, setTab, tab } = props;

  const anchorName = "account-user";
  const ariaLabel = "account-user";

  const [anchorEl, setAnchorEl] = useState({});

  const handleClick = (event) => {
    setAnchorEl({
      ...anchorEl,
      [event.currentTarget.name]: event.currentTarget,
    });
  };

  const handleClose = (event) => {
    setAnchorEl({});
  };

  return (
    <React.Fragment>
      <IconButton
        aria-label={ariaLabel}
        color="inherit"
        onClick={handleClick}
        name={anchorName}
        aria-controls={ariaLabel}
        aria-haspopup="true"
      >
        <AccountCircle />
      </IconButton>

      <Menu
        anchorEl={anchorEl[anchorName]}
        keepMounted
        getContentAnchorEl={null}
        name={anchorName}
        open={!!anchorEl[anchorName]}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem
          selected={tab === 'account'}
          button
          key="Account"
          onClick={() => {
            setTab([Page.ACCOUNT]);
            handleClose();
          }}
        >
          My Account
        </MenuItem>
        <MenuItem button key="Logout" onClick={logoutHandler}>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default withStyles(styles)(MenuIcon);
