import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import { useLazyLoadSelector } from '../../util/redux';
import TabContentContainer from '../Common/ContentContainers/TabContentContainer';
import HomePageWrapper from '../Common/PageWrappers/HomePageWrapper';
import { fetchAlgReleases, fetchAlgs, fetchDevices, fetchUsers } from '../../actions/internalActions';
import UserAccounts from '../Internal/userAccounts';
import AllDevices from '../Internal/allDevices';
import AlgMetadata from '../Internal/algMetadata';
import AlgReleases from '../Internal/algRelease';

const styles = (theme) => ({
  toolbar: theme.mixins.toolbar
});

const Internal = (props) => {
  const { classes } = props;

  const tabs = [
    {
      label: "User Accounts",
      content: (<UserAccounts/>)
    },
    {
      label: "Devices",
      content: (<AllDevices/>)
    },
    {
      label: "Algs",
      content: (<AlgMetadata/>)
    },
    {
      label: "Alg Releases",
      content: (<AlgReleases/>)
    }
  ];

  useLazyLoadSelector((state) => state.internal.users, fetchUsers, [], false);
  useLazyLoadSelector((state) => state.internal.devices, fetchDevices, [], false);
  useLazyLoadSelector((state) => state.internal.algs, fetchAlgs, [], false);
  useLazyLoadSelector((state) => state.internal.algReleases, fetchAlgReleases, [], false);

  return (
    <HomePageWrapper>
      <TabContentContainer tabs={tabs}/>
    </HomePageWrapper>
  );
};

export default withStyles(styles)(Internal);
