import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { Page } from '../../util/constants';

import Avatar from '@material-ui/core/Avatar';
import logo from '../../images/CXL_knockout.png';
import MobileMenuIcon from '@mui/icons-material/Menu';

// style imports
import {
  warningColor,
  primaryColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
} from '../../style/style';
import MenuIcon from './Icons/MenuIcon';
import HelpIcon from './Icons/HelpIcon';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: '-webkit-sticky',
    position: 'sticky !important',
    top: 0,
    alignSelf: 'flex-star',
    zIndex: 1000,
  },
  grow: {
    flexGrow: 1,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  warningAppbarHeader: {
    color: warningColor[0],
  },
  primaryAppbarHeader: {
    color: primaryColor[0],
  },
  dangerAppbarHeader: {
    color: dangerColor[0],
  },
  successAppbarHeader: {
    color: successColor[0],
  },
  infoAppbarHeader: {
    background: infoColor[0],
    color: 'white',
  },
  roseAppbarHeader: {
    background: roseColor[0],
  },
  grayAppbarHeader: {
    color: grayColor[0],
  },
  title: {
    [theme.breakpoints.down('sm')]: { display: 'none' },
  },
  mobileMenu: {
    [theme.breakpoints.up('md')]: { display: 'none' },
  },
  logo: {
    [theme.breakpoints.up('md')]: { 
      maxHeight: 38,
      maxWidth: 280,
      objectFit: "contain",
      width: '60%',
      height: '55%',
    },
    [theme.breakpoints.down('sm')]: { 
      maxHeight: 38,
      maxWidth: 100,
      objectFit: "contain",
      width: '60%',
      height: '55%',
    },
    
  },
}));

export default function Appbar(props) {
  /** @type {{ setTab: React.Dispatch<React.SetStateAction<Page>> }} */
  const { setTab, setCollapseNav, logoutHandler, tab } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />

      {/* FIRST... THE NAVBAR */}
      <AppBar>
        <Toolbar>
          <IconButton
            color="inherit"
            className={classes.mobileMenu}
            onClick={() => {
              setCollapseNav(false);
            }}
          >
            <MobileMenuIcon />
          </IconButton>
          <img className={classes.logo} src={logo}>
            {/* <LockOutlinedIcon /> */}
          </img>

          <div className={classes.grow} />

          <HelpIcon />
          <MenuIcon setTab={setTab} tab={tab} logoutHandler={logoutHandler}/>
        </Toolbar>
      </AppBar>
    </div>
  );
}

// <AppBar position="fixed" className={classes.appBar}>
// <Toolbar>

// </Toolbar>
// </AppBar>
