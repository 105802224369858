import React, { useState, useEffect } from 'react';

import WidgetCommon from '../Common/widgetCommon'
import { withStyles } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import { grayColor } from '../../../style/style';

const styles = (theme) => ({});

const TotalClasses = (props) => {
    const { isLoading, insights } = props;
    const [totalClasses, setTotalClasses] = useState(null);

    useEffect(() => {
        if (insights) {
            setTotalClasses(insights.map(insight => insight.class_name).filter((value, index, self) => self.indexOf(value) === index).length);
        }
    }, [insights])

    return (
        <WidgetCommon height="17vh" maxHeight="200px" title="Total Classes" isLoading={isLoading} hasNoData={!totalClasses} >
            <br></br>
            <Typography variant="h2" style={{color: grayColor[2]}}>
                {totalClasses}
            </Typography>
        </WidgetCommon>
    )
}

export default withStyles(styles)(TotalClasses);