import { Action } from "./actionsShared";

export const FETCH_USER_FEATURES_PREFIX = "FETCH_USER_FEATURES";
export const UPDATE_USER_FEATURE_PREFIX = "UPDATE_USER_FEATURE";

export const fetchUserFeatures = () => {
  const url = "/api/features";
  return Action.GET(FETCH_USER_FEATURES_PREFIX, url, true);
};

export const updateUserFeature = (featureId, enabled) => {
  const url = `/api/features/${featureId}`;
  return Action.PUT(UPDATE_USER_FEATURE_PREFIX, url, { enabled }, true, {featureId, enabled});
};
