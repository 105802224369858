import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CommunicationCard from './CommunicationCard';
import info from './CommunicationCardInfo';
import useWindowPosition from '../../hook/useWindowPosition';
import AlgorithmImage from '../../../images/DeerAlgorithm.png';
import { useMediaQuery } from 'react-responsive'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  image: {
    borderRadius: 20,
    width: '50vw',
  },
  mobileImage: {
    borderRadius: 20,
    width: '80vw',
  },
}));
export default function () {
  const classes = useStyles();
  const checked = useWindowPosition('header');

  const isDesktop = useMediaQuery({ query: '(min-width: 1224px)'})
  const isMobile = useMediaQuery({ query: '(max-width: 1224px)'})

  return (
    <section id="communicationCard" data-scroll-id="communicationCard">
    <div className={classes.root} id="communication-card">
      <CommunicationCard place={info[0]} checked={checked} />
      {isDesktop && <img src={AlgorithmImage} className={classes.image} alt="" />}
      {isMobile && <img src={AlgorithmImage} className={classes.mobileImage} alt="" />}
    </div>
    </section>
  );
}