export const getDisplayName = (device) => {
  if (!device) {
    return 'Unnamed';
  }
  return device.display_name ? device.display_name : device.device_name;
};

export const getFrequencyInformation = (time) => {
  const freqUnits = time >= 60 ? 'Hour' : 'Minute';
  const freqValue = time >= 60 ? time / 60 : time;
  const freqString = `${freqValue} ${freqUnits}${freqValue !== 1 ? 's' : ''}`;
  return {
    freqUnits,
    freqValue,
    freqString
  };
};