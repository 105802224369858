import axios from 'axios';
import { server } from '../actions/backend-url';

export const FETCH_USER_BEGIN = 'FETCH_USER_BEGIN';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const CHANGE_PASSWORD_BEGIN = 'CHANGE_PASSWORD_BEGIN';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const UPDATE_USER_BEGIN = 'UPDATE_USER_BEGIN';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const UPDATE_USER_PHOTO_BEGIN = 'UPDATE_USER_PHOTO_BEGIN';
export const UPDATE_USER_PHOTO_SUCCESS = 'UPDATE_USER_PHOTO_SUCCESS';
export const UPDATE_USER_PHOTO_FAILURE = 'UPDATE_USER_PHOTO_FAILURE';

export const FETCH_CARD_INFO_BEGIN = 'FETCH_CARD_INFO_BEGIN';
export const FETCH_CARD_INFO_SUCCESS = 'FETCH_CARD_INFO_SUCCESS';
export const FETCH_CARD_INFO_FAILURE = 'FETCH_CARD_INFO_FAILURE';

export const UPDATE_CARD_INFO_BEGIN = 'UPDATE_CARD_INFO_BEGIN';
export const UPDATE_CARD_INFO_SUCCESS = 'UPDATE_CARD_INFO_SUCCESS';
export const UPDATE_CARD_INFO_FAILURE = 'UPDATE_CARD_INFO_FAILURE';

export const FETCH_CHARGES_BEGIN = 'FETCH_CHARGES_BEGIN';
export const FETCH_CHARGES_SUCCESS = 'FETCH_CHARGES_SUCCESS';
export const FETCH_CHARGES_FAILURE = 'FETCH_CHARGES_FAILURE';

export const fetchUserBegin = () => {
  return {
    type: FETCH_USER_BEGIN,
  };
};

export const fetchUserSuccess = (response) => {
  return {
    type: FETCH_USER_SUCCESS,
    payload: { response },
  };
};

export const fetchUserFailure = (error) => {
  return {
    type: FETCH_USER_FAILURE,
    payload: { error },
  };
};

export function fetchUser() {
  return (dispatch) => {
    dispatch(fetchUserBegin());
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    return axios
      .get(`${server}/api/user`)
      .then((response) => {
        dispatch(fetchUserSuccess(response));
        return response;
      })
      .catch((error) => dispatch(fetchUserFailure(error)));
  };
}

export const changePasswordBegin = () => {
  return {
    type: CHANGE_PASSWORD_BEGIN,
  };
};

export const changePasswordSuccess = (response) => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: { response },
  };
};

// generate change password actions
export const changePasswordFailure = (error) => {
  return {
    type: CHANGE_PASSWORD_FAILURE,
    payload: { error },
  };
};

export function changePassword(currPassword, newPassword) {
  return (dispatch) => {
    dispatch(changePasswordBegin());
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    return axios
      .post(`${server}/api/changePassword`, {
        currPassword: currPassword,
        newPassword: newPassword,
      })
      .then((response) => {
        dispatch(changePasswordSuccess());
        return response;
      })
      .catch((error) => {
        if (error.response.status === 400) {
          dispatch(changePasswordFailure(
            "Your password does not meet the defined requirements"));
        } else if (error.response.status === 403) {
          dispatch(changePasswordFailure(
            "The entered password is not correct"));
        } else {
          dispatch(changePasswordFailure(error));
        }
      })
  };
}

export const updateUserBegin = () => {
  return {
    type: UPDATE_USER_BEGIN,
  };
};

export const updateUserSuccess = (user) => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: { user },
  };
};

export const updateUserFailure = (error) => {
  return {
    type: UPDATE_USER_FAILURE,
    payload: { error },
  };
};

export function updateUser(user) {
  return (dispatch) => {
    dispatch(updateUserBegin());
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    return axios
      .post(`${server}/api/user`, user)
      .then((response) => {
        dispatch(updateUserSuccess(user));
        return response;
      })
      .catch((error) => dispatch(updateUserFailure(error)));
  };
}

export const updateUserPhotoBegin = () => {
  return {
    type: UPDATE_USER_PHOTO_BEGIN,
  };
};

export const updateUserPhotoSuccess = (imageUrl) => {
  return {
    type: UPDATE_USER_PHOTO_SUCCESS,
    payload: { imageUrl },
  };
};

export const updateUserPhotoFailure = (error) => {
  return {
    type: UPDATE_USER_PHOTO_FAILURE,
    payload: { error },
  };
};

export function updateUserPhoto(photo) {
  return (dispatch) => {
    dispatch(updateUserPhotoBegin());
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    let formData = new FormData();
    formData.append('image', photo);
    return axios
      .post(`${server}/api/user/image`, formData)
      .then((response) => {
        console.log(response);
        dispatch(updateUserPhotoSuccess(response.data.imageUrl));
        return response;
      })
      .catch((error) => dispatch(updateUserPhotoFailure(error)));
  };
}

export const fetchCardInfoBegin = () => {
  return {
    type: FETCH_CARD_INFO_BEGIN,
  };
};

export const fetchCardInfoSuccess = (cardInfo) => {
  return {
    type: FETCH_CARD_INFO_SUCCESS,
    payload: { cardInfo },
  };
};

export const fetchCardInfoFailure = (error) => {
  return {
    type: FETCH_CARD_INFO_FAILURE,
    payload: { error },
  };
};

export const fetchCardInfo = () => {
  return (dispatch) => {
    dispatch(fetchCardInfoBegin());
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    axios
      .get(server + '/api/getCard')
      .then((response) => {
        dispatch(fetchCardInfoSuccess(response.data.card.card));
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchCardInfoFailure(error));
      });
  };
};

export const updateCardInfoBegin = () => {
  return {
    type: UPDATE_CARD_INFO_BEGIN,
  };
};

export const updateCardInfoSuccess = () => {
  return {
    type: UPDATE_CARD_INFO_SUCCESS,
  };
};

export const updateCardInfoFailure = (error) => {
  return {
    type: UPDATE_CARD_INFO_FAILURE,
    payload: { error },
  };
};

export const updateCardInfo = (payload) => {
  return (dispatch) => {
    dispatch(updateCardInfoBegin());
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    axios
      .post(server + '/api/setSource', { token: payload })
      .then((result) => {
        dispatch(updateCardInfoSuccess());
        dispatch(fetchCardInfo());
      })
      .catch((error) => {
        dispatch(updateCardInfoFailure(error));
      });
  };
};

export const fetchChargesBegin = () => {
  return {
    type: FETCH_CHARGES_BEGIN,
  };
};

export const fetchChargesSuccess = (response) => {
  return {
    type: FETCH_CHARGES_SUCCESS,
    payload: { response },
  };
};

export const fetchChargesFailure = (error) => {
  return {
    type: FETCH_CHARGES_FAILURE,
    payload: { error },
  };
};

export const fetchCharges = () => {
  return (dispatch) => {
    dispatch(fetchChargesBegin());
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    return axios
      .get(`${server}/api/getCharges`)
      .then((response) => {
        dispatch(fetchChargesSuccess(response));
        return response;
      })
      .catch((error) => dispatch(fetchChargesFailure(error)));
  };
};