// Material UI components
import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import logo from '../images/CXL.png';
import { server } from '../actions/backend-url';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../actions/userActions';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  customError: {
    color: 'red',
    fontSize: '0.8rem',
    marginTop: 10,
  },
  progess: {
    position: 'absolute',
  },
});

const Login = (props) => {
  
  const { classes, history } = props;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);

  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.userIdSql);

  useEffect(() => {
    const authToken = localStorage.getItem('AuthToken');
    if (userId && authToken) {
      localStorage.setItem('userId', userId);
      setLoading(false);
      history.push('/');
    }
  }, [userId, check]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const userData = {
      email,
      password,
    };
    try {
      const response = await axios
      .post(
        `${server}/api/login`,
        userData
      );
      // This should be a cookie for security
      localStorage.setItem('AuthToken', `Bearer ${response.data.token}`);
      dispatch(fetchUser());
      setCheck(true);
    } catch(error) {
      setLoading(false);
      setErrors(error.response.data);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar variant="square" src={logo}>
          {/* <LockOutlinedIcon /> */}
        </Avatar>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            helperText={errors.email}
            error={errors.email ? true : false}
            onChange={(event) => setEmail(event.currentTarget.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            helperText={errors.password}
            error={errors.password ? true : false}
            onChange={(event) => setPassword(event.currentTarget.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit}
            disabled={loading || !email || !password}
          >
            Sign In
            {loading && (
              <CircularProgress size={30} className={classes.progess} />
            )}
          </Button>
          {errors.general && (
            <Typography variant="body2" className={classes.customError}>
              {errors.general}
            </Typography>
          )}

          <Grid container justify="space-between">
          <Grid item>
              <Link href="signup" variant="body2">
                Create a new account
              </Link>
            </Grid>
            <Grid item>
              <Link href="resetpassword" variant="body2">
                Forgot your password?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

export default withStyles(styles)(Login);
