import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { limitString } from '../../../util/tableUtils';
import { makeStyles } from '@material-ui/core';
import { HeaderDataTypes } from '../../../util/constants';

const useStyles = makeStyles({
  rowSelected: {
    '&:hover': {
      backgroundColor: '#c8e6fc',
    },
    backgroundColor: '#EDF7FE'
  },
  rowNotSelected: {
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.1) !important',
    },
    backgroundColor: 'white'
  },
});

export default function CustomTableRow(props) {
  const { row, headers, clickAction, isSelected, onSelectClick, checkBoxes, ActionsButtons } = props;
  const classes = useStyles();
  const isItemSelected = isSelected ? isSelected(row.id) : false;

  const formatCell = (data, type) => {
    switch (type) {
      case HeaderDataTypes.PERCENT: return data.toString() + "%";
      case HeaderDataTypes.STRING: return limitString(data, 50);
      case HeaderDataTypes.ELEMENT: return (data.element)
      case HeaderDataTypes.BUTTONS: return (<ActionsButtons row={row} />)
      default: return data;
    }
  }

  const formatAlign = (type) => {
    switch (type) {
      case HeaderDataTypes.ELEMENT: return "center";
      default: return "left";
    }
  }

  return (
    <TableRow
      className={isItemSelected ? classes.rowSelected : classes.rowNotSelected}
      onClick={row.no_click ? () => {} : (event) => clickAction(event, row)}
      tabIndex={-1}
      key={row.id}
      sx={row.no_click ? { cursor:  'default' } : { cursor: 'pointer' }}
    >
      {checkBoxes ?
        <TableCell onClick={(event) => event.stopPropagation()} size="small">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            onClick={(event) => onSelectClick(event, row.id)}
          />
        </TableCell> : null
      }

      {headers.filter((header) => header.display).map((header) => {
        if (header.type === HeaderDataTypes.BUTTONS) {
          return (
            <TableCell onClick={(event) => event.stopPropagation()} align={formatAlign(header.type)}>
              {formatCell(row[header.id], header.type)}
            </TableCell>
          )
        } else {
          return (
            <TableCell align={formatAlign(header.type)}>
              {formatCell(row[header.id], header.type)}
            </TableCell>
          )
        }
      })}
    </TableRow>
  );
}
