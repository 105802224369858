import React, { useEffect, useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import moment from 'moment-timezone';
import Button from '@material-ui/core/Button';
import {
  getInsightImagePromise,
  updateUserConfirm,
} from '../../actions/userInsightsActions';
import CountUp from 'react-countup';
import { useDispatch } from 'react-redux';
import { CircularProgress, Typography } from '@material-ui/core';
import { getFormattedTimeString, getInsightTime } from '../../util/time';
import InsightMapDialog from './InsightMapDialog';
import GeneralSwipeableView from '../Common/ContentContainers/GeneralSwipeableView';
import { ResponsiveBoxPlot } from '@nivo/boxplot'

const styles = (theme) => ({
  largeImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  photoDiv: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    height: '100%',
    width: '100%'
  },
  border: {
    height: '100%',
    width: '100%',
    minHeight: "30vh",
    border: '2px solid black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px'
  },
  button: {
    margin: '0px 5px'
  },
  text: {
    margin: 0
  },
  gridContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    padding: 8,
    columnGap: 16,
  },
  gridHeader: {
    flex: 3,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8,
  },
  buttonContainer: { display: 'flex', flexDirection: 'row', rowGap: 8 },
  mapContainer: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  latLongContainer: { marginRight: 20 },
  centerColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: "relative"
  }
});

const AggregateView = (props) => {
  const { classes, row, isMobile } = props;
  const [imageLoading, setImageLoading] = useState(true);
  const [images, setImages] = useState(undefined);
  const [graphData, setGraphData] = useState([]);

  const [localAggregate, setLocalAggregate] = useState(row);

  const getWindowDuration = (aggregate) => {
    return ((new Date(aggregate.window_end)).getTime() - (new Date(aggregate.window_start)).getTime()) / 1000
  }

  const loadData = async () => {
    setImageLoading(true);
    if (!!row && !!row.insights && row.insights.length > 0) {
      setGraphData(row.insights.map((insight) => {
        return {
          "group": "Confidence",
          "mu": 5,
          "sd": 1,
          "n": row.insights.length,
          "value": insight.confidence
        }
      }));
      const downloadedImages = [];
      for (const insight of row.insights) {
        if (!!insight.photoUrl) {
          downloadedImages.push(getInsightImagePromise(row.device_id, row.insight_id, row.alg_id, row.class_name, insight.photoUrl));
        }
      }
      setImages(await Promise.all(downloadedImages));
    }
    setLocalAggregate(row);
    setImageLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [row])

  return (
    <div className={classes.gridContainer}>
      <div className={classes.gridHeader}>

        <p className={classes.text}>
          <b>Species: </b> {localAggregate.class_name}
        </p>

        <p className={classes.text}>
          <b>Start: </b> {getFormattedTimeString(localAggregate.window_start)}
        </p>

        <p className={classes.text}>
          <b>End: </b> {getFormattedTimeString(localAggregate.window_end)}
        </p>

        <p className={classes.text}>
          <b>Duration: </b> {getWindowDuration(localAggregate)} Seconds
        </p>

        {isMobile && 
        <p className={classes.text}>
          <b>Mean Confidence: </b> {localAggregate.mean_confidence}%
        </p>}

        {!!localAggregate.lat && !!localAggregate.long && (
          <div className={classes.mapContainer}>
            <div className={classes.latLongContainer}>
              <p className={classes.text}>
                <b>Location: {`${localAggregate.lat}, ${localAggregate.long}`}</b>
              </p>
            </div>
            <InsightMapDialog
              latitude={localAggregate.lat}
              longitude={localAggregate.long}
              insight={localAggregate.insights[0]}
              disabled={!localAggregate || !localAggregate.lat || !localAggregate.long}
            />
          </div>
        )}
        <div className={classes.largeImage}>
          {!!localAggregate && !!localAggregate.insights && localAggregate.insights.filter((insight) => !!insight.photoUrl).length > 0 ?
            (imageLoading ?
              <CircularProgress disableShrink /> :
              <div>
                {<GeneralSwipeableView content={images.map((image, index) => {
                  return (<img
                    className={classes.photoDiv}
                    src={`data:image/jpeg;base64,${image}`}
                    id={`large-image${index}`}
                  />)
                })} />
                }
              </div>
            ) :
            <div className={classes.border}>
              <Typography>Image does not exist</Typography>
            </div>
          }
        </div>
      </div>

      {!imageLoading && !isMobile && <div className={classes.centerColumn}>
          <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
            <ResponsiveBoxPlot
              data={graphData}
              margin={{ top: 60, right: 50, bottom: 60, left: 50 }}
              minValue={0}
              maxValue={100}
              padding={0.12}
              isInteractive={false}
              enableGridX={true}
              colors={{ scheme: 'category10' }}
              borderRadius={2}
              borderWidth={2}
              borderColor={{
                from: 'color',
                modifiers: [
                  [
                    'darker',
                    1
                  ]
                ]
              }}
              medianWidth={2}
              medianColor={{
                from: 'color',
                modifiers: [
                  [
                    'darker',
                    1
                  ]
                ]
              }}
              whiskerEndSize={0.6}
              whiskerColor={{
                from: 'color',
                modifiers: [
                  [
                    'darker',
                    1
                  ]
                ]
              }}
              motionConfig="stiff"
            />
          </div>
        </div>}
    </div>
  );
};

export default withStyles(styles)(AggregateView);
