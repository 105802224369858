import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography } from '@material-ui/core';
import MapView from '../Map/MapView';


const styles = (theme) => ({
  mapContainer: {
    width: '100%', 
    height: '60vh'
  }
});

const InsightMapDialog = (props) => {
  const { disabled, classes, insight } = props;
  const [open, setOpen] = React.useState(false);
  const point = {
    latitude: insight?.latitude,
    longitude: insight?.longitude
  }

  const handleClickOpen = (event) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div  >
      { disabled ?
        <Typography variant='body2'>No Location Available</Typography> :
        <Button variant="outlined" color="primary" onClick={handleClickOpen} disabled={disabled} style={{paddingTop: 0, paddingBottom: 0, margin: 0}}>
          Open Map
        </Button>
      }
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        fullWidth = {true}
        maxWidth = "sm"
        onClick={(event) => event.stopPropagation()}
      >
        <div className={classes.mapContainer}>
          <MapView points={[point]}/>
        </div>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(InsightMapDialog);
