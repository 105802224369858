import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware, compose } from 'redux';
import allReducer from './reducers/index';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import thunk from 'redux-thunk';
import { BreakpointProvider } from './MediaQueries/breakpoint';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(allReducer, composeEnhancers(applyMiddleware(thunk)));

const queries = {
  mobile: '(max-width: 520px)',
  tablet: '(max-width: 920px)',
  desktop: '(max-width: 1024px)',
  bigDesktop: '(min-width: 1025px)',
  // bigDesktop: '(orientation: portrait)', // we can check orientation also
};

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <BreakpointProvider queries={queries}>
          <App />
        </BreakpointProvider>
      </ThemeProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
