import React, { useState, useEffect } from 'react';

import WidgetCommon from '../Common/widgetCommon'
import { withStyles } from '@material-ui/core';
import MapGL, { ScaleControl, NavigationControl, FullscreenControl, Source, Layer } from 'react-map-gl';

const styles = (theme) => ({});
const MAPBOX_TOKEN = "pk.eyJ1IjoicGF0cmlja2NvbWJlIiwiYSI6ImNrbHR5eTF0ZTBkbGMycXF4bXc2dWF5bHQifQ.wCfYeiV37t7c9yqZcGQLgQ";
const MAX_ZOOM_LEVEL = 14;

const heatmapLayer = {
  id: 'heatmap',
  type: 'heatmap',
  maxzoom: MAX_ZOOM_LEVEL,
  paint: {
    'heatmap-weight': 0.1,
    'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 0.1, MAX_ZOOM_LEVEL, 0.2],
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0,
      'rgba(236,222,239,0)',
      0.1,
      '#033198',
      0.2,
      '#1F64AF',
      0.3,
      '#3191C2',
      0.4,
      '#5CC1D2',
      0.5,
      '#A5E6D3',
      0.6,
      '#D0E2A3',
      0.7,
      '#C8B454',
      0.8,
      '#B08029',
      0.9,
      '#995215',
      1,
      '#7E1700'
    ],
    'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 10, MAX_ZOOM_LEVEL, 30],
    'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 1, 22, 0]
  }
};

const circleLayer = {
  id: 'points',
  type: 'circle',
  minzoom: 14,
  paint: {
    // increase the radius of the circle as the zoom level and dbh value increases
    'circle-radius': ['interpolate', ['linear'], ['zoom'], 0, 3, 22, 20],
    'circle-color': 'rgb(28,144,153)',
    'circle-stroke-color': 'white',
    'circle-stroke-width': 1,
    'circle-opacity': {
      stops: [
        [14, 0],
        [15, 1]
      ]
    }
  }
}

const Heatmap = (props) => {
  const { isLoading, insights } = props;
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    if (insights) {
      const features = [];
      insights.forEach(function (insight) {
        if (insight.lat !== 0 && insight.long !== 0 && insight.lat && insight.long) {
          features.push({
            type: 'Feature',
            properties: {
              "id": insight.id
            },
            geometry: {
              type: 'Point',
              coordinates: [insight.long, insight.lat],
            }
          });
        }
      })

      setGraphData({ type: "FeatureCollection", features: features });
    }

  }, [insights])

  return (
    <WidgetCommon
      title="Heatmap"
      isLoading={isLoading}
      hasNoData={graphData.length === 0}
      hasTooMuchData={false}
      tooMuchDataString=""
    >
        <MapGL
          initialViewState={{
            latitude: graphData.features?.length > 0 ? graphData.features[0].geometry.coordinates[1] : 0,
            longitude: graphData.features?.length > 0 ? graphData.features[0].geometry.coordinates[0] : 0,
            zoom: 1
          }}
          mapStyle="mapbox://styles/mapbox/dark-v9"
          mapboxAccessToken={MAPBOX_TOKEN}
          onRender={(event) => event.target.resize()}
        >
          {insights && (
            <Source type="geojson" data={graphData}>
              <Layer {...heatmapLayer} />
              <Layer {...circleLayer} />
            </Source>
          )}
          <ScaleControl />
          <NavigationControl
            position='bottom-right'
          />
          <FullscreenControl />
        </MapGL>
    </WidgetCommon>
  )
}

export default withStyles(styles)(Heatmap);