import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  withStyles,
  TextField,
  CardActions,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { changePassword } from '../../actions/userActions.js';

const styles = (theme) => ({
  changePasswordHeader: {
    [theme.breakpoints.up('md')]: {
      marginLeft: '25%',
    },
  },
  changePasswordCard: {
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '20px',
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 2, 2, 2),
  },
  changePasswordButton: {
    marginLeft: 'auto',
  },
  changePasswordProgress: {
    position: 'absolute',
  },
  tfaHeader: {
    marginTop: theme.spacing(4),
  }
});

const AccountSecurity = (props) => {
  const { classes, openSnackbar } = props;

  const [currPassword, setCurrPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const changePasswordProgress = useSelector(
    (state) => state.user.changePasswordProgress
  );
  const changePasswordError = useSelector(
    (state) => state.user.changePasswordError
  );
  const changePasswordLoading = changePasswordProgress === 'BEGINNING';

  // set to true when component mounts, used in useEffect() to check if this is
  // when the component is first mounting
  const isMounted = useRef(false);

  const onChangePassword = () => {
    dispatch(changePassword(currPassword, newPassword));
  };

  // update the snackbar with the progress of changing password, but not when the component first mounts
  // if we didn't have the component first mounts check, then it would show the snackbar again from the previous update
  useEffect(() => {
    if (isMounted.current) {
      if (changePasswordLoading) {
        openSnackbar('Changing password...', 'info');
      } else {
        if (changePasswordProgress === 'SUCCESS') {
          openSnackbar('Changed password', 'success');
          setCurrPassword('');
          setNewPassword('');
        } else if (changePasswordProgress === 'FAILURE') {
          openSnackbar(
            `Error changing password (${changePasswordError})`,
            'error'
          );
        }
      }
    }
    // eslint-disable-next-line
  }, [changePasswordLoading, changePasswordProgress]);

  useEffect(() => {
    isMounted.current = true;
  }, []);

  return (
    <Box>
      <Typography className={classes.changePasswordHeader} variant="h6">
        Change Password
      </Typography>
      <Card className={classes.changePasswordCard}>
        <CardContent>
          <TextField
            fullWidth
            label="Current Password"
            margin="dense"
            name="currPassword"
            variant="outlined"
            type="password"
            required
            value={currPassword}
            onChange={(e) => {
              setCurrPassword(e.target.value);
            }}
          />
          <TextField
            fullWidth
            label="New Password"
            margin="dense"
            name="newPassword"
            variant="outlined"
            type="password"
            required
            value={newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}   
          />

          <Typography>
          Password requirements:
          <ul>
          <li>Must have more than 8 characters</li>
          <li>Must have one upper case, lower case, and special character</li>
          <li>Must be different than your current password</li>
          </ul>
          </Typography>

        </CardContent>
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            className={classes.changePasswordButton}
            onClick={onChangePassword}
            disabled={changePasswordLoading || !currPassword || !newPassword}
          >
            Change Password
            {changePasswordLoading && (
              <CircularProgress
                size={30}
                className={classes.changePasswordProgress}
              />
            )}
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default withStyles(styles)(AccountSecurity);