import React, { useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@material-ui/core';


const styles = (theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2)
  },
});


const BasicModal = (props) => {
  const { classes, fullWidth, icon, title, content, confirmText, confirmAction = () => {}, cancelAction = () => {}, noCancel, iconColor } = props;

  const [open, setOpen] = useState(false);

  const handleConfirm = () => {
    confirmAction();
    setOpen(false);
  }

  const handleCancel = () => {
    cancelAction();
    setOpen(false);
  }

  return (
    <React.Fragment>
      <IconButton color={iconColor && iconColor} onClick={() => setOpen(true)}>
        {icon}
      </IconButton>
      <Dialog className={classes.modal} fullWidth={fullWidth} open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {content}
        </DialogContent>
        <DialogActions className={classes.actions}>
          {!noCancel && 
            <Button color='primary' onClick={handleCancel}>
              Cancel
            </Button>
          }
          <Button color='primary' onClick={handleConfirm}>
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default withStyles(styles)(BasicModal);
