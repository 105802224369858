
import React from 'react';
import moment from 'moment-timezone';

// Material Components
import { createTheme } from '@mui/material';

// Custom Components
import EditDeviceModal from '../Modal/editDeviceModal';

// Style imports
import withStyles from '@material-ui/core/styles/withStyles';
import {
  defaultFont,
} from '../../style/style';
import { getDisplayName } from '../../util/deviceHelpers';
import EditIcon from '@material-ui/icons/Edit';
import { IconButton } from '@material-ui/core';

const theme_bp = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 1050,
      lg: 1200,
      xl: 1536,
    },
  },
});

const styles = (theme) => ({
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    fontSize: '0.9rem',
  },
  button: {
    '&:focus': {
      color: 'white',
      backgroundColor: '#0A94C7',
    },
    '&:hover': {
      color: 'white',
      backgroundColor: '#0A94C7',
    },
  },
  noMargin: { '> div': { margin: 0 } },
  deviceView: {
    width: '100%',
    rowGap: '16px',
    columnGap: '24px',
    padding: '16px',
    display: 'flex',
    margin: 'auto',
    '& div': {
      margin: 0,
    },
    [theme_bp.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    [theme_bp.breakpoints.up('sm')]: {
      flexDirection: 'row',
      // justifyContent: 'space-between',
    },
  },
});


const DeviceView = (props) => {

  const { device, classes, row } = props;

  const [open, setOpen] = React.useState(false);

  return (
    <div
      className={classes.deviceView}
    >
      <div
        className={classes.noMargin}
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          rowGap: 16,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h2> {getDisplayName(device)}</h2> 
          <IconButton onClick={() => setOpen(true)}>
            <EditIcon/>
          </IconButton>
          <EditDeviceModal
            device={device.device_id}
            open={open}
            handleClose={() => setOpen(false)}
          />
        </div>
      </div>
      <div style={{ flex: 1 }}>
        <p>
          <b>Device ID:</b> {device.device_id}
        </p>
        <p>
          <b>Name:</b> {getDisplayName(device)}
        </p>
        <p>
          {' '}
          <b>Location Name: </b>
          {device.location_name || 'Not Set'}
        </p>
        <p>
          {' '}
          <b>Location Description: </b>
          {device.location_description || 'Not Set'}
        </p>
        <p>
          <b>Last Seen (UTC): </b>
          {device.last_seen
            ? moment(device.last_seen).tz('UTC').format('MMM Do YYYY, h:mm a')
            : ''}
        </p>
        <p>
          <b>Latitude: </b>
          {device.latitude}
        </p>
        <p>
          <b>Longitude: </b>
          {device.longitude}
        </p>
        <p>
          <b>Cycle Time: </b>
          {device.cycle_time}
        </p>
        <p>
          <b>Trigger Number: </b>
          {device.trigger_number}
        </p>
        <p>
          <b>Total Images Processed: </b>
          {device.images_processed || "No Data"}
        </p>
        <p>
          <b>Memory Remaining (MB): </b>
          {device.memory_remaining || "No Data"}
        </p>
        <p>
          <b>Groups: </b>
          {row.groups}
        </p>
      </div>
    </div>
  );
};

export default withStyles(styles)(DeviceView);