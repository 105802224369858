import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomModelCard from './CustomModelCard';
import info from './CustomModelCardInfo';
import useWindowPosition from '../../hook/useWindowPosition';
import CustomImage from '../../../images/Custom.png';
import { useMediaQuery } from 'react-responsive'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '60vh',
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  image: {
    borderRadius: 20,
    width: '50vw',
  },
  mobileImage: {
    borderRadius: 20,
    width: '80vw',
  },
}));
export default function () {
  const classes = useStyles();
  const checked = useWindowPosition('header');

  const isDesktop = useMediaQuery({ query: '(min-width: 1224px)'})
  const isMobile = useMediaQuery({ query: '(max-width: 1224px)'})

  return (
    <section id="customcard" data-scroll-id="customcard">
    <div className={classes.root} id="custom-card">
    {isDesktop && <img src={CustomImage} className={classes.image} alt="" />}
    {isMobile && <img src={CustomImage} className={classes.mobileImage} alt="" />}
      <CustomModelCard place={info[0]} checked={checked} />
    </div>
    </section>
  );
}