import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Alert from '@material-ui/lab/Alert';
import BasicModalNoButton from '../Common/Modals/BasicModalNoButton';
import { getInsightImage } from '../../actions/userInsightsActions';
import InsightView from './InsightView';
import { getDisplayName } from '../../util/deviceHelpers';
import moment from 'moment-timezone';
import { getInsightTime } from '../../util/time';
import { getImageKey } from '../../util/photo';
import { useDispatch, useSelector } from 'react-redux';
import AggregateView from './AggregateView';


const styles = (theme) => ({
  photoDiv: {
    height: '100px',
    width: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  mobilePhotoDiv: {
    padding: '5px',
    height: '40vw',
    width: '40vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    boxShadow: '0 4px 8px 1px rgba(0, 0, 0, 0.2), 0 6px 20px 1px rgba(0, 0, 0, 0.17)'
  },
  emptyPhotoDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  thumbnail: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between"
  },
  thumbnailText: {
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    padding: "5px 0",
    margin: 0,
    width: "100%"
  },
  imageTitle: {
    fontSize: 14,
    color: 'black',
    textAlign: 'left',
    fontWeight: 'bold',
    lineHeight: 1,
    padding: 0,
    margin: 0
  },
  imageText: {
    fontSize: 10,
    color: 'rgba(0, 0, 0, 0.6)',
    textAlign: 'left',
    lineHeight: 1,
    padding: 0,
    margin: 0
  },
});

const UserInsightDialog = (props) => {
  const { insight, isMobile, classes, aggregate } = props;
  const [open, setOpen] = useState(false);
  const [imageKey, setImageKey] = useState("None");
  const [image, setImage] = useState(undefined);
  const [content, setContent] = useState(undefined);
  const dispatch = useDispatch();

  const imageData = useSelector((state) => state.userInsights.insightImages[imageKey])

  useEffect(() => {
    if ((!imageKey || imageKey === "None") && !!insight?.photoUrl) {
      setImageKey(getImageKey(insight.device_id, insight.insight_id, insight.alg_id, insight.class_name, insight.table));
    }
  }, [insight]);

  useEffect(() => {
    if (!imageData && !!insight?.photoUrl) {
      dispatch(getInsightImage(insight.device_id, insight.insight_id, insight.alg_id, insight.class_name, insight.table, insight.photoUrl));
    }
  }, [imageKey]);

  useEffect(() => {
    if (imageData && imageData.dataBuffer) {
      setImage(imageData.dataBuffer);
    }
  }, [imageData]);

  useEffect(() => {
    if (!!aggregate) {
      setContent(<AggregateView row={aggregate} isMobile={isMobile}/>);
    } else {
      setContent(<InsightView insight={insight}/>);
    }
  }, [insight, aggregate]);

  const handleButtonClick = async () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const getInsightImageComponent = () => {
    return (
      <div className={classes.thumbnail} style={{ backgroundImage: image ? `url(data:image/jpeg;base64,${image})` : undefined}}>
        { isMobile && 
            <div className={classes.thumbnailText}>
              <p className={classes.imageTitle}>{insight && insight.class_name}</p>
            </div>
        }
        {!!!insight?.photoUrl && <ErrorOutlineIcon style={{ height: '30'}} />}
        { isMobile && 
            <div className={classes.thumbnailText}>
              <p className={classes.imageText}>
              {insight && getDisplayName(insight)}<br/>
              {moment(getInsightTime(insight)).tz('UTC').format('MMM Do YYYY, h:mm a')}</p>
            </div>
        }
      </div>
    );
  } 
  
  const applyMobileStyle = () => {
    if (isMobile) {
      return classes.mobilePhotoDiv;
    } else if (!!!insight?.photoUrl) {
      return classes.emptyPhotoDiv;
    } else {
      return classes.photoDiv;
    }
  };

  return (
    <div>
      <Button
        onClick={handleButtonClick}
        disabled={!isMobile}
      >
        <div className={applyMobileStyle()}>
          {!!insight?.photoUrl && !image ? (
            <CircularProgress disableShrink />
          ) : getInsightImageComponent()
          }
        </div>
      </Button>
      <BasicModalNoButton
        fullWidth
        title={`Class Name: ${insight && insight.class_name}`}
        content={content}
        confirmText='Close'
        noCancel
        confirmAction={handleClose}
        passedOpen={open}
      />
    </div>
  );
};

export default withStyles(styles)(UserInsightDialog);
