import React, { useState, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import Map, { ScaleControl, NavigationControl, FullscreenControl, Source, Layer, Popup } from 'react-map-gl';
import { withStyles } from '@material-ui/styles';
import turf from 'turf';
import outdoorStyle from '../../style/mapStyles/outdoor.json';
import streetStyle from '../../style/mapStyles/street.json';
import { Paper, Switch } from '@material-ui/core';
import MapIcon from '@material-ui/icons/Map';
import SatelliteIcon from '@material-ui/icons/Satellite';
import { clusterCountLayer, clusterLayer, unclusteredPointLayer, unclusteredPointLayerCountCircle, unclusteredPointLayerInside, unclusteredPointLayerCount, unclusteredPointLayerWarning, unclusteredPointLayerWarningText, clusteredPointLayerWarning, clusteredPointLayerWarningText } from './Layers';
import DeviceView from './DeviceView';

import mapboxgl from 'mapbox-gl/dist/mapbox-gl';
import MapboxWorker from 'mapbox-gl/dist/mapbox-gl-csp-worker';
import { getDisplayName } from '../../util/deviceHelpers';

mapboxgl.workerClass = MapboxWorker;

const styles = (theme) => ({
  mapActionMenu: {
    position: 'absolute',
    top: 10,
    left: '2vh',
    zIndex: 100,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    columnGap: theme.spacing(2),
  },
  centerIcons: {
    display: 'flex',
    alignItems: 'center',
    padding: 5,
    flexDirection: 'row',
  },
});

const MAPBOX_TOKEN = "pk.eyJ1IjoicGF0cmlja2NvbWJlIiwiYSI6ImNrbHR5eTF0ZTBkbGMycXF4bXc2dWF5bHQifQ.wCfYeiV37t7c9yqZcGQLgQ";

const MapView = (props) => {
  const { classes, points, setTab, showInsightCount } = props;
  const isMobile = useMediaQuery({ query: '(max-width: 740px)'});
  const mapRef = useRef();
  const [viewState, setViewState] = useState({});
  const [mapStyle, setMapStyle] = useState(streetStyle);
  const [styleChecked, setStyleChecked] = useState(true);
  const [popupInfo, setPopupInfo] = useState(null);

  const features = points.map((p) => {
    const lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7); // Subtract 7 days

    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [p.longitude, p.latitude],
      },
      properties: {
        insightCount: p.insightMetrics ? p.insightMetrics.count : 0,
        insightCountText: p.insightMetrics ? p.insightMetrics.count > 999 ? '>999' : p.insightMetrics.count : 0,
        longitude: p.longitude,
        latitude: p.latitude,
        deviceName: p.device && getDisplayName(p.device),
        location: p.device && p.device.location_name,
        deviceId: p.device && p.device.device_id,
        lastSeen: p.device && p.device.last_seen,
        displayWarning: p.device && p.device.last_seen && (new Date(p.device.last_seen)).valueOf() < lastWeek.valueOf() ? true : false,
        mostRecentClassName: p.insightMetrics && p.insightMetrics.mostRecentInsight ? p.insightMetrics.mostRecentInsight.class_name : null,
        mostRecentTimestamp: p.insightMetrics && p.insightMetrics.mostRecentInsight ? p.insightMetrics.mostRecentInsight.time_stamp : null,
      },
    };
  });

  const onLoad = () => {
    const [minLng, minLat, maxLng, maxLat] = turf.bbox({
      type: 'FeatureCollection',
      features: features,
    });

    mapRef.current.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat]
      ],
      { padding: isMobile ? 30 : 150, duration: 1000, maxZoom: 10 }
    );

    if (setTab) {
      mapRef.current.on('mouseover', clusterLayer.id, () => {
        mapRef.current.getCanvas().style.cursor = 'pointer'
      })
      mapRef.current.on('mouseleave', clusterLayer.id, () => {
        mapRef.current.getCanvas().style.cursor = ''
      })
      mapRef.current.on('mouseover', unclusteredPointLayer.id, () => {
        mapRef.current.getCanvas().style.cursor = 'pointer'
      })
      mapRef.current.on('mouseleave', unclusteredPointLayer.id, () => {
        mapRef.current.getCanvas().style.cursor = ''
      })
    }
  }

  const onClick = event => {
    if (event.features && event.features.length > 0 && setTab) {
      const feature = event.features[0];
      if (feature.layer.id === clusterLayer.id) {
        const clusterId = feature.properties.cluster_id;

        const mapboxSource = mapRef.current.getSource('cameras');

        mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) {
            return;
          }
          mapRef.current.easeTo({
            center: feature.geometry.coordinates,
            zoom,
            duration: 1000
          });
        });
      } else if (
        feature.layer.id === unclusteredPointLayer.id || 
        feature.layer.id === unclusteredPointLayerInside.id || 
        feature.layer.id === unclusteredPointLayerCountCircle.id || 
        feature.layer.id === unclusteredPointLayerCount.id
      ) {
        setPopupInfo(feature.properties);
      }
    }
  };

  const geoData = {
    type: "FeatureCollection",
    features
  }

  const handleStyleChange = () => {
    const checked = !styleChecked;
    if (checked) {
      setMapStyle(streetStyle);
    } else {
      setMapStyle(outdoorStyle);
    }
    setStyleChecked(checked);
  };

  const handleMove = (viewState) => {
    setViewState(viewState)
  }

  return (
    <Map
      {...viewState}
      ref={mapRef}
      onMove={evt => handleMove(evt.viewState)}
      onLoad={onLoad}
      style={{ width: "100%", height: "100%" }}
      interactiveLayerIds={[clusterLayer.id, unclusteredPointLayer.id]}
      mapboxAccessToken={MAPBOX_TOKEN}
      renderWorldCopies={false}
      mapStyle={mapStyle}
      onClick={onClick}
      onRender={(event) => event.target.resize()}
    >
      <Source
        id="cameras"
        type="geojson"
        data={geoData}
        cluster={true}
        clusterMaxZoom={14}
        clusterRadius={35}
        clusterProperties={{
          displayWarning: ['any', ['get', 'displayWarning']]
        }}
      >
        <Layer {...clusterLayer} />
        <Layer {...clusterCountLayer} />
        <Layer {...clusteredPointLayerWarning} />
        <Layer {...clusteredPointLayerWarningText} />
        <Layer {...unclusteredPointLayer} />
        <Layer {...unclusteredPointLayerInside} />
        {showInsightCount &&
          <Layer {...unclusteredPointLayerCountCircle} />
        }
        {showInsightCount &&
          <Layer {...unclusteredPointLayerCount} />
        }
        {showInsightCount &&
          <Layer {...unclusteredPointLayerWarning} />
        }
        {showInsightCount &&
          <Layer {...unclusteredPointLayerWarningText} />
        }
      </Source>

      {popupInfo && (
        <Popup
          anchor="top"
          longitude={popupInfo.longitude}
          latitude={popupInfo.latitude}
          onClose={() => setPopupInfo(null)}
        >
          <DeviceView
            longitude={popupInfo.longitude}
            latitude={popupInfo.latitude}
            deviceId={popupInfo.deviceId}
            insightCount={popupInfo.insightCount}
            deviceName={popupInfo.deviceName}
            location={popupInfo.location}
            mostRecentClassName={popupInfo.mostRecentClassName}
            mostRecentTimestamp={popupInfo.mostRecentTimestamp}
            setTab={setTab}
            lastSeen={popupInfo.lastSeen}
            displayWarning={popupInfo.displayWarning}
          />
        </Popup>
      )}

      <ScaleControl />
      <NavigationControl
        position='bottom-right'
      />
      <FullscreenControl />
      <div className={classes.mapActionMenu}>
        <Paper className={classes.centerIcons}>
          <MapIcon color="action" />
          <Switch
            checked={styleChecked}
            onChange={handleStyleChange}
            color="default"
            name="checkedB"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <SatelliteIcon color="action" />
        </Paper>
      </div>
    </Map>
  );
}

export default withStyles(styles)(MapView);