import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

export default function ReleaseComponentsModal(props) {
  const { components, name, open, handleClose } = props;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Release: {name}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {
            components && components.length > 0? components.map((component) => {
              return (<Grid item xs={12}>
                <Typography variant='body1'>{`Model ID: ${component.algMetadataId}`}</Typography>
                <Typography variant='body1'>{`Is Intermediate?: ${component.isIntermediate}`}</Typography>
                <Typography variant='body1'>{`Save Bound Box Images?: ${component.saveBBImages}`}</Typography>
                <Typography variant='body1'>{`Save Cropped Images?: ${component.saveCroppedImages}`}</Typography>
                <Typography variant='body1'>{`Downstream Model IDs: ${component.downstreamConfigs.length === 0 ? "None" : component.downstreamConfigs.join(", ")}`}</Typography>
                <Typography variant='body1'>{`Bypass Class IDs: ${component.bypassClasses.length === 0 ? "None" : component.bypassClasses.join(", ")}`}</Typography>
                <Typography variant='body1'>{`Ignore Class IDs: ${component.ignoredClasses.length === 0 ? "None" : component.ignoredClasses.join(", ")}`}</Typography>
              </Grid>);
            }) : []
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
