import React, { useEffect, useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import moment from 'moment-timezone';
import Button from '@material-ui/core/Button';
import {
  getInsightImagePromise,
  updateUserConfirm,
} from '../../actions/userInsightsActions';
import CountUp from 'react-countup';
import { useDispatch } from 'react-redux';
import { CircularProgress, Typography } from '@material-ui/core';
import { getInsightTime } from '../../util/time';
import InsightMapDialog from './InsightMapDialog';

const styles = (theme) => ({
  largeImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  photoDiv: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    height: '100%',
    width: '100%'
  },
  border: {
    height: '100%',
    width: '100%',
    border: '2px solid black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px'
  },
  button: { 
    margin: '0px 5px' 
  },
  text: {
    margin: 0
  },
  gridContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    padding: 8,
    columnGap: 16,
  },
  gridHeader: {
    flex: 3,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8,
  },
  buttonContainer: { display: 'flex', flexDirection: 'row', rowGap: 8 },
  mapContainer: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  latLongContainer: { marginRight: 20 },
  centerColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  meterContainer: {
    maxWidth: 100,
    margin: 'auto',
    width: '30%',
    borderRadius: 10,
    height: '70%',
    backgroundColor: 'rgb(99,99,99,0.1)',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  }
});

const InsightView = (props) => {
  const { classes, row, insight, ActionsButtons } = props;
  const dispatch = useDispatch();
  const [imageLoading, setImageLoading] = useState(!!insight.photoUrl);
  const [image, setImage] = useState(undefined);

  const [localInsight, setLocalInsight] = useState(insight);

  const handleClick = (insightId, deviceId, value, table) => {
    dispatch(updateUserConfirm(insightId, deviceId, value, table));
    insight.userConfirmed = value;
    const newInsight = {...insight}
    setLocalInsight(newInsight);
  };

  const loadImage = async () => {
    setImageLoading(true);
    setImage(await getInsightImagePromise(localInsight.device_id, localInsight.insight_id, localInsight.alg_id, localInsight.class_name, localInsight.photoUrl));
    setImageLoading(false);
  };

  useEffect(() => {
    if (!!insight.photoUrl) {
      loadImage();
    }
    setLocalInsight(insight);
  }, [insight])

  return (
    <div className={classes.gridContainer}>
      <div className={classes.gridHeader}>
        <p className={classes.text}>
          <b>Insight Id: </b> {localInsight.insight_id}
        </p>

        <p className={classes.text}>
          <b>Species: </b> {localInsight.class_name}
        </p>

        <p className={classes.text}>
          <b>Camera Timestamp: </b>
          {moment(getInsightTime(localInsight))
            .tz('UTC')
            .format('MMM Do YYYY, h:mm a')}{' '}
        </p>

        <p className={classes.text}>
          <b>Confirm animal in image:</b>
        </p>

        <div className={classes.buttonContainer}>
          <Button
            className={classes.button}
            variant="contained"
            disabled={!!!localInsight.photoUrl}
            onClick={() => {
              handleClick(localInsight.insight_id, localInsight.device_id, 1, localInsight.table);
            }}
            color={
              localInsight.userConfirmed == null
                ? 'default'
                : localInsight.userConfirmed === 1
                ? 'primary'
                : 'default'
            }
          >
            Yes
          </Button>

          <Button
            className={classes.button}
            variant="contained"
            disabled={!!!localInsight.photoUrl}
            onClick={() => {
              handleClick(localInsight.insight_id, localInsight.device_id, 0, localInsight.table);
            }}
            color={
              localInsight.userConfirmed == null
                ? 'default'
                : localInsight.userConfirmed === 1
                ? 'default'
                : 'primary'
            }
          >
            No
          </Button>
        </div>

        {!!localInsight.latitude && !!localInsight.longitude && (
          <div className={classes.mapContainer}>
            <div className={classes.latLongContainer}>
              <p className={classes.text}>
                <b>Location: </b>{`${localInsight.latitude}, ${localInsight.longitude}`}
              </p>
            </div>
            <InsightMapDialog
              latitude={localInsight.latitude}
              longitude={localInsight.longitude}
              insight={localInsight}
              disabled={!localInsight || !localInsight.latitude || !localInsight.longitude}
            />
          </div>
        )}
        <div className={classes.largeImage}>
          { !!localInsight.photoUrl ? 
            (imageLoading ? 
            <CircularProgress disableShrink /> :
            <img
              className={classes.photoDiv}
              src={`data:image/jpeg;base64,${image}`}
              id='large-image'
            />) :
            <div className={classes.border}>
              <Typography>Image does not exist</Typography>
            </div>
          }
        </div>
        {ActionsButtons ? <ActionsButtons row={row} /> : null}
      </div>

      <div className={classes.centerColumn}>
        <div className={classes.centerColumn}>
          <p>Confidence</p>

          <p>
            {localInsight.is_from_lora ? (
              <CountUp
                start={0}
                end={localInsight.group_confidence}
                duration={1}
                delay={0}
              />
            ) : (
              <CountUp
                start={0}
                end={localInsight.confidence}
                duration={1}
                delay={0}
              />
            )}
            %
          </p>
        </div>
        <div className={`meter ${classes.meterContainer}`}>
          <span
            style={{
              bottom: 0,
              height: `${
                localInsight.is_from_lora
                  ? localInsight.group_confidence
                  : localInsight.confidence
              }%`,
            }}
          >
            <span class="progress"></span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(InsightView);
