import React, { useState } from 'react';

// Material Components
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { useTheme } from '@emotion/react';

// Style imports
import withStyles from '@material-ui/core/styles/withStyles';

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import MobileStepper from '@mui/material/MobileStepper';
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = (theme) => ({
    contentClass: {
        minHeight: "30vh"
    }
});

const GeneralSwipeableView = (props) => {
  const {
    content, classes
  } = props;

  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);

  const steps = content
    .map((singlePageContent) => (
      <div className={classes.contentClass} data-testid="swipeable-content-page">
        {singlePageContent}
      </div>
      ));

    const maxSteps = steps.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {steps}
            </SwipeableViews>
            <MobileStepper
                variant="text"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                <Button
                    data-testid="forward"
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                >
                    Next
                    {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                    ) : (
                    <KeyboardArrowRight />
                    )}
                </Button>
                }
                backButton={
                <Button size="small" onClick={handleBack} disabled={activeStep === 0} data-testid="back">
                    {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                    ) : (
                    <KeyboardArrowLeft />
                    )}
                    Previous
                </Button>
                }
            />
        </Box>
    );
}

export default withStyles(styles)(GeneralSwipeableView);