import {
  FETCH_DEVICE_BEGIN,
  FETCH_DEVICE_SUCCESS,
  FETCH_DEVICE_FAILURE,
  // FETCH_DEVICE_MODEL_BEGIN,
  // FETCH_DEVICE_MODEL_SUCCESS,
  // FETCH_DEVICE_MODEL_FAILURE,
  FETCH_DEPLOYED_MODEL_BEGIN,
  FETCH_DEPLOYED_MODEL_SUCCESS,
  FETCH_DEPLOYED_MODEL_FAILURE,
  FETCH_MODELS_BEGIN,
  FETCH_MODELS_SUCCESS,
  FETCH_MODELS_FAILURE,
  UPDATE_DEVICE_BEGIN,
  UPDATE_DEVICE_SUCCESS,
  UPDATE_DEVICE_FAILURE,
  CREATE_DEVICE_BEGIN,
  CREATE_DEVICE_FAILURE,
  CREATE_DEVICE_SUCCESS,
  UPDATE_MODEL_BEGIN,
  UPDATE_MODEL_SUCCESS,
  UPDATE_MODEL_FAILURE,
  UPDATE_MODEL_NOTIFS_BEGIN,
  UPDATE_MODEL_NOTIFS_SUCCESS,
  UPDATE_MODEL_NOTIFS_FAILURE,
  DELETE_MODEL_BEGIN,
  DELETE_MODEL_SUCCESS,
  DELETE_MODEL_FAILURE,
  UPDATE_DEPLOY_BEGIN,
  UPDATE_DEPLOY_SUCCESS,
  UPDATE_DEPLOY_FAILURE,
  UPDATE_DEPLOY_GROUP_DEVICES_BEGIN,
  UPDATE_DEPLOY_GROUP_DEVICES_SUCCESS,
  UPDATE_DEPLOY_GROUP_DEVICES_FAILURE,
  FETCH_MODEL_NOTIFS_BEGIN,
  FETCH_MODEL_NOTIFS_FAILURE,
  FETCH_MODEL_NOTIFS_SUCCESS,
  UPDATE_DEVICE_DISPLAY_PREFIX,
} from '../actions/deviceActions';

import { Action } from '../actions/actionsShared';

const initialState = {
  deviceData: [],
  deviceModelData: [],
  modelNotifs: [],
  deployedModelData: [],
  result: '',
  loadingDevices: false
};

const deviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DEVICE_BEGIN:
      return {
        ...state,
        loadingDevices: true,
        result: 'BEGINNING',
      };
    case FETCH_DEVICE_SUCCESS:
      return {
        ...state,
        deviceData: action.payload.response.data.message,
        loadingDevices: false,
        result: 'SUCCESS',
      };
    case FETCH_DEVICE_FAILURE:
      return {
        ...state,
        loadingDevices: false,
        result: 'FAILURE',
      };
    case UPDATE_DEVICE_BEGIN:
      return {
        ...state,
        result: 'BEGINNING',
      };
    case UPDATE_DEVICE_SUCCESS:
      const deviceDataNew = [...state.deviceData];
      const deviceIndex = state.deviceData.findIndex(
        (device) => device.device_id === action.payload.device.device_id
      );
      const updatedDevice = action.payload.device;
      deviceDataNew[deviceIndex] = updatedDevice;
      return {
        ...state,
        deviceData: deviceDataNew,
        result: 'SUCCESS',
      };
    case UPDATE_DEVICE_FAILURE:
      return {
        ...state,
        result: 'FAILURE',
      };
    case `${UPDATE_DEVICE_DISPLAY_PREFIX}${Action.BEGIN_SUFFIX}`:
      return {
        ...state,
        result: 'BEGINNING',
      };
    case `${UPDATE_DEVICE_DISPLAY_PREFIX}${Action.SUCCESS_SUFFIX}`:
      const updatedDeviceIndex = state.deviceData.findIndex(
        (device) => device.device_id === action.payload.data.data.message.device_id
      );
      
      state.deviceData[updatedDeviceIndex] = action.payload.data.data.message;
      return {
        ...state,
        result: 'SUCCESS',
      };
    case `${UPDATE_DEVICE_DISPLAY_PREFIX}${Action.FAILURE_SUFFIX}`:
      return {
        ...state,
        result: 'FAILURE'
      };

    //Create Device
    case CREATE_DEVICE_BEGIN:
      return {
        ...state,
        result: 'BEGINNING',
      };
    case CREATE_DEVICE_SUCCESS:
      const deviceDataAdded = state.deviceData;

      // inserts newly created device into device list.
      const createdDevice = action.payload.device;
      createdDevice['location_name'] = createdDevice['location'];
      createdDevice['longitude'] = parseFloat(createdDevice['long']);
      createdDevice['latitude'] = parseFloat(createdDevice['lat']);

      deviceDataAdded.push(createdDevice);

      //TODO: this can be made more elegant eventually. send model data with response?
      const modelDataAdded = state.deployedModelData;
      if (createdDevice['models'].length !== 0) {
        modelDataAdded.push({
          device_id: createdDevice.device_id,
          deployed_status: 'Deploy',
          alg_id: createdDevice['models'][0],
        });
      }

      return {
        ...state,
        deviceData: deviceDataAdded,
        deployedModelData: modelDataAdded,
        result: 'SUCCESS',
      };
    case CREATE_DEVICE_FAILURE:
      return {
        ...state,
        result: 'FAILURE',
      };
    case FETCH_DEPLOYED_MODEL_BEGIN:
      return {
        ...state,
        result: 'BEGINNING',
      };
    case FETCH_DEPLOYED_MODEL_SUCCESS:
      return {
        ...state,
        deployedModelData: action.payload.response.data.message,
        result: 'SUCCESS',
      };
    case FETCH_DEPLOYED_MODEL_FAILURE:
      return {
        ...state,
        result: 'FAILURE',
      };
    case FETCH_MODELS_BEGIN:
      return {
        ...state,
        result: 'BEGINNING',
      };
    case FETCH_MODELS_SUCCESS:
      return {
        ...state,
        deviceModelData: action.payload.response.data,
        result: 'SUCCESS',
      };
    case FETCH_MODELS_FAILURE:
      return {
        ...state,
        result: 'FAILURE',
      };
    default:
      return state;

    // Update Model
    case UPDATE_MODEL_BEGIN:
      return {
        ...state,
        result: 'BEGINNING',
      };
    case UPDATE_MODEL_SUCCESS:
      const deviceModelDataNew = [...state.deviceModelData];
      const deviceModelIndex = state.deviceModelData.findIndex(
        (model) => model.alg_id === action.payload.model.alg_id
      );
      const updatedModel = action.payload.model;
      deviceModelDataNew[deviceModelIndex] = updatedModel;
      return {
        ...state,
        deviceModelData: deviceModelDataNew,
        result: 'SUCCESS',
      };
    case UPDATE_MODEL_FAILURE:
      return {
        ...state,
        result: 'FAILURE',
      };

    case UPDATE_MODEL_NOTIFS_BEGIN:
      return {
        ...state,
        result: 'BEGINNING',
      };
    case UPDATE_MODEL_NOTIFS_SUCCESS:
      const newModelNotifs = [...state.modelNotifs];
      const indexModelNotifs = newModelNotifs.findIndex(
        (model) => model.alg_id === action.payload.alg_id
      );
      newModelNotifs[indexModelNotifs].send_email = action.payload.send_email;
      newModelNotifs[indexModelNotifs].send_sms = action.payload.send_sms;

      newModelNotifs[indexModelNotifs].cell_image = action.payload.cell_image;
      newModelNotifs[indexModelNotifs].sat_image = action.payload.sat_image;

      return {
        ...state,
        modelNotifs: newModelNotifs,
        result: 'SUCCESS',
      };
    case UPDATE_MODEL_NOTIFS_FAILURE:
      return {
        ...state,
        result: 'FAILURE',
      };

    // Delete a Model
    case DELETE_MODEL_BEGIN:
      return {
        ...state,
        result: 'BEGINNING',
      };
    case DELETE_MODEL_SUCCESS:
      return {
        ...state,
        deviceModelData: deviceModelDataNew,
        result: 'SUCCESS',
      };
    case DELETE_MODEL_FAILURE:
      return {
        ...state,
        result: 'FAILURE',
      };

    //Update Deploy
    case UPDATE_DEPLOY_BEGIN:
      return {
        ...state,
        result: 'BEGINNING',
      };
    case UPDATE_DEPLOY_SUCCESS:
      const status = action.payload.deploy.deployed_status;
      const devId = parseInt(action.payload.deploy.device_id);
      const algId = parseInt(action.payload.deploy.alg_id);

      if (status === 'Deploy') {
        return {
          ...state,
          deployedModelData: [
            ...state.deployedModelData,
            { device_id: devId, alg_id: algId },
          ],
          result: 'SUCCESS',
        };
      } else {
        return {
          ...state,
          deployedModelData: [...state.deployedModelData].filter(
            (d) => !(d.alg_id === algId && d.device_id === devId)
          ),
          result: 'SUCCESS',
        };
      }

    case UPDATE_DEPLOY_FAILURE:
      return {
        ...state,
        result: 'FAILURE',
      };

    //Update Deploy Group Devices
    case UPDATE_DEPLOY_GROUP_DEVICES_BEGIN:
      return {
        ...state,
        result: 'BEGINNING',
      };
    case UPDATE_DEPLOY_GROUP_DEVICES_SUCCESS:
      const devIds = action.payload.devIds;
      const deploy = action.payload.deploy;

      if (deploy.deployed_status === 'Deploy') {
        console.log('SUCCESS UPDATE ', devIds, deploy);
        let newDeployedModelData = [...state.deployedModelData];

        for (const devId of devIds) {
          console.log('in loop ', devId);
          newDeployedModelData.push({
            device_id: devId,
            alg_id: deploy.alg_id,
          });

          console.log('newDeployedModelData ', newDeployedModelData);
        }

        return {
          ...state,
          deployedModelData: newDeployedModelData,
          result: 'SUCCESS',
        };
      } else {
        console.log('SUCCESS REMOVAL');

        return {
          ...state,
          deployedModelData: state.deployedModelData.filter(
            (d) => !(devIds.includes(d.device_id) && deploy.alg_id === d.alg_id)
          ),
          result: 'SUCCESS',
        };
      }

    case UPDATE_DEPLOY_GROUP_DEVICES_FAILURE:
      console.log('FAILED UPDATE');

      return {
        ...state,
        result: 'FAILURE',
      };
    case FETCH_MODEL_NOTIFS_BEGIN:
      return {
        ...state,
        result: 'BEGINNING',
      };
    case FETCH_MODEL_NOTIFS_FAILURE:
      return {
        ...state,
        result: 'FAILURE',
      };
    case FETCH_MODEL_NOTIFS_SUCCESS:
      return {
        ...state,
        modelNotifs: action.payload.response.data,
        result: 'FAILURE',
      };
  }
};

export default deviceReducer;
