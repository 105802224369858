
import { Action } from "../actions/actionsShared";
import {
  GET_IMPROVE_MODEL_BEGIN,
  GET_IMPROVE_MODEL_SUCCESS,
  GET_IMPROVE_MODEL_FAILURE,
  FETCH_MODEL_CLASSES_PREFIX,
} from '../actions/modelActions';

const initialState = {
  dataset_images: [],
  modelClasses: {},
  result: '',
};

const modelReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_IMPROVE_MODEL_BEGIN:
      return {
        ...state,
        result: 'BEGINNING',
      };
    case GET_IMPROVE_MODEL_SUCCESS:
      return {
        ...state,
        dataset_images: action.payload.images,
        result: 'SUCCESS',
      };
    case GET_IMPROVE_MODEL_FAILURE:
      return {
        ...state,
        result: 'FAILURE',
      };
    case `${FETCH_MODEL_CLASSES_PREFIX}${Action.BEGIN_SUFFIX}`:
      return {
        ...state,
        result: 'BEGINNING',
      };
    case `${FETCH_MODEL_CLASSES_PREFIX}${Action.SUCCESS_SUFFIX}`:
      const modelClassesData = action.payload.data.data.message;
      const classMap = modelClassesData.classes.reduce((result, c) => {
        if (!(c.alg_id in result)) {
          result[c.alg_id] = []
        }
        result[c.alg_id].push(c);
        return result;
      },{});

      return {
        ...state,
        modelClasses: {
          ...state.modelClasses,
          ...classMap
        },
        result: 'SUCCESS',
      };
    case `${FETCH_MODEL_CLASSES_PREFIX}${Action.FAILURE_SUFFIX}`:
      return {
        ...state,
        result: 'FAILURE'
      };
    default:
      return state;
  }
};

export default modelReducer;
