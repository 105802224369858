export const clusterLayer = {
  id: 'clusters',
  type: 'circle',
  source: 'cameras',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': ['step', ['get', 'point_count'], '#0093C5', 100, '#f1f075', 750, '#f28cb1'],
    'circle-radius': ['step', ['get', 'point_count'], 30, 100, 30, 750, 40]
  }
};

export const clusterCountLayer = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'cameras',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': ['get', 'point_count'],
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12,
    'text-allow-overlap': true
  }
};

export const clusteredPointLayerWarning = {
  id: 'clustered-point-warning',
  type: 'circle',
  source: 'cameras',
  filter: ['all', ['get', 'displayWarning'], ['has', 'point_count']],
  paint: {
    'circle-color': '#ebc934',
    'circle-radius': 14,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#000000',
    'circle-translate': [-18, -20]
  }
};

export const clusteredPointLayerWarningText = {
  id: 'clustered-point-warning-text',
  type: 'symbol',
  source: 'cameras',
  filter: ['all', ['get', 'displayWarning'], ['has', 'point_count']],
  layout: {
    'text-field': '!',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 24,
    'text-allow-overlap': true
  },
  paint: {
    'text-color': '#000000',
    'text-translate': [-19, -20]
  }
};

export const unclusteredPointLayer = {
  id: 'unclustered-point',
  type: 'circle',
  source: 'cameras',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': '#AA0000',
    'circle-radius': 20,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#750000',
    'circle-opacity': 0.5
  }
};

export const unclusteredPointLayerInside = {
  id: 'unclustered-point-inside',
  type: 'circle',
  source: 'cameras',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': '#ED0000',
    'circle-radius': 6,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#ED0000'
  }
};

export const unclusteredPointLayerCountCircle = {
  id: 'unclustered-point-count-circle',
  type: 'circle',
  source: 'cameras',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': '#0093C5',
    'circle-radius': 14,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#0093C5',
    'circle-translate': [16, -16]
  }
};

export const unclusteredPointLayerWarning = {
  id: 'unclustered-point-warning',
  type: 'circle',
  source: 'cameras',
  filter: ['all', ['get', 'displayWarning'], ['!', ['has', 'point_count']]],
  paint: {
    'circle-color': '#ebc934',
    'circle-radius': 14,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#000000',
    'circle-translate': [-16, -16]
  }
};

export const unclusteredPointLayerWarningText = {
  id: 'unclustered-point-warning-text',
  type: 'symbol',
  source: 'cameras',
  filter: ['all', ['get', 'displayWarning'], ['!', ['has', 'point_count']]],
  layout: {
    'text-field': '!',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 24,
    'text-allow-overlap': true
  },
  paint: {
    'text-color': '#000000',
    'text-translate': [-17, -16]
  }
};

export const unclusteredPointLayerCount = {
  id: 'unclustered-point-count',
  type: 'symbol',
  source: 'cameras',
  filter: ['!', ['has', 'point_count']],
  layout: {
    'text-field': ['get', 'insightCountText'],
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12,
    'text-allow-overlap': true
  },
  paint: {
    'text-color': '#FFFFFF',
    'text-translate': [16, -16]
  }
};

