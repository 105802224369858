import React, { useState } from 'react';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import withStyles from '@material-ui/core/styles/withStyles';

import AccountProfile from '../Account/AccountProfile';
import AccountSecurity from '../Account/AccountSecurity';
import AccountBilling from '../Account/AccountBilling';
import HomePageWrapper from '../Common/PageWrappers/HomePageWrapper';
import TabContentContainer from '../Common/ContentContainers/TabContentContainer';

const styles = (theme) => ({
  toolbar: theme.mixins.toolbar,
});

const AccountRedo = (props) => {
  const { classes } = props;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const openSnackbar = (message, severity) => {
    setSnackbarOpen(true);
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const tabs = [
    {
      label: "Profile",
      content: (<AccountProfile openSnackbar={openSnackbar}/>)
    },
    {
      label: "Billing",
      content: (<AccountBilling openSnackbar={openSnackbar}/>)
    },
    {
      label: "Security",
      content: (<AccountSecurity openSnackbar={openSnackbar}/>)
    }
  ];

  return (
    <HomePageWrapper>
      <TabContentContainer tabs={tabs}/>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <br />
    </HomePageWrapper>
  );
};

export default withStyles(styles)(AccountRedo);
