import axios from 'axios';
import { server } from '../actions/backend-url';

export const FETCH_GROUPS_BEGIN = 'FETCH_GROUPS_BEGIN';
export const FETCH_GROUPS_SUCCESS = 'FETCH_GROUPS_SUCCESS';
export const FETCH_GROUPS_FAILURE = 'FETCH_GROUPS_FAILURE';

export const ADD_DEVICE_TO_GROUP_BEGIN = 'ADD_DEVICE_TO_GROUP_BEGIN';
export const ADD_DEVICE_TO_GROUP_SUCCESS = 'ADD_DEVICE_TO_GROUP_SUCCESS';
export const ADD_DEVICE_TO_GROUP_FAILURE = 'ADD_DEVICE_TO_GROUP_FAILURE';

export const REMOVE_DEVICE_FROM_GROUP_BEGIN = 'REMOVE_DEVICE_FROM_GROUP_BEGIN';
export const REMOVE_DEVICE_FROM_GROUP_SUCCESS = 'REMOVE_DEVICE_FROM_GROUP_SUCCESS';
export const REMOVE_DEVICE_FROM_GROUP_FAILURE = 'REMOVE_DEVICE_FROM_GROUP_FAILURE';

export const UPDATE_GROUP_BEGIN = 'UPDATE_GROUP_BEGIN';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILURE = 'UPDATE_GROUP_FAILURE';

export const CREATE_GROUP_BEGIN = 'CREATE_GROUP_BEGIN';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAILURE = 'CREATE_GROUP_FAILURE';

export const DELETE_GROUP_BEGIN = 'DELETE_GROUP_BEGIN';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';


export const removeDeviceFromGroupBegin = () => {
  return {
    type: REMOVE_DEVICE_FROM_GROUP_BEGIN,
  };
};

export const removeDeviceFromGroupSuccess = (response, groupId) => {
  return {
    type: REMOVE_DEVICE_FROM_GROUP_SUCCESS,
    payload: { response, groupId },
  };
};

export const removeDeviceFromGroupFailure = (error) => {
  return {
    type: REMOVE_DEVICE_FROM_GROUP_FAILURE,
    payload: { error },
  };
};

export function removeDeviceFromGroup(groupId, deviceId) {
  return (dispatch) => {
    dispatch(removeDeviceFromGroupBegin());
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    return axios
      .delete(`${server}/api/v2/groups/${groupId}/devices/${deviceId}`)
      .then((response) => {
        dispatch(removeDeviceFromGroupSuccess(response, groupId));
        return response;
      })
      .catch((error) => dispatch(removeDeviceFromGroupFailure(error)));
  };
}

export const addDeviceToGroupBegin = () => {
  return {
    type: ADD_DEVICE_TO_GROUP_BEGIN,
  };
};

export const addDeviceToGroupSuccess = (response, groupId) => {
  return {
    type: ADD_DEVICE_TO_GROUP_SUCCESS,
    payload: { response, groupId },
  };
};

export const addDeviceToGroupFailure = (error) => {
  return {
    type: ADD_DEVICE_TO_GROUP_FAILURE,
    payload: { error },
  };
};

export function addDeviceToGroup(groupId, deviceId) {
  return (dispatch) => {
    dispatch(addDeviceToGroupBegin());
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    return axios
      .post(`${server}/api/v2/groups/${groupId}/devices`, {
        deviceId
      })
      .then((response) => {
        dispatch(addDeviceToGroupSuccess(response, groupId));
        return response;
      })
      .catch((error) => dispatch(addDeviceToGroupFailure(error)));
  };
}

// Group
export const fetchGroupsBegin = () => {
  return {
    type: FETCH_GROUPS_BEGIN,
  };
};

export const fetchGroupsSuccess = (response) => {
  return {
    type: FETCH_GROUPS_SUCCESS,
    payload: { response },
  };
};

export const fetchGroupsFailure = (error) => {
  return {
    type: FETCH_GROUPS_FAILURE,
    payload: { error },
  };
};

export function fetchGroups() {
  return (dispatch) => {
    dispatch(fetchGroupsBegin());
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    return axios
      .get(`${server}/api/v2/groups`)
      .then((response) => {
        dispatch(fetchGroupsSuccess(response));
        return response;
      })
      .catch((error) => dispatch(fetchGroupsFailure(error)));
  };
}

//Updating a Group
export const updateGroupBegin = () => {
  return {
    type: UPDATE_GROUP_BEGIN,
  };
};

export const updateGroupSuccess = (response) => {
  return {
    type: UPDATE_GROUP_SUCCESS,
    payload: { response },
  };
};

export const updateGroupFailure = (error) => {
  return {
    type: UPDATE_GROUP_FAILURE,
    payload: { error },
  };
};

export function updateGroup(group) {
  return (dispatch) => {
    dispatch(updateGroupBegin());

    if (!group.group_name || group.group_name?.length === 0)
      return dispatch(updateGroupFailure('The new group name cannot be empty'));

    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    return axios
      .put(`${server}/api/v2/groups/${group.group_id.toString()}`, {
        groupName: group.group_name,
        groupDesc: group.deployment_description,
      })
      .then((response) => {
        dispatch(updateGroupSuccess(response));
        return response;
      })
      .catch((error) => dispatch(updateGroupFailure(error)));
  };
}

export const createGroupBegin = () => {
  return {
    type: CREATE_GROUP_BEGIN,
  };
};

export const createGroupSuccess = (group) => {
  return {
    type: CREATE_GROUP_SUCCESS,
    payload: { group },
  };
};

export const createGroupFailure = (error) => {
  return {
    type: CREATE_GROUP_FAILURE,
    payload: { error },
  };
};

export function createGroup(group) {
  return (dispatch) => {
    dispatch(createGroupBegin());
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = { Authorization: `${authToken}` };

    if (!group.group_name) {
      dispatch(createGroupFailure());
      return;
    }

    return axios
      .post(`${server}/api/v2/groups`, {
        groupName: group.group_name,
        deploymentDescription: group.deployment_description
      })
      .then((response) => {
        /** @type {GroupData} The GroupData for the new Group, with all the server data too */
        const newGroup = response.data.message;
        dispatch(createGroupSuccess(newGroup));
        return response;
      })
      .catch((error) => dispatch(createGroupFailure(error)));
  };
}

export const deleteGroupBegin = () => {
  return {
    type: DELETE_GROUP_BEGIN,
  };
};

export const deleteGroupSuccess = (group) => {
  return {
    type: DELETE_GROUP_SUCCESS,
    payload: { group },
  };
};

export const deleteGroupFailure = (error) => {
  return {
    type: DELETE_GROUP_FAILURE,
    payload: { error },
  };
};

// NOTE, needs to be followed by a new fetch devicess to detect changes to `device.group_id`s
export function deleteGroup(group) {
  return (dispatch) => {
    dispatch(deleteGroupBegin());

    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    return axios
      .delete(`${server}/api/v2/groups/${group.group_id.toString()}`)
      .then((response) => {
        dispatch(deleteGroupSuccess(group));
        return response;
      })
      .catch((error) => dispatch(deleteGroupFailure(error)));
  };
}

