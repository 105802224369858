import React from 'react';
import { IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import DownloadIcon from '@mui/icons-material/Download';

import withStyles from '@material-ui/core/styles/withStyles';
import moment from 'moment-timezone';
import ModelModal from '../Modal/modelModal';
import DeviceDrawer from '../Drawer/models/deviceDrawer';

import '../../util/typedefs';
import { createTheme } from '@mui/material';
import Paper from '@mui/material/Paper';

const theme_bp = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 1050,
      lg: 1200,
      xl: 1536,
    },
  },
});

const styles = (theme) => ({
  button: {
    '&:focus': {
      color: 'white',
      backgroundColor: '#0A94C7',
    },
    '&:hover': {
      color: 'white',
      backgroundColor: '#0A94C7',
    },
  },
  noMargin: { '> div': { margin: 0 } },
  modelView: {
    width: '100%',
    rowGap: '16px',
    columnGap: '24px',
    padding: '16px',
    display: 'flex',
    margin: 'auto',
    '& div': {
      margin: 0,
    },
    [theme_bp.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    [theme_bp.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
});

const ModelView = (props) => {
  const {
    classes,
    model,
    deployedModelData,
    marketplaceDataClasses,
    deviceStore,
  } = props;
  let modelDateCreated = moment(model.date_created)
    .tz('UTC')
    .format('MMM Do YYYY');
    
  //TODO: Move this information away from marketplace.
  const marketPlaceModelClasses =
    marketplaceDataClasses == null
      ? null
      : marketplaceDataClasses[model['alg_id']];

  let modelDevicesList = [];
  for (let deployment of deployedModelData) {
    let name = deviceStore[deployment.device_id];
    if (
      name &&
      model.alg_id === deployment.alg_id &&
      !modelDevicesList.includes(name)
    ) {
      modelDevicesList.push(name);
    }
  }
  let modelDevices = modelDevicesList.join(', ');

  return (
    <Paper className={classes.modelView}>
      <div
        className={classes.noMargin}
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          rowGap: 16,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            size="small"
            color="primary"
            className={classes.button}
            variant="outlined"
          >
            {model.alg_name}
          </Button>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: '8px',
              alignItems: 'center',
            }}
          >
            <ModelModal modelState={model} />
          </div>
        </div>

        {marketPlaceModelClasses &&
          marketPlaceModelClasses.map((el) => el.original_label).join(', ')}

        {/* <DeviceDrawer algId={model.alg_id} groupId={model.alg_id} /> */}
      </div>
      <div style={{ flex: 1 }}>
        <p>
          <b>Model ID:</b> {model.alg_id}
        </p>

        <p>
          <b>Model Description:</b> {model.alg_description}
        </p>

        <p>
          <b>Data Type:</b> {model.alg_type}
        </p>

        <p>
          <b>Date Created (UTC):</b> {modelDateCreated}
        </p>

        <p>
          <b>Devices:</b> {modelDevices}
        </p>

        <p>
          <b>Author:</b> {model.author}
        </p>

        <p>
          <b>Framework:</b> {model.framework}
        </p>
      </div>
    </Paper>
  );
}

export default withStyles(styles)(ModelView);