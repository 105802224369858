import axios from "axios";
import { Action } from "./actionsShared";
import { server } from "./backend-url";

export const FETCH_MARKETPLACE_MODELS_PREFIX = "FETCH_MARKETPLACE_MODELS";

export const fetchMarketplaceModels = () => {
  const url = "/api/v2/marketplace/models";
  return Action.GET(FETCH_MARKETPLACE_MODELS_PREFIX, url, true);
};

export const addModelToUserAccount = async (modelId) => {
  const authToken = localStorage.getItem('AuthToken');
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  try {
    await axios.post(`${server}/api/v2/marketplace/models`, {
      modelId
    });
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

