import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    root: {
      fontFamily: "Nunito",
    },
    appbarTitle: {
      flexGrow: "1",
      fontSize: "1vw",
    },
    appbarWrapper: {
      width: "80%",
      margin: "0 auto"
    },
    colorText: {
      color: "#34cbc2"
    },
    container: {
      textAlign: "center",
      height: "50vh",
      marginTop: "80px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",

    },
    title: {
      color: "#000",
      fontSize: "2vw",
    },
    buttontext: {
        fontFamily: "Nunito",
        fontWeight: 'bold',
        color: '#2bbcd4',
    },
    goDown: {
      color: "#2bbcd4",
      fontSize: "4vw"
    }
  }));

  export default function GetStarted() {
    const classes = useStyles();


    return (
        <div className={classes.root}>
                <div id="getstarted" className={classes.container}>
                    <h1 className={classes.title}>
                        This is where the footer goes.
                    </h1>
                    <h1 className={classes.title}>
                        But we forgot to add one.
                    </h1> 
                </div>
        </div>
    );
}