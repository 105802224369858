const getServer = () => {
  console.log(process.env)
  if (process.env.REACT_APP_LOCAL_DEV_ENVIRONMENT === "true") {
    console.log("Running against local backend.");
    return "http://localhost:5001/tyto-9af2b/us-central1";
  } else {
    console.log("Running against production backend.");
    return "https://us-central1-tyto-9af2b.cloudfunctions.net";
  }
}

export const server = getServer();