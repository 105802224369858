import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { useSelector } from 'react-redux';

import MarketplaceCard from './marketplaceCard';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { Grid } from '@material-ui/core';

import { createTheme } from '@mui/material';

// Search input
import InputBase from '@material-ui/core/InputBase';
import CenteredCircularProgress from '../Common/CenteredCircularProgress';

//swipeableView
import GeneralSwipeableView from '../Common/ContentContainers/GeneralSwipeableView';

const theme_bp = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 1050,
      lg: 1200,
      xl: 1536,
    },
  },
});

// Style
const styles = (theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(3),
  },
  filters: {
    display: 'flex',
    flexDirection: 'column',
    columnGap: 8,
    rowGap: 24,
  },
  searchbar: {
    background: 'white',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(16,22,26,.2)',
    minWidth: 300,
    padding: '10px 10px',
    width: '100%',
    // marginBottom: theme.spacing(3),
  },

  typeFilter: {
    minWidth: 300,

    background: 'white',
    boxShadow: '0 3px 5px 2px rgb(16 22 26 / 20%)',
  },

  noLabel: {
    marginTop: theme.spacing(3),
  },
  filter: {
    minWidth: 200,
    width: '100%',
    background: 'white',
    boxShadow: '0 3px 5px 2px rgb(16 22 26 / 20%)',
  },
  inputRoot: { width: '100%' },
  inputInput: { width: '100%' },
  filterContainer: {
    display: 'flex',
    columnGap: 8,
    rowGap: 24,

    [theme_bp.breakpoints.up('md')]: {
      flexDirection: 'row',
    },

    [theme_bp.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  bigTable: {
    [theme_bp.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  smallTable: {
    [theme_bp.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      minWidth: 250,
      maxWidth: 300,
    },
  },
  getContentAnchorEl: null,
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  inputRoot: { width: '100%' },
  inputInput: { width: '100%' },
};

const MarketplaceGrid = ({ classes }) => {
  const marketplaceModels = useSelector(
    (state) => state.marketplace.marketplaceModels
  );

  const [search, setSearch] = useState('');
  const [filteredModels, setFilteredModels] = useState([]);
  const [classFilter, setClassFilter] = useState([]);
  const [uniqueClassTax, setUniqueClassTax] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleClassFilter = (model, classList) => {
    for (const modelClass of classList) {
      if (!model.classes.includes(modelClass)) {
        return false;
      }
    }
    return true;
  };

  const handleSearchFilter = (model, searchString) => {
    return model.name.toLowerCase().includes(searchString.toLowerCase()) ||
      model.author.toLowerCase().includes(searchString.toLowerCase()) ||
      model.framework.toLowerCase().includes(searchString.toLowerCase());
  };

  useEffect(() => {
    if (marketplaceModels && marketplaceModels.length > 0) {
      setLoading(false);
    }
    const taxSet = new Set();
    for (const model of marketplaceModels) {
      for (const modelClass of model.classes) {
        taxSet.add(modelClass.toLowerCase());
      }
    }
    setUniqueClassTax([...taxSet].sort());
  }, [marketplaceModels]);

  useEffect(() => {
    // if something has been filtered
    if (search !== '' || classFilter.length > 0) {
      const newFilter = [];
      for (const model of marketplaceModels) {
        if (handleClassFilter(model, classFilter) && handleSearchFilter(model, search)) {
          newFilter.push(model);
        }
      }

      setFilteredModels(newFilter);
    } else {
      setFilteredModels(marketplaceModels);
    }

  }, [search, marketplaceModels, classFilter]);

  return (
    <main className={classes.content}>
      <div className={classes.filters}>
        <div className={classes.searchbar}>
          <InputBase
            placeholder="Search Models by Name, Author, or Framework"
            className={classes.inputInput}
            InputProps={{
              classes: {
                root: classes.inputRoot,
              },
            }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            onChange={(event) => setSearch(event.target.value)}
            value={search}
          />
        </div>

        <div className={classes.filterContainer}>
          <Autocomplete
            className={classes.filter}
            multiple
            id="tags-outlined"
            options={uniqueClassTax}
            getOptionLabel={(option) => option}
            value={classFilter}
            filterSelectedOptions
            onChange={(e, newValue) => {
              setClassFilter(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Filter by Species" placeholder="" />
            )}
          />
        </div>
      </div>
      <div className={classes.bigTable}>
        <h1>PUBLIC MODELS</h1>
        {loading ? <CenteredCircularProgress size={100} /> : <Grid container spacing={1}>
          {filteredModels.map((model) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={model.alg_id}
              style={{ marginBottom: '30px' }}
            >
              <MarketplaceCard
                model={model}
              />
            </Grid>
          ))}
        </Grid>}
      </div>
      <div className={classes.smallTable}>
        <h1>PUBLIC MODELS</h1>
        <GeneralSwipeableView
          content={
            filteredModels.map((model) => (
              <MarketplaceCard
                model={model}
              />
            ))}
        />
      </div>
    </main>
  );
};

export default withStyles(styles)(MarketplaceGrid);
