import React, { useState, useEffect } from 'react';

import WidgetCommon from '../Common/widgetCommon'
import { Tooltip, withStyles } from '@material-ui/core';
import { getDisplayName } from '../../../util/deviceHelpers';
import { useMediaQuery } from 'react-responsive';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);
const styles = (theme) => ({});

const popperProps = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, -30],
      },
    },
  ],
};

const HoursSinceLastTable = (props) => {
  const { isLoading, insights, devices } = props;
  const [graphData, setGraphData] = useState([]);

  const isMobile = useMediaQuery({ query: '(max-width: 625px)' });

  useEffect(() => {
    if (devices && insights) {
      const currentDate = new Date((Date.now()) + ((new Date()).getTimezoneOffset() * 60 * 1000));
      const dataMap = {};
      for (const device of devices) {
        if (device.last_seen) {
          dataMap[device.device_id] = {
            device_name: getDisplayName(device),
            hours_check_in: Math.floor((currentDate - new Date(device.last_seen)) / (1000 * 60 * 60))
          }
        }
      }
      for (const insight of insights) {
        if (insight.device_id && insight.device_id in dataMap) {
          const insightTime = new Date(insight.time_stamp)
          if (!dataMap[insight.device_id].insight_time || dataMap[insight.device_id].insight_time < insightTime) {
            dataMap[insight.device_id].insight_time = insightTime;
            dataMap[insight.device_id].hours_insight = Math.floor((currentDate - insightTime) / (1000 * 60 * 60));
          }
        }
      }
      setGraphData(Object.values(dataMap));
    }
  }, [insights, devices])

  return (
    <WidgetCommon
      title="Last Device Communication (Hours)"
      isLoading={isLoading} hasNoData={graphData.length === 0}
      hasTooMuchData={graphData.length >= (isMobile ? 20 : 25)}
      tooMuchDataString="Too many devices. Please filter to fewer devices."
      height="20vh"
      maxHeight="150px"
      info="Hours since last insight can change based on the filters applied to the data."
    >
      <TableContainer component={Paper} style={{ maxHeight: "17vh", overflow: 'scroll' }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Device Name</TableCell>
              <TableCell>Check-In</TableCell>
              <TableCell>Insight</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {graphData.map((row, index) => (
              <Tooltip  PopperProps={popperProps} arrow interactive title={row.device_name}>
                <TableRow key={index}>
                  <TableCell>
                    {row.device_name}
                  </TableCell>
                  <TableCell>{row.hours_check_in}</TableCell>
                  <TableCell>{row.hours_insight}</TableCell>
                </TableRow>
              </Tooltip>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </WidgetCommon>
  )
}

export default withStyles(styles)(HoursSinceLastTable);