import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  withStyles,
  Avatar,
  Grid,
  TextField,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import {
  fetchUser,
  updateUser,
  updateUserPhoto,
} from '../../actions/userActions.js';

const styles = (theme) => ({
  profilePhotoHeader: {
    marginLeft: '25%',
  },
  profilePhotoCard: {
    marginTop: theme.spacing(1),
    borderRadius: '10px',
    backgroundImage: 'linear-gradient(bottom, #FFFFFF 50%, #0093C5 50%)',
    height: '0', // hack to get the aspect ratio of the card to be constant
    position: 'relative',
    margin: '0 auto',
    paddingBottom: '60%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      paddingBottom: '30%',
    },
  },
  uploadLabel: {
    borderRadius: '50%',
    position: 'relative',
    padding: '6px',
    cursor: 'pointer',
    background: '#0093C5',
  },
  uploadImageContainer: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '50%',
    '&::before': {
      fontFamily: 'Material Icons',
      fontFeatureSettings: "'liga' 1",
      mozFontFeatureSettings: "'liga' 1",
      webkitFontFeatureSettings: "'liga' 1",
      content: '"file_upload"',
      fontSize: '5rem',
      position: 'absolute',

      textAlign: 'center',
      color: '#0093C5',
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      opacity: 0,
      transition: '.5s ease',
      backgroundColor: '#fff',
      zIndex: 1,
    },
    '&:hover::before': {
      opacity: 0.7,
    },
  },
  uploadImage: {
    width: '100%',
    height: '100%',
  },
  uploadFile: {
    display: 'none',
  },
  userInfoCard: {
    marginTop: theme.spacing(3),
    borderRadus: '18px',
    padding: theme.spacing(3),
  },
  saveDetailsButton: {
    marginTop: theme.spacing(3),
    float: 'right',
  },
  fetchUserProgress: {
    position: 'fixed',
    zIndex: '1000',
    height: '31px',
    width: '31px',
    left: '50%',
    top: '35%',
  },
  updateDetailsProgress: {
    position: 'absolute',
  },
});

const AccountProfile = (props) => {
  const { classes, openSnackbar } = props;

  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [username, setUsername] = useState('');
  const [country, setCountry] = useState('');
  const [profilePicture, setProfilePicture] = useState('');

  // ref used to access the container the profile photo is in
  // so that we can do the formatting properly
  // TODO this is technically not required because we are setting the aspect ratio to be constant
  // so we can use percentages to get the correct diameter of profile photo and margins
  const [photoCardHeight, setPhotoCardHeight] = useState('200px');
  const photoCardRef = useRef(null);

  const userData = useSelector((state) => state.user);
  const fetchUserLoading = userData.fetchUserProgress === 'BEGINNING';
  const saveDetailsLoading = userData.updateUserProgress === 'BEGINNING';
  const updatePhotoLoading = userData.updatePhotoProgress === 'BEGINNING';

  // set to true when component mounts, used in useEffect() to check if this is
  // when the component is first mounting
  const isMounted = useRef(false);

  useEffect(() => {
    const handleResize = () => {
      if (photoCardRef.current != null) {
        setPhotoCardHeight(photoCardRef.current.clientHeight);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // keep track of what the card's height is
  // to prevent infinite loop, do NOT let `photoCardHeight` affect the card's height
  // currently, `photoCardHeight` is used to set up the size of the profile photo
  // inside the photoCard, so if the profile photo somehow causes the size of the card
  // to change, we get an infinite loop

  // eslint-disable-next-line
  useEffect(() => {
    if (photoCardRef.current != null) {
      setPhotoCardHeight(photoCardRef.current.clientHeight);
    }
  });

  useEffect(() => {
    dispatch(fetchUser());

    // eslint-disable-next-line
  }, [props.history]);

  useEffect(() => {
    setFirstName(userData.firstName);
    setLastName(userData.lastName);
    setEmail(userData.email);
    setPhoneNumber(userData.phoneNumber);
    setUsername(userData.username);
    setCountry(userData.country);
    setProfilePicture(userData.imageUrl);
  }, [userData]);

  const onUploadPhoto = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    dispatch(updateUserPhoto(file));
  };

  // update the snackbar with the progress of uploading photo, but not when the component first mounts
  // if we didn't have the component first mounts check, then it would show the snackbar again from the previous update
  useEffect(() => {
    if (isMounted.current) {
      if (updatePhotoLoading) {
        openSnackbar('Uploading photo...', 'info');
      } else {
        if (userData.updatePhotoProgress === 'SUCCESS') {
          openSnackbar(
            'Uploaded photo! You may need to reload to see changes.',
            'success'
          );
        } else if (userData.updatePhotoProgress === 'FAILURE') {
          openSnackbar('Error uploading photo', 'error');
        }
      }
    } else {
      isMounted.current = true;
    }
    // eslint-disable-next-line
  }, [updatePhotoLoading, userData.updatePhotoProgress]);

  const onSaveDetails = (event) => {
    event.preventDefault();

    const newUserData = {
      firstName: firstName,
      lastName: lastName,
      country: country,
    };

    dispatch(updateUser(newUserData));
  };

  // update the snackbar with the progress of the updating user
  useEffect(() => {
    if (saveDetailsLoading) {
      openSnackbar('Saving details...', 'info');
    } else {
      if (userData.updateUserProgress === 'SUCCESS') {
        openSnackbar('Saved details!', 'success');
      } else if (userData.updateUserProgress === 'FAILURE') {
        openSnackbar('Error saving details', 'error');
      }
    }
    // eslint-disable-next-line
  }, [saveDetailsLoading, userData.updateUserProgress]);

  if (fetchUserLoading) {
    return (
      <CircularProgress size={150} className={classes.fetchUserProgress} />
    );
  }

  return (
    <Box>
      <Typography className={classes.profilePhotoHeader} variant="h6">
        Profile Photo
      </Typography>
      <Card className={classes.profilePhotoCard} ref={photoCardRef}>
        <label
          htmlFor="photo-upload"
          className={classes.uploadLabel}
          style={{ marginTop: photoCardHeight / 5 }}
        >
          <div
            className={classes.uploadImageContainer}
            style={{ width: photoCardHeight / 2, height: photoCardHeight / 2 }}
          >
            <Avatar
              htmlFor="photo-upload"
              src={profilePicture}
              className={classes.uploadImage}
            />
          </div>
          <input
            id="photo-upload"
            type="file"
            onChange={onUploadPhoto}
            className={classes.uploadFile}
          />
        </label>
      </Card>

      <Card className={classes.userInfoCard}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="First Name"
                margin="dense"
                name="firstName"
                variant="outlined"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Last Name"
                margin="dense"
                name="lastName"
                variant="outlined"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email"
                margin="dense"
                name="email"
                variant="outlined"
                disabled={true}
                value={email}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                margin="dense"
                name="phone"
                type="tel"
                variant="outlined"
                disabled={true}
                value={phoneNumber}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="User Name"
                margin="dense"
                name="userHandle"
                disabled={true}
                variant="outlined"
                value={username}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Country"
                margin="dense"
                name="country"
                variant="outlined"
                value={country}
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            className={classes.saveDetailsButton}
            onClick={onSaveDetails}
            disabled={saveDetailsLoading || !firstName || !lastName || !country}
          >
            Save details
            {saveDetailsLoading && (
              <CircularProgress
                size={30}
                className={classes.updateDetailsProgress}
              />
            )}
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default withStyles(styles)(AccountProfile);
