export const getInsightTime = (insight) => {
  if (insight && insight.time_stamp && insight.file_ctime) {
    const switchTime = 1694026800 * 1000; // September 6th 2023 (post message fix)
    const timestamp = (new Date(insight.time_stamp)).getTime();
    if (timestamp > switchTime) {
      return insight.file_ctime;
    }
  }
  return insight?.time_stamp;
}

export const getFormattedTimeString = (date) => {
  return (new Date(date)).toISOString().split(".")[0].split("T").join(" ");
}