import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from 'infinite-react-carousel';
import Arm from '../../../images/PartnerLogos/arm-logo.png'
import ASME from '../../../images/PartnerLogos/asme-logo.png'
import FastComp from '../../../images/PartnerLogos/fastco-logo.png'
import Gorongosa from '../../../images/PartnerLogos/gorongosa-logo.png'
import TACC from '../../../images/PartnerLogos/tacc-logo.png'
import SPECIES from '../../../images/PartnerLogos/species-logo.png'
import Osa from '../../../images/PartnerLogos/osa-logo.png'
import Moore from '../../../images/PartnerLogos/moore-logo.png'
import HRA from '../../../images/PartnerLogos/hra-logo.png'
import { useMediaQuery } from 'react-responsive'

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  image: {
    height: '100px',
    width: '100px',
  },
}
));

export default function Partners() {
  const classes = useStyles();

  const isDesktop = useMediaQuery({ query: '(min-width: 1224px)'})
  const isMobile = useMediaQuery({ query: '(max-width: 1224px)'})
  
  const desktopSettings = {
    slidesPerRow: '4',
    // slidesToShow: '4',
    // centerMode: 'true',
    // adaptiveHeight: 'true',
    centerPadding: '2',
    autoplay: 'true',
  }

  const mobileSettings = {
    slidesPerRow: '2',
    // slidesToShow: '4',
    // centerMode: 'true',
    // adaptiveHeight: 'true',
    centerPadding: '2',
    autoplay: 'true',
  }

return (
  <div>
    <br />
    <br />
    <br />
    <br />

  <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
    <h1> Our Partners </h1>
  </div>

{isMobile &&
  <Slider dots {...mobileSettings}>
    <div>
    <a href="https://www.arm.com/why-arm/partner-ecosystem/ai-ecosystem-catalog/conservation-x-labs?searchq=conservation%20x%20labs">
      <img classname={classes.image} src={Arm} alt="" />
      </a>
    </div>
    <div>
    <a href="https://thisishardware.org/competition/2021/usa">
    <img classname={classes.image} src={ASME} alt="" />
    </a>
    </div>
    <div>
    <a href="https://www.fastcompany.com/90667097/impact-innovation-by-design-2021">
    <img classname={classes.image} src={FastComp} alt=""/>
    </a>
    </div>
    <div>
    <a href="https://gorongosa.org/">
    <img classname={classes.image} src={Gorongosa} alt=""/>
    </a>
    </div>
    <div>
    <a href="https://www.tacc.utexas.edu/">
    <img classname={classes.image} src={TACC} alt=""/>
    </a>
    </div>
    <div>
    <a href="https://carnivores.org/">
    <img classname={classes.image} src={SPECIES} alt=""/>
    </a>
    </div>
    <div>
    <a href="https://osaconservation.org/">
    <img classname={classes.image} src={Osa} alt=""/>
    </a>
    </div>
    <div>
    <a href="https://www.moore.org/">
    <img classname={classes.image} src={Moore} alt=""/>
    </a>
    </div>
    <div>
    <a href="https://www.humanerescuealliance.org/">
    <img classname={classes.image} src={HRA} alt=""/>
    </a>
    </div>
  </Slider>}

  {isDesktop &&
  <Slider dots {...desktopSettings}>
    <div>
    <a href="https://www.arm.com/why-arm/partner-ecosystem/ai-ecosystem-catalog/conservation-x-labs?searchq=conservation%20x%20labs">
      <img classname={classes.image} src={Arm} alt="" />
      </a>
    </div>
    <div>
    <a href="https://thisishardware.org/competition/2021/usa">
    <img classname={classes.image} src={ASME} alt="" />
    </a>
    </div>
    <div>
    <a href="https://www.fastcompany.com/90667097/impact-innovation-by-design-2021">
    <img classname={classes.image} src={FastComp} alt=""/>
    </a>
    </div>
    <div>
    <a href="https://gorongosa.org/">
    <img classname={classes.image} src={Gorongosa} alt=""/>
    </a>
    </div>
    <div>
    <a href="https://www.tacc.utexas.edu/">
    <img classname={classes.image} src={TACC} alt=""/>
    </a>
    </div>
    <div>
    <a href="https://carnivores.org/">
    <img classname={classes.image} src={SPECIES} alt=""/>
    </a>
    </div>
    <div>
    <a href="https://osaconservation.org/">
    <img classname={classes.image} src={Osa} alt=""/>
    </a>
    </div>
    <div>
    <a href="https://www.moore.org/">
    <img classname={classes.image} src={Moore} alt=""/>
    </a>
    </div>
    <div>
    <a href="https://www.humanerescuealliance.org/">
    <img classname={classes.image} src={HRA} alt=""/>
    </a>
    </div>
  </Slider>}
  </div>
);
}