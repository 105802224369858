import { Action } from "../actions/actionsShared";
import {
  FETCH_USERS_PREFIX,
  FETCH_DEVICES_PREFIX,
  FETCH_ALGS_PREFIX,
  FETCH_ALG_RELEASES_PREFIX
} from "../actions/internalActions";


const initialState = {
  users: [],
  devices: [],
  algs: [],
  algReleases: []
};

const internalReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_USERS_PREFIX}${Action.BEGIN_SUFFIX}`:
      return {
        ...state,
        result: 'BEGINNING',
      };
    case `${FETCH_USERS_PREFIX}${Action.SUCCESS_SUFFIX}`:
      return {
        ...state,
        users: action.payload.data.data.message,
        result: 'SUCCESS',
      };
    case `${FETCH_USERS_PREFIX}${Action.FAILURE_SUFFIX}`:
      return {
        ...state,
        result: 'FAILURE'
      }
    case `${FETCH_DEVICES_PREFIX}${Action.BEGIN_SUFFIX}`:
      return {
        ...state,
        result: 'BEGINNING',
      };
    case `${FETCH_DEVICES_PREFIX}${Action.SUCCESS_SUFFIX}`:
      return {
        ...state,
        devices: action.payload.data.data.message,
        result: 'SUCCESS',
      };
    case `${FETCH_DEVICES_PREFIX}${Action.FAILURE_SUFFIX}`:
      return {
        ...state,
        result: 'FAILURE'
      }
    case `${FETCH_ALGS_PREFIX}${Action.BEGIN_SUFFIX}`:
      return {
        ...state,
        result: 'BEGINNING',
      };
    case `${FETCH_ALGS_PREFIX}${Action.SUCCESS_SUFFIX}`:
      return {
        ...state,
        algs: action.payload.data.data.message,
        result: 'SUCCESS',
      };
    case `${FETCH_ALGS_PREFIX}${Action.FAILURE_SUFFIX}`:
      return {
        ...state,
        result: 'FAILURE'
      }
    case `${FETCH_ALG_RELEASES_PREFIX}${Action.BEGIN_SUFFIX}`:
      return {
        ...state,
        result: 'BEGINNING',
      };
    case `${FETCH_ALG_RELEASES_PREFIX}${Action.SUCCESS_SUFFIX}`:
      return {
        ...state,
        algReleases: action.payload.data.data.message,
        result: 'SUCCESS',
      };
    case `${FETCH_ALG_RELEASES_PREFIX}${Action.FAILURE_SUFFIX}`:
      return {
        ...state,
        result: 'FAILURE'
      }
    default:
      return state;
  }
};

export default internalReducer;
