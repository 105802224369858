import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';


const useStyles = makeStyles({
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  checkbox: {
    paddingLeft: 0
  }
});


export default function DownloadPDFModal(props) {
  const { element } = props;
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const downloadPdf = async () => {
    if (!element) {
      return;
    }
    const canvas = await html2canvas(document.querySelector(element), {scrollY: -window.scrollY});
    const image = canvas.toDataURL('image/png');
    const pdf = new jsPDF({
      orientation: 'portrait',
    });
    const imgProps= pdf.getImageProperties(image)
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(image, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save("dashboard-export.pdf");
    setOpen(false);
  }

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          setOpen(true);
        }}
      >
        Download PDF
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          Export to PDF
        </DialogTitle>
        <DialogActions>
          <Button color="primary" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={downloadPdf}
            variant="contained"
          >
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
