const info = [
    {
      title: '	3.	Expand your outreach with advanced communications',
      description:
        "		The Sentinel device allows for data transfer over Wifi, Cellular, LoRa, and Satellite networks. Get notified fast on what's being recorded in the wild, straight to your mobile or desktop. ",
      // imageUrl: "https://images.unsplash.com/photo-1583470790878-4f4f3811a01f?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1955&q=80",
      time: 1500,
    },
    // {
    //   title: 'Artificial Intelligence Technologies for Everyone',
    //   description:
    //     'Browse, Create, Host, Deploy, and Manage AI models without seeing a single line of code. Review all your insights from your own free dashboard and get the information you\'re looking for immediately from the wild, to act on or use for decision-making.',
    //   // imageUrl: "https://images.unsplash.com/photo-1583212292454-1fe6229603b7?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1267&q=80",
    //   time: 1500,
    // },
  ];
  
  export default info;