import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

// Pages
import Dashboard from '../components/Subpages/Dashboard';
import Account from '../components/Subpages/Account';
import Admin from '../components/Subpages/Admin';
import Models from '../components/Subpages/Models';
import UserInsights from '../components/Subpages/UserInsights';
import Devices from '../components/Subpages/Devices';
import MapPage from '../components/Subpages/MapPage';
import PageUnderConstruction from '../components/Subpages/PageUnderConstruction';
import ModelMarketplace from '../components/Subpages/ModelMarketplace';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import CssBaseline from '@material-ui/core/CssBaseline';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '../components/Appbar/appbar';
import DrawerDash from '../components/Drawer/DrawerDash';
import { authMiddleWare } from '../util/auth';

import { useSelector, useDispatch } from 'react-redux';
import { fetchUser } from '../actions/userActions';
import {
  fetchModels,
} from '../actions/deviceActions';
import {
  fetchUserInsightsGraph,
} from '../actions/userInsightsActions';
import { Page } from '../util/constants';

import Alert from '@material-ui/lab/Alert';



import '../util/typedefs';
import '../App.css';
import Groups from '../components/Subpages/Groups';
import Internal from '../components/Subpages/Internal';
import { Typography } from '@material-ui/core';
import DeviceUpdates from '../components/Subpages/DeviceUpdates';

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: ' row',
    width: '100vw',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  banner: {
    minHeight: "100px",
    backgroundColor: "#f2cb96",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  avatar: {
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
    marginTop: 20,
  },
  uiProgess: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'fixed',
    zIndex: '1000',
  },
  toolbar: theme.mixins.toolbar,
  appBarContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100vh',
    overflow: 'auto',
  },
  collapseDrawerButton: {
    [theme.breakpoints.down('sm')]: {
      top: 0,
      right: 0,
      // position: 'absolute',
      zIndex: 2000,
    },

    position: 'absolute',
    left: '-16px',
    top: '8%',
    height: '32px',
    width: '32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    color: '#0093C5',
    border: 'none',
    backgroundColor: 'white',
    zIndex: 1000,
    cursor: 'pointer'
  },
  collapseDrawerButtonContainer: {
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      top: '5vh',
      right: '10vw',
      zIndex: 2000,
      transition: 'right 0.5s ease-out',
    },
    // [theme.breakpoints.up('sm')]: {
    width: 0,
    height: '100vh',
    position: 'relative',
    zIndex: 1200,

    //},
  },

  collapseDrawerButtonContainerCollapse: {
    [theme.breakpoints.down('sm')]: {
      right: '110vw',
    },
  },
  collapseDrawerButtonContainerExpand: {
    [theme.breakpoints.down('sm')]: {
      right: '10vw',
    },
  },
});

const Home = (props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 740px)' });

  const [tab, setTab] = useState(['dashboard']);
  const [uiLoading, setUiLoading] = useState(true);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [collapseNav, setCollapseNav] = useState(isMobile);
  const [userId, setUserId] = useState('');

  /** @type {UserData} */
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const logoutHandler = () => {
    localStorage.removeItem('AuthToken');
    localStorage.removeItem('userId');
    dispatch({ type: 'USER_LOGOUT' });
    props.history.push('/login');
  };

  useEffect(() => {
    if (user.fetchUserProgress === "FAILURE") {
      logoutHandler();
    }
  }, [user])

  useEffect(() => {
    if (!user || !user.userIdSql) {
      dispatch(fetchUser());
    } else {
      if ((!userId || userId.length === 0)) {
        setUserId(user.userIdSql);
      }
    }
  }, [user.userIdSql]);

  useEffect(() => {
    if (userId) {
      authMiddleWare(props.history);
      dispatch(fetchUserInsightsGraph());
      setUiLoading(false);
    }
  }, [userId]);

  /**
   *
   * @param {Page} param
   * @returns JSX.Element
   */
  const renderSwitch = (param) => {
    switch (param[0]) {
      case Page.USER_INSIGHTS:
        return <UserInsights setTab={setTab} pageProps={param[1]} />;
      case Page.DASHBOARD:
        return <Dashboard setTab={setTab} />;
      case Page.DEVICE:
        return <Devices />;
      case Page.DEVICE_UPDATES:
        return <DeviceUpdates />;
      case Page.GROUPS:
        return <Groups />;
      case Page.ACCOUNT:
        return <Account />;
      case Page.MODELS:
        return <Models />;
      case Page.MAP:
        return <MapPage setTab={setTab} />;
      case Page.MARKETPLACE:
        return <ModelMarketplace setTab={setTab} />;
      case Page.ADMIN:
        return <Admin />;
      case Page.INTERNAL:
        return <Internal />;
      default:
        return <PageUnderConstruction />;
    }
  };

  const [showAlert, setShowAlert] = useState(false);

  const { classes } = props;
  const [toggleDrawer, setToggleDrawer] = useState(false);

  useEffect(() => {
    if (uiLoading) {
      setTimeout(function () {
        setShowAlert(true);
      }, 5000);
    } else {
      setShowAlert(false);
    }
  }, [uiLoading]);

  return (
    <div className={classes.root}>
      {uiLoading && (
        <div className={classes.uiProgess}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              rowGap: 32,
            }}
          >
            <CircularProgress size={150} />
            {showAlert && (
              <Alert severity="info" color="info">
                If the page is taking too long to load, please refresh.
              </Alert>
            )}
          </div>
        </div>
      )}
      {!uiLoading && (
        <React.Fragment>
          <CssBaseline />
          <DrawerDash
            collapseNav={collapseNav}
            setCollapseNav={setCollapseNav}
            mobileOpen={mobileOpen}
            setMobileOpen={setMobileOpen}
            tab={tab}
            isMobile={isMobile}
            setTab={setTab}
            logoutHandler={logoutHandler}
            setToggleDrawer={setToggleDrawer}
            toggleDrawer={toggleDrawer}
          />
          <div
            className={`${classes.collapseDrawerButtonContainer} ${collapseNav
                ? classes.collapseDrawerButtonContainerCollapse
                : classes.collapseDrawerButtonContainerExpand
              }`}
          >
            <button
              className={classes.collapseDrawerButton}
              onClick={() => {
                setCollapseNav(!collapseNav);
              }}
            >
              {collapseNav ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </button>
          </div>
          <div className={classes.appBarContent}>
            <AppBar
              setCollapseNav={setCollapseNav}
              mobileOpen={mobileOpen}
              setMobileOpen={setMobileOpen}
              tab={tab}
              setTab={setTab}
              logoutHandler={logoutHandler}
              setToggleDrawer={setToggleDrawer}
            />
            {/* <div className={classes.banner}>
              <Typography>We are experiencing technical difficults and are working to resolve them. We appologize for the inconvience. Reach out to Sentinel Support if you have questions.</Typography>
            </div> */}
            <div className={'contentContainer'}>{renderSwitch(tab)}</div>
          </div>
          {/* <Footer /> */}
        </React.Fragment>
      )}
    </div>
  );
};

export default withStyles(styles)(Home);
