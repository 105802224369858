import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import { TextField, Typography } from '@material-ui/core';
import { updateAlgMetadata } from '../../../actions/internalActions';

export default function UpdateAlgConfigModal(props) {
  const { algId, open, handleClose, bucket, path } = props;
  const [newBucket, setNewBucket] = useState(bucket);
  const [newPath, setNewPath] = useState(path);
  const [errorMessage, setErrorMessage] = useState("");


  const handleSave = async () => {
    setErrorMessage("");
    if (algId && newBucket && newPath) {
      const response = await updateAlgMetadata(algId, newBucket, newPath);
      if (!response) {
        setErrorMessage("Can't update alg metadata. Check that the bucket and path point to the correct tflite file.")
      } else {
        handleClose();
      }
    } else {
      setErrorMessage("Missing information. Check that bucket and path are both set.")
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Update Alg Metadata: {algId}</DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            rows={4}
            inputProps={{
              maxLength: 100
            }}
            margin="dense"
            label="Bucket"
            name="bucket"
            value={newBucket}
            onChange={(event) => setNewBucket(event.currentTarget.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            rows={4}
            inputProps={{
              maxLength: 100
            }}
            margin="dense"
            label="Path"
            name="bucket"
            value={newPath}
            onChange={(event) => setNewPath(event.currentTarget.value)}
          />
        </Grid>
        {errorMessage && errorMessage.length > 0 &&
        <Grid item xs={12}>
        <Typography variant='body1' color="textSecondary">{errorMessage}</Typography>
      </Grid>}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSave}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
