import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles } from '@material-ui/styles';
import DownloadCSVModal from './downloadCSV';
import CustomTableFilters from './CustomTableFilters';
import { getFilterableHeaders } from '../../../util/filters';
import { useMediaQuery } from 'react-responsive';

const useStyles = makeStyles({
  spacing: {
    marginRight: "10px"
  }
});

export default function CustomTableToolbar(props) {
  const { rowData, headers, updateFilters, filteredRowData, filters, handleArchivedToggle, showArchived, AdditionalToolbar, ButtonFilters } = props;
  const classes = useStyles();
  const isMobile = useMediaQuery({ query: '(max-width: 625px)' });

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 }
      }}
    >
      <CustomTableFilters headers={getFilterableHeaders(headers)} filters={filters} updateFilters={updateFilters} handleArchivedToggle={handleArchivedToggle} showArchived={showArchived} ButtonFilters={ButtonFilters}/>
      <div className={classes.spacing} />
      <DownloadCSVModal rowData={rowData} headers={headers} filteredRowData={filteredRowData} />
      <div className={classes.spacing} />
      {AdditionalToolbar && !isMobile ? AdditionalToolbar : null}
    </Toolbar >
  );
}