import React, { useState, useEffect } from 'react';

import { ResponsiveBar } from '@nivo/bar'
import WidgetCommon from '../Common/widgetCommon'
import { withStyles } from '@material-ui/core';
import { limitString } from '../../../util/tableUtils';
import { useMediaQuery } from 'react-responsive';

const styles = (theme) => ({});

const InsightsByClass = (props) => {
  const { isLoading, insights } = props;
  const [graphData, setGraphData] = useState([]);
  const [graphType, setGraphType] = useState('linear');

  const isMobile = useMediaQuery({ query: '(max-width: 625px)' });


  useEffect(() => {
    if (insights) {
      const dataMap = {};
      for (const insight of insights) {
        if (insight.class_name) {
          const className = limitString(insight.class_name.toLowerCase(), 13);
          if (!(className in dataMap)) {
            dataMap[className] = {
              class_name: className,
              count: 0
            };
          }
          dataMap[className].count += 1;
        }
      }
      setGraphData(Object.values(dataMap));
    }
  }, [insights])

  return (
    <WidgetCommon
      title="Insights by Class"
      isLoading={isLoading}
      hasNoData={graphData.length === 0}
      hasTooMuchData={graphData.length >= (isMobile ? 25 : 30)}
      tooMuchDataString="Too many insight classes. Please filter to fewer class names."
      graphSwitch={true} setGraphType={setGraphType} graphType={graphType}
    >
      <ResponsiveBar
        data={graphData}
        keys={["count"]}
        indexBy="class_name"
        margin={{ top: isMobile ? 10 : 50, right: 10, bottom: 100, left: isMobile ? 40 : 60 }}
        padding={0.3}
        valueScale={{ type: graphType }}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'paired' }}
        borderColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              1.6
            ]
          ]
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 90,
          legend: !isMobile && 'Class Name',
          legendPosition: 'middle',
          legendOffset: 95,
          truncateTickAt: 0
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: !isMobile && 'Count',
          legendPosition: 'middle',
          legendOffset: -40,
          truncateTickAt: 0
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              1.6
            ]
          ]
        }}
        role="application"
        ariaLabel="Insights by Class"
      />
    </WidgetCommon>
  )
}

export default withStyles(styles)(InsightsByClass);