import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

// import Icon from '@material-ui/core/Icon';
// import DashboardIcon from '@material-ui/icons/Dashboard';

// import CloudUploadIcon from '@material-ui/icons/CloudUpload';
// import Divider from '@material-ui/core/Divider';
// import CardContent from '@material-ui/core/CardContent';
import Card from '../Card/card';
import CardHeader from '../Card/cardHeader';
import CardFooter from '../Card/cardFooter';
import CardIcon from '../Card/cardIcon';
import CardBody from '../Card/cardBody';

import PolarBear from '../../animations/polarbear';

import GridContainer from '../Grid/gridContainer';
import GridItem from '../Grid/gridItem';

import ErrorIcon from '@material-ui/icons/Error';
// import InfoIcon from '@material-ui/icons/Info';
import StickyFooter from '../Footer/StickyFooter';


import { successColor, grayColor } from '../../style/style';

// import clsx from 'clsx';

const styles = (theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

  cardColor: {
    background: 'linear-gradient(60deg, #0093C5, #1195C9)',
    color: 'white',
  },
  toolbar: theme.mixins.toolbar,
  root: {},
  details: {
    display: 'flex',
  },
  avatar: {
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
  locationText: {
    paddingLeft: '15px',
  },
  buttonProperty: {
    position: 'absolute',
    top: '50%',
  },
  mapCard: {
    minHeight: '70vh',
  },
  uiProgess: {
    position: 'fixed',
    zIndex: '1000',
    height: '31px',
    width: '31px',
    left: '50%',
    top: '35%',
  },
  progess: {
    position: 'absolute',
  },
  successText: {
    color: successColor[0],
  },
  // ARROW SIZE ON CHART CARDS
  upArrowCardCategory: {
    width: '16px',
    height: '16px',
  },
  // 'last 24 hours' AND 'triggered 1 hour ago' INFO AT THE BOTTOM OF THE CARDS
  stats: {
    color: grayColor[0],
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '22px',
    '& svg': {
      top: '4px',
      width: '16px',
      height: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      top: '4px',
      fontSize: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
  },
  // HEADING TEXT ON TOP 4 CARDS 'Insights received' etc
  cardCategory: {
    color: grayColor[0],
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
  },
  // INFORMATION OR HEADINGS ON CARDS (USE THESE!!)
  cardTitle: {
    color: grayColor[2],
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: grayColor[1],
      fontWeight: '400',
      lineHeight: '1',
    },
  },
});

const PageUnderConstruction = (props) => {
  const { classes } = props;
  let content = <div></div>;

  content = (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      {/* FIRST DASHBOARD ROW */}

      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <ErrorIcon />
                </CardIcon>
               </CardHeader>
              <CardBody>
                  <center>
                      <PolarBear />
              <p className={classes.cardTitle}>Page is under construction!</p>
                <h3 className={classes.cardCategory}>Please check back later, cheers</h3>
                </center>
              </CardBody>
              <CardFooter stats>
              </CardFooter>
            </Card>
          </GridItem>
         
        </GridContainer>

        <StickyFooter />
      </div>
    </main>
  );

  return content;
};

export default withStyles(styles)(PageUnderConstruction);
