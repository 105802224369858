import React, { useEffect, useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Snackbar } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';


const styles = (theme) => ({});

const RequestSnackbar = (props) => {
  const { classes, name, baseSelector } = props;

  const selector = useSelector((state) => state[baseSelector].result);

  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    if (selector === "UPDATE_SUCCESS" && severity !== "error") {
      setSnackbarOpen(true);
      setMessage(`${name} updated.`);
      setSeverity('success');
    }
    if (selector === "UPDATE_FAILURE") {
      setSnackbarOpen(true);
      setMessage(`${name} failed to update.`);
      setSeverity('error');
    }
  }, [selector]);

  const handleClose = () => {
    setSeverity('');
    setSnackbarOpen(false);
  }

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={severity}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default withStyles(styles)(RequestSnackbar);
