import * as React from 'react';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { Tooltip, makeStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import CustomTableHeaderSelector from './CustomTableHeaderSelector';
import { HeaderDataTypes } from '../../../util/constants';


const useStyles = makeStyles({
  tooltip: {
    marginLeft: 0,
    marginBottom: -5,
    paddingBottom: 0,
    cursor: 'pointer'
  }
});


export default function CustomTableHeader(props) {
  const { order, orderBy, onRequestSort, headers, updateHeaders, onSelectAllClick, selectAll, checkBoxes } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow >
        {checkBoxes ?
          <TableCell>
            <Checkbox
              color="primary"
              onChange={onSelectAllClick}
              checked={selectAll}
            />
          </TableCell>
          : null}
        {headers.filter((header) => header.display).map((header) => (
          <TableCell
            key={header.id}
            align={header.type === HeaderDataTypes.ELEMENT ? "center" : 'left'}
            sortDirection={orderBy === header.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === header.id}
              direction={orderBy === header.id ? order : 'asc'}
              onClick={createSortHandler(header.id)}
            >
              {header.label}
              {orderBy === header.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
            {
              header.info && (
                <Tooltip
                  title={header.info}
                  className={classes.tooltip}
                >
                  <InfoIcon />
                </Tooltip>
              )
            }
          </TableCell>
        ))}
        <div style={{ position: 'sticky', top: 0, right: 0, zIndex: 100, backgroundColor: 'transparent' }}>
          <CustomTableHeaderSelector headers={headers} updateHeaders={updateHeaders} />
        </div>
      </TableRow>
    </TableHead>
  );
}