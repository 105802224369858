import React, { useCallback } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { useResizeDetector } from 'react-resize-detector';
import StickyFooter from '../../Footer/StickyFooter';
import CenteredCircularProgress from '../CenteredCircularProgress';


const styles = (theme) => ({
  content: { 
    padding: theme.spacing(3),
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  loadingSpinner: { 
    minHeight: "80vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
});


const HomePageWrapper = (props) => {
  const { classes, children, loading } = props;

  const onResize = useCallback(() => {}, []);

  const { ref } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'debounce',
    refreshRate: 100,
    onResize,
  });

  return (
    <main ref={ref} className={classes.content}>
      <div>
        {loading ? <div className={classes.loadingSpinner}>
          <CenteredCircularProgress size={150}/>
        </div> : children}
      </div>
      <br/>
      <StickyFooter />
    </main>
  );;
};

export default withStyles(styles)(HomePageWrapper);
