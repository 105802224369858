import axios from "axios";
import { Action } from "./actionsShared";
import { server } from "./backend-url";

export const FETCH_USERS_PREFIX = "FETCH_USERS";
export const FETCH_DEVICES_PREFIX = "FETCH_DEVICES";
export const FETCH_ALGS_PREFIX = "FETCH_ALGS";
export const FETCH_ALG_RELEASES_PREFIX = "FETCH_ALG_RELEASES";

export const fetchUsers = () => {
  const url = "/api/internal/users";
  return Action.GET(FETCH_USERS_PREFIX, url, true);
};

export const fetchDevices = () => {
  const url = "/api/internal/devices";
  return Action.GET(FETCH_DEVICES_PREFIX, url, true);
};

export const fetchAlgs = () => {
  const url = "/api/internal/algs";
  return Action.GET(FETCH_ALGS_PREFIX, url, true);
};

export const fetchAlgReleases = () => {
  const url = "/api/modelRelease";
  return Action.GET(FETCH_ALG_RELEASES_PREFIX, url, true);
};


export const getUserSpecificDevices = async (userId) => {
  const authToken = localStorage.getItem('AuthToken');
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  try {
    const response = await axios.get(`${server}/api/internal/users/${userId}/devices`);
    return response.data.message;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export const getUserSpecificAlgs = async (userId) => {
  const authToken = localStorage.getItem('AuthToken');
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  try {
    const response = await axios.get(`${server}/api/internal/users/${userId}/algs`);
    return response.data.message;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export const getUserSpecificModelReleases = async (userId) => {
  const authToken = localStorage.getItem('AuthToken');
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  try {
    const response = await axios.get(`${server}/api/internal/users/${userId}/modelRelease`);
    return response.data.message;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export const assignUserAlgs = async (userId, algs) => {
  const modifiedAlgs = algs.map((alg) => parseInt(alg));
  const authToken = localStorage.getItem('AuthToken');
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  try {
    await axios.put(`${server}/api/internal/users/${userId}/algs`, {
      algIds: modifiedAlgs
    });
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}


export const assignUserAlgReleases = async (userId, releaseIds) => {
  const modifiedReleases = releaseIds.map((releaseId) => parseInt(releaseId));
  const authToken = localStorage.getItem('AuthToken');
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  try {
    await axios.put(`${server}/api/internal/users/${userId}/modelRelease`, {
      algReleaseIds: modifiedReleases
    });
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export const assignUserDevices = async (userId, devices) => {
  const modifiedDevices = devices.map((device) => parseInt(device));
  const authToken = localStorage.getItem('AuthToken');
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  try {
    await axios.put(`${server}/api/internal/users/${userId}/devices`, {
      deviceIds: modifiedDevices
    });
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export const updateAlgMetadata = async (algId, bucket, path) => {
  const authToken = localStorage.getItem('AuthToken');
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  try {
    await axios.put(`${server}/api/internal/algs/${algId}`, {
      bucket,
      path
    });
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export const createRelease = async (releaseObject) => {
  const authToken = localStorage.getItem('AuthToken');
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  try {
    await axios.post(`${server}/api/modelRelease`, releaseObject);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export const clearDevice = async (deviceId) => {
  const authToken = localStorage.getItem('AuthToken');
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  try {
    await axios.delete(`${server}/api/internal/devices/${deviceId}`);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}