import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImageCard from './ProductCard';
import places from './ProductCardInfo';
import useWindowPosition from '../../hook/useWindowPosition';
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '80vh',
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
}));
export default function () {
  const classes = useStyles();
  const checked = useWindowPosition('header');
  return (
    <section id="infocards"data-scroll-id="infocards">
    <div className={classes.root} id="info-card">
      <ImageCard place={places[0]} checked={checked} />
      <ImageCard place={places[1]} checked={checked} />
    </div>
    </section>
  );
}