import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteGroup from '../Modal/deleteGroup';
import EditGroup from '../Modal/editGroupModal';
import DeviceDrawer from '../Drawer/devices/deviceDrawer';
import withStyles from '@material-ui/core/styles/withStyles';
// style imports
import {
  warningColor,
  primaryColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  defaultFont,
} from '../../style/style';
import moment from 'moment';
// Import Typedefs for convenience
import '../../util/typedefs';

import { createTheme } from '@mui/material';
import GeneralSwipeableView from '../Common/ContentContainers/GeneralSwipeableView';

const theme_bp = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 1050,
      lg: 1200,
      xl: 1536,
    },
  },
});

const styles = (theme) => ({
  warningTableHeader: {
    color: warningColor[0],
  },
  primaryTableHeader: {
    color: primaryColor[0],
  },
  dangerTableHeader: {
    color: dangerColor[0],
  },
  successTableHeader: {
    color: successColor[0],
  },
  infoTableHeader: {
    color: infoColor[0],
  },
  roseTableHeader: {
    color: roseColor[0],
  },
  grayTableHeader: {
    color: grayColor[0],
  },
  table: {
    marginBottom: '0',
    width: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse',
    objectFit: 'contain',
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    '&, &$tableCell': {
      fontSize: '1em',
    },
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    fontSize: '0.9rem',
  },
  tableHeadRow: {
    height: '56px',
    color: 'inherit',
    display: 'table-row',
    outline: 'none',
    verticalAlign: 'middle',
  },
  tableBodyRow: {
    height: '48px',
    color: 'inherit',
    display: 'table-row',
    outline: 'none',
    verticalAlign: 'middle',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    '&:focus': {
      color: 'white',
      backgroundColor: '#0A94C7',
    },
    '&:hover': {
      color: 'white',
      backgroundColor: '#0A94C7',
    },
  },
  tableTopLevelRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  bigTable: {
    [theme_bp.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  smallTable: {
    [theme_bp.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  noMargin: { '> div': { margin: 0 } },
  groupView: {
    width: '100%',
    rowGap: '16px',
    columnGap: '24px',
    padding: '16px',
    display: 'flex',
    margin: 'auto',
    '& div': {
      margin: 0,
    },
    [theme_bp.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    [theme_bp.breakpoints.up('sm')]: {
      flexDirection: 'row',
      // justifyContent: 'space-between',
    },
  },
  nullResult: {
    align: 'right',
    fontSize: 28,
    lineHeight: '150%',
  },
});

const CollapsibleRow = withStyles(styles)((props) => {
  const { group, classes } = props;

  return (
    <React.Fragment>
      <TableRow className={classes.tableTopLevelRow}>
        {/* Group Name */}
        <TableCell>
          {group.group_name}
        </TableCell>
        {/* Group Description */}
        <TableCell>
          {group.deployment_description}
        </TableCell>
        {/* Group Size */}
        <TableCell>
          {
            group.devices.length
          }
        </TableCell>
        {/* Group Devices */}
        <TableCell>
          <DeviceDrawer group={group}></DeviceDrawer>
        </TableCell>
        {/* Edit Group Button and Popup*/}
        <TableCell>
          <EditGroup group={group} />
        </TableCell>
        {/* Delete Group Button and Popup*/}
        <TableCell>
          <DeleteGroup group={group} />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
});

const GroupView = withStyles(styles)((props) => {
  const { classes, group } = props;

  return (
    <div className={classes.groupView}>
      <div
        className={classes.noMargin}
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          rowGap: 16,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {group.group_name}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: '8px',
              alignItems: 'center',
            }}
          >
            <EditGroup group={group} />
            <DeleteGroup group={group} />
          </div>
        </div>

        <p>
          {' '}
          {
            group.devices.length
          }{' '}
          devices{' '}
        </p>

        <DeviceDrawer group={group}></DeviceDrawer>
      </div>
      <div style={{ flex: 1 }}>
        <p>
          <b>Group ID:</b> {group.group_id}
        </p>

        <p>
          <b>Group Name:</b> {group.group_name}
        </p>

        <p>
          <b>Group Notes:</b> {group.deployment_description}
        </p>

        <p>
          <b>Created on ({moment.tz(moment.tz.guess()).format('z')}):</b>{' '}
          {group.created_on &&
            moment(group.created_on)
              .tz(moment.tz.guess())
              .format('MMM Do YYYY, h:mm a')}
        </p>

        <p>
          <b>Last updated ({moment.tz(moment.tz.guess()).format('z')}):</b>{' '}
          {group.updated_on &&
            moment(group.updated_on)
              .tz(moment.tz.guess())
              .format('MMM Do YYYY, h:mm a')}
        </p>
      </div>
    </div>
  );
});

const GroupTable = (props) => {
  const { classes, tableHeaderColor } = props;
  /** @type {GroupData[]} */
  const groupData = useSelector((state) => state.group.groupData);

  function isObjectEmpty(value) {
    for (let prop in value) {
      if (value.hasOwnProperty(prop)) return false;
    }
    return true;
  }

  function add_group_rows () {
    if (isObjectEmpty(groupData)) {
      return (<TableRow>
        <TableCell/>
        <TableCell/>
        <TableCell className={classes.nullResult}> No groups available, please make a new group </TableCell>
        </TableRow>)
    } else {
      return groupData.map((group) => (
        <CollapsibleRow key={`group-${group.group_id}`} group={group}/>
      ))
    }
  }

  function add_group_pages () {
    if (isObjectEmpty(groupData)) {
      return [(<div className={classes.nullResult}> No groups available, please make a new group </div>)] 
    } else {
        return groupData.map((group) => <GroupView group={group}/>)
      }
  }

  return (
    <>
      <div className={`${classes.setWidth} ${classes.bigTable}`}>
        <Table className={classes.table} align="center">
          <TableHead className={classes[tableHeaderColor + 'TableHeader']}>
            <TableRow className={classes.tableHeadRow}>
              <TableCell
                className={classes.tableCell + ' ' + classes.tableHeadCell}
              >
                Group Name
              </TableCell>
              <TableCell
                className={classes.tableCell + ' ' + classes.tableHeadCell}
              >
                Group Description
              </TableCell>
              <TableCell
                className={classes.tableCell + ' ' + classes.tableHeadCell}
              >
                Size
              </TableCell>
              <TableCell
                className={classes.tableCell + ' ' + classes.tableHeadCell}
              >
                Add Device to Group
              </TableCell>
              <TableCell
                className={classes.tableCell + ' ' + classes.tableHeadCell}
              >
                Edit
              </TableCell>
              <TableCell
                className={classes.tableCell + ' ' + classes.tableHeadCell}
              >
                Delete
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {add_group_rows()}
          </TableBody>
        </Table>
      </div>
      <div className={classes.smallTable}>
        <GeneralSwipeableView content={add_group_pages()} />
      </div>
    </>
  );
};

export default withStyles(styles)(GroupTable);
