import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { createTheme } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import CustomTableContainer from '../Common/Table/CustomTableContainer';
import { HeaderDataTypes } from '../../util/constants';
import UpdateAlgConfigModal from './modals/updateAlgConfig';
import { fetchAlgs } from '../../actions/internalActions';

const theme_bp = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 680,
      md: 850,
      lg: 1200,
      xl: 1536,
    },
  },
});

const useStyles = makeStyles({

});

export default function AlgMetadata(props) {
  const [processedAlgs, setProcessedAlgs] = useState([]);
  const algsData = useSelector((state) => state.internal.algs);
  const [modalData, setModalData] = useState({ open: false });
  const dispatch = useDispatch();

  const handleClick = (event, row) => {
    const newModalData = {
      open: true,
      algId: row.alg_id,
      bucket: row.alg_bucket,
      path: row.alg_path
    };
    setModalData(newModalData);
  }

  const handleClose = () => {
    setModalData({ open: false });
    dispatch(fetchAlgs())
  }

  const defaultHeaders = [
    {
      id: 'alg_id',
      type: HeaderDataTypes.NUMBER,
      label: 'Alg ID',
      display: true
    },
    {
      id: 'alg_name',
      type: HeaderDataTypes.STRING,
      label: 'Name',
      display: true
    },
    {
      id: 'framework',
      type: HeaderDataTypes.STRING,
      label: 'Framework',
      display: true
    },
    {
      id: 'date_created',
      type: HeaderDataTypes.DATE,
      label: 'Date Created',
      display: true,
    },
    {
      id: 'alg_bucket',
      type: HeaderDataTypes.STRING,
      label: 'Alg Bucket',
      display: true
    },
    {
      id: 'alg_path',
      type: HeaderDataTypes.STRING,
      label: 'Alg Path',
      display: true
    },
    {
      id: 'md5_checksum',
      type: HeaderDataTypes.STRING,
      label: 'MD5 Checksum',
      display: true
    }
  ];

  useEffect(() => {
    if (algsData) {
      setProcessedAlgs(
        algsData.map((alg, index) => {
          return {
            id: index,
            alg_id: alg.alg_id,
            alg_name: alg.alg_name,
            framework: alg.framework,
            date_created: alg.date_created,
            alg_bucket: alg.alg_bucket,
            alg_path: alg.alg_path,
            md5_checksum: alg.md5_checksum,
          }
        })
      );
    }

  }, [algsData]);

  return (
    <>
      <CustomTableContainer
        defaultOrder="desc"
        defaultOrderHeader="alg_id"
        headers={defaultHeaders}
        rowData={processedAlgs}
        paginationOptions={[5, 10, 25, 50, 100]}
        defaultPaginationOption={25}
        clickAction={handleClick}
      />
      {
        modalData && modalData.algId &&
        (<UpdateAlgConfigModal
          algId={modalData.algId}
          bucket={modalData.bucket}
          path={modalData.path}
          open={modalData.open}
          handleClose={handleClose}
        />)
      }
    </>
  );
}
