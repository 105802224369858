import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  withStyles,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import RouterIcon from '@material-ui/icons/SettingsRemote';
import MailIcon from '@material-ui/icons/Mail';
import WifiIcon from '@material-ui/icons/Wifi';

import ItemList from '../../Common/ItemList';
import IntegrationItem from '../Integration/IntegrationItem';
import { IntegrationPlatforms } from '../../../util/constants';
import FeatureItem from './FeatureItem';

const styles = (theme) => ({
  integrationInfoCard: {
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '20px',
    marginTop: theme.spacing(2),
    padding: 0,
  },
  cardContent: {
    padding: "0!important",
    margin: 0
  },
});

const Features = (props) => {
  const { classes } = props;

  const features = useSelector((state) => state.feature.features);

  const [items, setItems] = useState([]);
  
  useEffect(() => {
    setItems(features.map((feature) => {
      return (
      <FeatureItem 
        featureId={feature.id}
        title={feature.name}
        enabled={feature.enabled}
        instructions={feature.instructions}
      />
      );
    }));
  }, [features]);

  return (
    <React.Fragment>
      <Card className={classes.integrationInfoCard}>
        <CardContent className={classes.cardContent}>
          <ItemList items={items} />
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default withStyles(styles)(Features);
