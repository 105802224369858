import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HardwareCard from './HardwareCard';
import info from './HardwareCardInfo';
import useWindowPosition from '../../hook/useWindowPosition';
import HardwareImage from '../../../images/Hardware.png';
import { useMediaQuery } from 'react-responsive'


// Styling
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  image: {
    borderRadius: 20,
    width: '40vw',
  },
  mobileImage: {
    borderRadius: 20,
    width: '80vw',
  },
}));

// Function
export default function () {
  const classes = useStyles();
  const checked = useWindowPosition('header');

  const isDesktop = useMediaQuery({ query: '(min-width: 1224px)'})
  const isMobile = useMediaQuery({ query: '(max-width: 1224px)'})
  

  return (
    <section id="hardwarecard" data-scroll-id="hardwarecard">
    <div className={classes.root} id="hardware-card">
      <HardwareCard place={info[0]} checked={checked} />

      {isDesktop && <img src={HardwareImage} className={classes.image} alt="" />}
      {isMobile && <img src={HardwareImage} className={classes.mobileImage} alt="" />}
    </div>
    </section>
  );
}