import React, { useState, useEffect } from 'react';

import { ResponsiveBar } from '@nivo/bar'
import WidgetCommon from '../Common/widgetCommon'
import { withStyles } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';

const styles = (theme) => ({});

const InsightRecievedHourByDay = (props) => {
  const { isLoading, insights } = props;
  const [graphData, setGraphData] = useState([]);
  const [graphType, setGraphType] = useState('linear');

  const isMobile = useMediaQuery({ query: '(max-width: 625px)' });


  useEffect(() => {
    if (insights) {
      const dataMap = {};
      for (let i = 0; i < 24; i++) {
        dataMap[i] = {
          hour: i,
          count: 0
        };
      }
      for (const insight of insights) {
        if (insight.time_stamp) {
          const hourOfDay = (new Date(insight.time_stamp)).getHours();
          dataMap[hourOfDay].count += 1;
        }
      }
      setGraphData(Object.values(dataMap));
    }
  }, [insights])

  return (
    <WidgetCommon
      title="Insights Recieved by Hour of Day"
      isLoading={isLoading}
      hasNoData={graphData.filter((data) => data.count !== 0).length === 0}
      hasTooMuchData={false} tooMuchDataString=""
      info="Data is based on raw insights. Does not change with aggregated insights feature."
      graphSwitch={true} setGraphType={setGraphType} graphType={graphType}
    >
      <ResponsiveBar
        data={graphData}
        keys={["count"]}
        indexBy="hour"
        margin={{ top: isMobile ? 10 : 50, right: 10, bottom: 60, left: isMobile ? 40 : 60 }}
        padding={0.3}
        valueScale={{ type: graphType }}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'paired' }}
        borderColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              1.6
            ]
          ]
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 90,
          legend: !isMobile && 'Hour of Day (UTC)',
          legendPosition: 'middle',
          legendOffset: 50,
          truncateTickAt: 0
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: !isMobile && 'Count',
          legendPosition: 'middle',
          legendOffset: -40,
          truncateTickAt: 0
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              1.6
            ]
          ]
        }}
        role="application"
        ariaLabel="Insight Recieved by Hour of Day"
      />
    </WidgetCommon>
  )
}

export default withStyles(styles)(InsightRecievedHourByDay);