import axios from 'axios';
import { Action } from "./actionsShared";
import { server } from '../actions/backend-url';

export const FETCH_MODEL_CLASSES_PREFIX = "FETCH_MODEL_CLASSES";

export const UPDATE_MODEL_BEGIN = 'UPDATE_MODEL_BEGIN';
export const UPDATE_MODEL_SUCCESS = 'UPDATE_MODEL_SUCCESS';
export const UPDATE_MODEL_FAILURE = 'UPDATE_MODEL_FAILURE';

export const GET_IMPROVE_MODEL_BEGIN = 'GET_IMPROVE_MODEL_BEGIN';
export const GET_IMPROVE_MODEL_SUCCESS = 'GET_IMPROVE_MODEL_SUCCESS';
export const GET_IMPROVE_MODEL_FAILURE = 'GET_IMPROVE_MODEL_FAILURE';


//Model

//Updating a Model
export const updateModelBegin = () => {
  return {
    type: UPDATE_MODEL_BEGIN,
  };
};

export const updateModelSuccess = (model) => {
  return {
    type: UPDATE_MODEL_SUCCESS,
    payload: { model },
  };
};

export const updateModelFailure = (error) => {
  return {
    type: UPDATE_MODEL_FAILURE,
    payload: { error },
  };
};

export function updateModel(model) {
  return (dispatch) => {
    if (model) {
      dispatch(updateModelBegin());
      const authToken = localStorage.getItem('AuthToken');
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      return axios
        .post(`${server}/api/updateModel`, {
          alg_name: model.alg_name.toString(),
          alg_description: model.alg_description.toString(),
        })
        .then((response) => {
          dispatch(updateModelSuccess(model));
          return response;
        })
        .catch((error) => dispatch(updateModelFailure(error)));
    } else {
      return;
    }
  };
}

export const getImproveModelBegin = () => {
  return {
    type: GET_IMPROVE_MODEL_BEGIN,
  };
};

export const getImproveModelSuccess = (images) => {
  return {
    type: GET_IMPROVE_MODEL_SUCCESS,
    payload: { images },
  };
};

export const getImproveModelFailure = (error) => {
  return {
    type: GET_IMPROVE_MODEL_FAILURE,
    payload: { error },
  };
};

export function getImproveModelImages(alg_id) {
  return (dispatch) => {
    dispatch(getImproveModelBegin());
    // console.log(model.alg_name.toString())
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    return axios
      .get(`${server}/api/getImproveModelImages/${alg_id}`)
      .then((response) => {
        console.log(response);
        dispatch(getImproveModelSuccess(response.data));
        return response;
      })
      .catch((error) => dispatch(getImproveModelFailure(error)));
  };
}

export const fetchModelClasses = (algId) => {
  const url = `/api/models/${algId}/classes`;
  return Action.GET(FETCH_MODEL_CLASSES_PREFIX, url, true);
};
