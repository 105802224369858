import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';


const styles = (theme) => ({});

const ErrorSnackbar = (props) => {
  const { classes, message, snackbarOpen, setSnackbarOpen } = props;

  const handleClose = () => {
    setSnackbarOpen(false);
  }

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity="error"
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default withStyles(styles)(ErrorSnackbar);
