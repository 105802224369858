import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles((theme) => ({
pricingsect: {
  height: '80vh',
  background: 'white',
},
root: {
  background: 'white',
  height: '100vh',
  paddingTop: '30px',
  paddingBottom: '60px',
  marginBottom: '-80px',
},
    cardRoot: {
        width: '90vw',
        background: 'white',
        marginTop: '20px',
        marginBottom: '20px',

    }, 
    calculator: {
        display: 'flex',
        marginLeft: '10vw',
        marginTop: '5vh',
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
        }, 
    },
    spacing: {
        marginTop: '10vh',
        marginLeft: '10vw',
    },
    header: {
      fontFamily: 'Nunito',
      fontWeight: 'bold',
      fontSize: '2rem',
      color: '#000',
      marginRight: '30px',
    },
    title: {
      fontFamily: 'Nunito',
      fontWeight: 'regular',
      fontSize: '1.5rem',
      color: '#000',
      marginRight: '30px',
    },
    price: {
      fontFamily: 'Nunito',
      fontWeight: 'regular',
      fontSize: '2rem',
      color: '#000',
      marginRight: '30px',
    },
    liststyle: {
      fontFamily: "Nunito",
      fontSize: "1rem",
      margin: '10px 10px',
    },
    list: {
      display: "flex",
      marginLeft: '8vw',

    },
    sections: {
      textDecoration: 'none',
      fontWeight: 'bold',
      color: '#B6B6B6',
      '&:hover': {
        backgroundColor: '#fff',
        color: '#000',
    },
    '&:clicked': {
      backgroundColor: '#fff',
      color: '#000',
  },
    },
}));


export default function () {
  const classes = useStyles();

const [state, setState] = useState({
    ImageNumber: '',
    SpeciesNumber: '',
    SeparateFolders: '',
    checkedA: false,
    checkedB: false,
    LabelImagesPrice: '',
    AnimalNumber: '',
    AnimalNumberPrice: '',
    DatasetImageNumber: '',
    DatasetAnimalNumber: '',
    DatasetModelPrice: '',
  });

  const [show, setShow] = useState({
    LabelImages: true,
    CustomModel: false,
    DatasetModel: false,
  })

  const LabelImagesonClick = (event) => {
    setShow({
      ...state,
      LabelImages: true,
      CustomModel: false,
      Dataset: false,
    })
  }

  const CustomModelonClick = (event) => {
    setShow({
      ...state,
      LabelImages: false,
      CustomModel: true,
      DatasetModel: false,
    })
  }

  const DatasetModelonClick = (event) => {
    setShow({
      ...state,
      LabelImages: false,
      CustomModel: false,
      DatasetModel: true,
    })
  }

  const handleSwitchChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <section className={classes.pricingsect} id="pricingcalc" data-scroll-id="pricingcalc" backgroundColor="white">
<div className={classes.root}>
<div className={classes.calculator}>
    <Typography
              gutterBottom
              variant="h5"
              component="h1"
              className={classes.header}
            >
              Pricing Calculator
              </Typography>
</div>
<div>
<ul className={classes.list} style={{ listStyleType: "none" }}>
              <li className={classes.liststyle}>
              <a 
                style = {show.LabelImages ? {color: 'black'} : {color: 'grey'}} 
                className={classes.sections} 
                href="#labelimages" 
                onClick={LabelImagesonClick}
              >
                Label Images
              </a>
              </li>
              <li className={classes.liststyle}>
                <a 
                style={show.CustomModel ? {color: 'black'} : {color: 'grey'}}
                className={classes.sections} 
                href="#customModel" 
                onClick={CustomModelonClick}
                >
                  Custom Model
                </a>
              </li>
              <li className={classes.liststyle}>
                <a 
                style={show.DatasetModel ? {color: 'black'} : {color: 'grey'}}
                className={classes.sections} 
                href="#datasetModel" 
                onClick={DatasetModelonClick}
                >
                  Specialized Model
                </a>
              </li>
            </ul>
</div>
{show.LabelImages && <div className={classes.calculator} name="CustomModel">
    <Typography
              gutterBottom
              variant="h6"
              component="h1"
              className={classes.title}
            >
              Number of Images
              </Typography>
        <TextField label="" name="ImageNumber" type="text" value={state.ImageNumber} variant="outlined" onChange={(e) => handleChange(e)} />
    </div>} 
    {show.LabelImages && <div className={classes.calculator} name="CustomModel">
    <Typography
              gutterBottom
              variant="h6"
              component="h1"
              className={classes.title}
            >
              Human verified?
              </Typography>
    <Switch checked={state.checkedA} color="primary" onChange={handleSwitchChange} name="checkedA" />
</div>}
{show.LabelImages && <div className={classes.calculator} name="CustomModel">
    <Typography
              gutterBottom
              variant="h6"
              component="h1"
              className={classes.title}
            >
              Number of Different Species in Dataset(s)
              </Typography>
        <TextField label="1 minimum" name="SpeciesNumber" type="text" value={state.SpeciesNumber} variant="outlined" onChange={(e) => handleChange(e)} />
    </div>} 
  {show.LabelImages && <div className={classes.calculator} name="SeparateFolders">
  <Typography
            gutterBottom
            variant="h6"
            component="h1"
            className={classes.title}
          >
           Each Species is NOT Separated into their own Folders?
            </Typography>
  <Switch checked={state.checkedB} color="primary" onChange={handleSwitchChange} name="checkedB" />
</div>}
{show.LabelImages && <div className={classes.calculator} name="CustomModel">
    <Typography
              gutterBottom
              variant="h6"
              component="h1"
              className={classes.price}
            >
              Estimated Price
              </Typography>
              <TextField label="$ (USD)" name="LabelImagesPrice" type="text" value={state.ImageNumber*0.002 + state.ImageNumber*state.checkedA*0.002*2 + state.SpeciesNumber*10 + state.ImageNumber*state.checkedB*3*0.002} disabled variant="outlined" onChange={(e) => handleChange(e)} />
    </div>}

{show.CustomModel && <div className={classes.calculator} name="CustomModel">
    <Typography
              gutterBottom
              variant="h6"
              component="h1"
              className={classes.title}
            >
              Number of Animals in Model
              </Typography>
        <TextField label="1 minimum" name="AnimalNumber" type="text" value={state.AnimalNumber} variant="outlined" onChange={(e) => handleChange(e)} />
    </div>}

{show.CustomModel && <div className={classes.calculator} name="CustomModel">
    <Typography
              gutterBottom
              variant="h6"
              component="h1"
              className={classes.price}
            >
              Estimated Price
              </Typography>
              <TextField label="$ (USD)" name="AnimalNumberPrice" type="text" value={Math.min( 500, 200 + state.AnimalNumber*50)} disabled variant="outlined" onChange={(e) => handleChange(e)} />
    </div>}

{show.DatasetModel && <div className={classes.calculator} name="DatasetModel">

    <Typography
              gutterBottom
              variant="h6"
              component="h1"
              className={classes.title}
            >
              Number of Images in Training Dataset
              </Typography>
        <TextField label="2000 minimum" name="DatasetImageNumber" type="text" value={state.DatasetImageNumber} variant="outlined" onChange={(e) => handleChange(e)} />
    </div>}

{show.DatasetModel && <div className={classes.calculator} name="DatasetModel">
<Typography
          gutterBottom
          variant="h6"
          component="h1"
          className={classes.title}
        >
          Number of Animals in Model
          </Typography>
    <TextField label="1 minimum" name="DatasetAnimalNumber" type="text" value={state.DatasetAnimalNumber} variant="outlined" onChange={(e) => handleChange(e)} />
</div>}

{show.DatasetModel && <div className={classes.calculator} name="DatasetModel">
    <Typography
              gutterBottom
              variant="h6"
              component="h1"
              className={classes.price}
            >
              Estimated Price
              </Typography>
      <TextField label="$ (USD)" name="DatasetModelPrice" type="text"  disabled value={Math.min( 5000, 500 + state.DatasetImageNumber*0.05 * state.DatasetAnimalNumber*2)} variant="outlined" onChange={(e) => handleChange(e)} />
    </div>}
    </div>
    </section>
  );
}