import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { createTheme } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import CustomTableContainer from '../Common/Table/CustomTableContainer';
import { HeaderDataTypes } from '../../util/constants';
import UpdateAlgConfigModal from './modals/updateAlgConfig';
import { fetchAlgs } from '../../actions/internalActions';
import ReleaseComponentsModal from './modals/releaseComponents';
import { Button } from '@material-ui/core';
import AddReleaseModal from './modals/addRelease';

const theme_bp = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 680,
      md: 850,
      lg: 1200,
      xl: 1536,
    },
  },
});

const useStyles = makeStyles({
  addReleaseButton: {
    marginBottom: "10px"
  }
});

export default function AlgReleases(props) {
  const [processedReleases, setProcessedReleases] = useState([]);
  const releaseData = useSelector((state) => state.internal.algReleases);
  const algData = useSelector((state) => state.internal.algs);
  const [modalData, setModalData] = useState({ open: false });
  const [addRelease, setAddRelease] = useState(false);
  const classes = useStyles();

  const handleClick = (event, row) => {
    const newModalData = {
      open: true,
      components: row.release_data,
      name: row.release_name
    };
    setModalData(newModalData);
  }

  const handleClose = () => {
    setModalData({ open: false });
  }

  const defaultHeaders = [
    {
      id: 'release_id',
      type: HeaderDataTypes.NUMBER,
      label: 'Release ID',
      display: true
    },
    {
      id: 'release_name',
      type: HeaderDataTypes.STRING,
      label: 'Name',
      display: true
    },
    {
      id: 'release_description',
      type: HeaderDataTypes.STRING,
      label: 'Framework',
      display: true
    },
    {
      id: 'model_count',
      type: HeaderDataTypes.NUMBER,
      label: 'Model Count',
      display: true,
    },
    {
      id: 'model_ids',
      type: HeaderDataTypes.STRING,
      label: 'Model IDs',
      display: true
    }
  ];

  useEffect(() => {
    if (releaseData) {
      setProcessedReleases(
        releaseData.map((release, index) => {
          return {
            id: index,
            release_id: parseInt(release.id),
            release_name: release.name,
            release_description: release.description,
            model_count: release.components.length,
            model_ids: release.components.map((a) => a.algMetadataId).join(", "),
            release_data: release.components
          }
        })
      );
    }

  }, [releaseData]);

  return (
    <>
      <Button onClick={() => setAddRelease(!addRelease)} variant="outlined" className={classes.addReleaseButton}>
        <span>
          Create Release
        </span>
      </Button>
      <CustomTableContainer
        defaultOrder="desc"
        defaultOrderHeader="release_id"
        headers={defaultHeaders}
        rowData={processedReleases}
        paginationOptions={[5, 10, 25, 50, 100]}
        defaultPaginationOption={25}
        clickAction={handleClick}
      />
      {
        modalData && modalData.components && modalData.name &&
        (<ReleaseComponentsModal
          components={modalData.components}
          name={modalData.name}
          open={modalData.open}
          handleClose={handleClose}
        />)
      }
      <AddReleaseModal
        open={addRelease}
        algs={algData ? algData : []}
        handleClose={() => setAddRelease(false)}
      />
    </>
  );
}
