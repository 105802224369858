// React Imports
import React from 'react';

import Card from '../../Card/card';
import CardHeader from '../../Card/cardHeader';
import CardFooter from '../../Card/cardFooter';
import InfoIcon from '@material-ui/icons/Info';
import CardBody from '../../Card/cardBody';
import CenteredCircularProgress from '../../Common/CenteredCircularProgress';

import withStyles from '@material-ui/core/styles/withStyles';
import { grayColor, whiteColor } from '../../../style/style';
import { Tooltip } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

const styles = (theme) => ({
  card: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  stats: {
    color: grayColor[0],
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '22px',
    '& svg': {
      top: '4px',
      width: '16px',
      height: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      top: '4px',
      fontSize: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
  },
  cardTitle: {
    // color: whiteColor,
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '530',
    fontSize: '15px',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    textTransform: 'uppercase',
    '& small': {
      color: grayColor[1],
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  cardBody: {
    overflow: 'auto',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  tooltip: {
    marginLeft: 10,
    cursor: 'pointer'
  },
  flex: {
    display: "flex",
    justifyContent: 'space-between', // Aligns items to each end of the container
    alignItems: "center"
  }
});


const WidgetCommon = (props) => {
  const { classes, title, children, footer, isLoading, hasNoData, hasTooMuchData, tooMuchDataString, dynamicHeight, hideIfNoData, info, height, maxHeight, graphSwitch, setGraphType, graphType } = props;

  const errorContent = () => {
    let text = "";
    if (hasNoData) {
      text = "No Data";
    } else if (hasTooMuchData) {
      text = tooMuchDataString;
    }

    return (<div className={classes.center}>
      <p>{text}</p>
    </div>);
  };

  const handleChange = (event) => {
    setGraphType(event.target.value);
  }

  return (
    <Card className={classes.card} style={hideIfNoData && hasNoData ? { display: "none" } : {}}>
      <CardHeader>
        <div className={classes.flex}>
          <h4 className={classes.cardTitle} style={{ color: 'black' }}>
            {title}
          </h4>
          {
            graphSwitch ?
              <ToggleButtonGroup
                color="primary"
                value={graphType}
                exclusive
                onChange={handleChange}
                aria-label="Graph Type"
                size="small"
              >
                <ToggleButton value="linear">Linear</ToggleButton>
                <ToggleButton value="symlog">Log</ToggleButton>
              </ToggleButtonGroup>
              : null
        }
        </div>
      </CardHeader>
      <CardBody className={classes.cardBody} style={dynamicHeight ? {} : height && maxHeight ? { maxHeight: maxHeight, height: height, } : { maxHeight: '400px', height: '60vh', }}>
        {isLoading ?
          <div className={classes.center}>
            <CenteredCircularProgress size={100} />
          </div> :
          hasNoData || hasTooMuchData ? errorContent() :
            children}
      </CardBody>
      <CardFooter stats>
        <div className={classes.stats}>
          {footer}
        </div>
        {
          info && (
            <Tooltip
              title={info}
              className={classes.tooltip}
              color="primary"
            >
              <InfoIcon />
            </Tooltip>
          )
        }
      </CardFooter>
    </Card>
  );
};

export default withStyles(styles)(WidgetCommon);


