import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { createTheme } from '@mui/material';

import { useSelector } from 'react-redux';
import { getDisplayName } from '../../util/deviceHelpers';
import CustomTableContainer from '../Common/Table/CustomTableContainer';
import EditDeviceModal from '../Modal/editDeviceModal';
import DeviceView from './deviceView';
import InsightMapDialog from '../UserInsights/InsightMapDialog';
import { DEVICE_UPDATE_STATUS, HeaderDataTypes } from '../../util/constants';
import { getFormattedTimeString } from '../../util/time';

import PendingIcon from '@material-ui/icons/CachedOutlined';
import PassedIcon from '@material-ui/icons/CheckCircleOutline';
import FailedIcon from '@material-ui/icons/HighlightOffOutlined';
import NoActionIcon from '@material-ui/icons/RemoveCircleOutline';
import { Tooltip } from '@material-ui/core';

const theme_bp = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 680,
      md: 850,
      lg: 1200,
      xl: 1536,
    },
  },
});

const useStyles = makeStyles({

});

const defaultHeaders = [
  {
    id: 'device_update_status',
    type: HeaderDataTypes.ELEMENT,
    label: 'Update Status',
    display: true
  },
  {
    id: 'device_id',
    type: HeaderDataTypes.NUMBER,
    label: 'Device ID',
    display: true
  },
  {
    id: 'display_name',
    type: HeaderDataTypes.STRING,
    label: 'Device Name',
    display: true
  },
  {
    id: 'on_device_name',
    type: HeaderDataTypes.STRING,
    label: 'On Device Name',
    display: false,
    info: "Name associated with the device locally. Used to ssh into the device."
  },
  {
    id: 'location_name',
    type: HeaderDataTypes.STRING,
    label: 'Location Name',
    display: false
  },
  {
    id: 'location_description',
    type: HeaderDataTypes.STRING,
    label: 'Location Description',
    display: false
  },
  {
    id: 'last_seen',
    type: HeaderDataTypes.DATE,
    label: 'Last Seen (UTC)',
    display: true
  },
  {
    id: 'lat',
    type: HeaderDataTypes.NUMBER,
    label: 'Latitude',
    display: true
  },
  {
    id: 'long',
    type: HeaderDataTypes.NUMBER,
    label: 'Longitude',
    display: true
  },
  {
    id: 'cycle_time',
    type: HeaderDataTypes.NUMBER,
    label: 'Cycle Time',
    display: true,
    info: "Maximum time between wake up cycles."
  },
  {
    id: 'trigger_number',
    type: HeaderDataTypes.NUMBER,
    label: 'Trigger Number',
    display: true,
    info: "Number of backlog images before device wakes up."
  },
  {
    id: 'memory_remaining',
    type: HeaderDataTypes.NUMBER,
    label: 'Memory Remaining (MB)',
    display: false
  },
  {
    id: 'cycle_count',
    type: HeaderDataTypes.NUMBER,
    label: 'Cycle Count',
    display: false
  },
  {
    id: 'images_processed',
    type: HeaderDataTypes.NUMBER,
    label: 'Images Processed',
    display: false
  },
  {
    id: 'groups',
    type: HeaderDataTypes.STRING,
    label: 'Groups',
    display: false
  },
  {
    id: 'map_button',
    type: HeaderDataTypes.ELEMENT,
    label: 'Map',
    display: false
  }
];



export default function DeviceTable(props) {
  const [processedDevices, setProcessedDevices] = useState([]);
  const deviceData = useSelector((state) => state.device.deviceData);
  const groupData = useSelector((state) => state.group.groupData);
  const [modalData, setModalData] = useState({ open: false});
  const [groupMap, setGroupMap] = useState({});

  const handleClick = (event, row) => {
    const newModalData = {
      open: true,
      deviceId: row.device_id
    };
    setModalData(newModalData);
  }

  const handleClose = () => {
    setModalData({ open: false });
  }

  const getMobileElement = (row) => {
    return (
      <DeviceView device={row.device} row={row} />
    )
  }

  const getUpdateIcon = (device)  => {
    const status = device.device_update_status;
    switch(status) {
      case DEVICE_UPDATE_STATUS.SUCCESS: return (<Tooltip title={"Device update passed"}><PassedIcon style={{ color: 'green' }} /></Tooltip>);
      case DEVICE_UPDATE_STATUS.FAILED: return (<Tooltip title={"Device update failed"}><FailedIcon style={{ color: 'red' }} /></Tooltip>);
      case DEVICE_UPDATE_STATUS.PENDING: return (<Tooltip title={"Device update pending"}><PendingIcon style={{ color: '#ebc934' }} /></Tooltip>);
      default: return (<Tooltip title={"No current or past device updates"}><NoActionIcon style={{ color: 'grey' }} /></Tooltip>);
    }
  }

  useEffect(() => {
    if (deviceData) {
      setProcessedDevices(
        deviceData.map((device, index) => {
          return {
            id: index,
            device,
            device_id: device.device_id,
            display_name: getDisplayName(device),
            on_device_name: device.device_name,
            location_name: device.location_name,
            location_description: device.location_description,
            last_seen: getFormattedTimeString(device.last_seen),
            lat: device.latitude,
            long: device.longitude,
            cycle_time: device.cycle_time,
            trigger_number: device.trigger_number,
            memory_remaining: device.memory_remaining || device?.summary?.space_remaining || null,
            images_processed: device.images_processed || device?.summary?.image_count || null,
            cycle_count: device?.summary?.cycle_count || null,
            groups: device.device_id in groupMap ? groupMap[device.device_id].join(", ") : "None",
            map_button: {element: (<InsightMapDialog disabled={!device.latitude || !device.longitude} insight={device}/>), present: !!device.latitude && !!device.longitude},
            device_update_status: {element: getUpdateIcon(device), present: true},
          }
        })
      );
    }
    
  }, [deviceData, groupMap]);

  useEffect(() => {
    if (deviceData && groupData) {
      const groupDevice = {};
      for (const group of groupData) {
        for (const device of group.devices) {
          if (!(device in groupDevice)) {
            groupDevice[device] = [];
          }
          groupDevice[device].push(group.group_name);
        }
      }
      setGroupMap(groupDevice);
    }
  }, [deviceData, groupData]);

  return (
    <>
      <CustomTableContainer
        defaultOrder="desc"
        defaultOrderHeader="device_id"
        headers={defaultHeaders}
        rowData={processedDevices}
        paginationOptions={[5, 10, 25, 50, 100]}
        defaultPaginationOption={25}
        clickAction={handleClick}
        getMobileElement={getMobileElement}
        swipeable={true}
        insightsTable={false}
        AdditionalToolbar={false}
        UnsavedChangesSnackbar={false}
      />
      {
        modalData && modalData.deviceId && 
        (<EditDeviceModal
          device={modalData.deviceId}
          open={modalData.open}
          handleClose={handleClose}
        />)
      }
      
    </>
  );
}
