import React, { useEffect, useState } from 'react';
import {
  ListItem,
  TextField,
  withStyles,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import BasicModal from '../../Common/Modals/BasicModal';
import { AlertSettings, DigestSettings, IntegrationPlatforms, NotificationConstants } from '../../../util/constants';
import { useDispatch } from 'react-redux';
import { createUserIntegration, deleteUserIntegration, updateIntegrationDeviceGroups, updateIntegrationEmails, updateIntegrationNotifications, updateIntegrationSMS } from '../../../actions/integrationActions';
import NotificationSection from './NotificationSection';
import CenteredCircularProgress from '../../Common/CenteredCircularProgress';
import EmailList from './EmailList';
import SMSList from './SMSList';
import GroupsSection from './GroupsSection';

const styles = (theme) => ({
  listItem: {
    padding: theme.spacing(3),
    margin: 0
  },
  listItemText: {
    marginLeft: theme.spacing(1),
    fontSize: '1rem'
  },
  integrationSettingsContainer: {
    marginBottom: theme.spacing(3)
  },
  overallContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
    margin: 0,
    padding: 0
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center'
    
  },
  checkbox: {
    paddingLeft: 0
  }
});

const deleteModalContent = (
  <Typography>Deleting the integration is permanent. You may add a new integration after deleting the old one.</Typography>
);

const IntegrationItem = (props) => {
  const { classes, icon, text, platform, hasIntegration, availableClasses, availableGroups, loading, integrationData, snackbarMessageController } = props;

  const [apiKey, setApiKey] = useState("");
  const [emails, setEmails] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [alert, setAlert] = useState(true);
  const [digest, setDigest] = useState(false);
  const [notificationClasses, setNotificationClasses] = useState([]);
  const [groupNotifications, setGroupNotifications] = useState([]);
  const [processedGroupNotifications, setProcessedGroupNotifications] = useState([]);
  const [switchEnabled, setSwitchEnabled] = useState(false);
  const dispatch = useDispatch();

  const getNotificationSection = () => {
    return (
      <>
        <Typography className={classes.title} variant="subtitle1" noWrap>Notification Settings</Typography>
        <FormGroup>
          <FormControlLabel control={<Switch onChange={(event) => setSwitchEnabled(event.target.checked)} checked={switchEnabled} />} label="Notify for select device groups?" />
        </FormGroup>
        {
          switchEnabled ? 
          <GroupsSection availableGroups={availableGroups} availableClasses={availableClasses} onGroupChange={setProcessedGroupNotifications} notificationGroups={groupNotifications}/> :
          <NotificationSection notificationClasses={notificationClasses} onNotificationChange={setNotificationClasses} availableClasses={availableClasses} />
        }
      </>
    );
  }

  const getEmailContent = () => {
    return (
      <div className={classes.overallContainer}>
        <div className={classes.integrationSettingsContainer}>
          <Typography variant="subtitle1">Email Settings</Typography>
          <EmailList emails={emails} setEmails={(newEmails) => setEmails(newEmails)} snackbarMessageController={snackbarMessageController}/> 
          <div className={classes.checkboxContainer}>
            <Checkbox
              className={classes.checkbox}
              checked={alert}
              onChange={() => setAlert(!alert)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography variant='body1'>Alert as Insights are Recorded</Typography>
          </div>
          <div className={classes.checkboxContainer}>
            <Checkbox
              className={classes.checkbox}
              checked={digest}
              onChange={() => setDigest(!digest)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography variant='body1'>Send Daily Digest Email</Typography>
          </div>
        </div>
        {getNotificationSection()}
      </div>
    );
  }

  const getSMSContent = () => {
    return (
      <div className={classes.overallContainer}>
        <div className={classes.integrationSettingsContainer}>
          <Typography variant="subtitle1">SMS Settings</Typography>
          <SMSList phoneNumbers={phoneNumbers} setPhoneNumbers={(newPhoneNumbers) => setPhoneNumbers(newPhoneNumbers)} snackbarMessageController={snackbarMessageController}/> 
        </div>
        {getNotificationSection()}
      </div>
    );
  }

  let createContent = (<Typography></Typography>);
  let editContent = (<Typography></Typography>);
  let platformSpecificFunction = undefined;

  switch (platform) {
    case IntegrationPlatforms.GUNDI: 
      createContent = (
        <div className={classes.overallContainer}>
          <div className={classes.integrationSettingsContainer}>
            <Typography variant="subtitle1">Integration Settings</Typography>
            <TextField 
              fullWidth
              label="Gundi API Key"
              margin="dense"
              name="gundiApiKey"
              variant="outlined"
              value={apiKey} 
              onChange={(event) => setApiKey(event.target.value)}
            />
          </div>
          {getNotificationSection()}
        </div>
      );
      editContent = (
        <div className={classes.overallContainer}>
          {getNotificationSection()}
        </div>
      );
      break;
    case IntegrationPlatforms.EMAIL:
      createContent = getEmailContent();
      editContent = getEmailContent();
      platformSpecificFunction = () => updateIntegrationEmails({
        options: {
          emails,
          alertSetting: alert ? AlertSettings.ENABLED : AlertSettings.DISABLED,
          digestSetting: digest ? DigestSettings.DAILY : null
        }
      })
      break;
    case IntegrationPlatforms.SMS:
      createContent = getSMSContent();
      editContent = getSMSContent();
      platformSpecificFunction = () => updateIntegrationSMS({
        options: {
          phoneNumbers
        }
      })
      break;
  };

  useEffect(() => {
    refresh();
  }, [integrationData])

  const refresh = () => {
    if (integrationData) {
      setEmails(integrationData.emails ? integrationData.emails : emails);
      setAlert(integrationData.alertSetting ? integrationData.alertSetting === AlertSettings.ENABLED : alert);
      setDigest(integrationData.digestSetting ? integrationData.digestSetting === DigestSettings.DAILY : digest);
      setPhoneNumbers(integrationData.phoneNumbers ? integrationData.phoneNumbers : phoneNumbers);
      setNotificationClasses(integrationData.notificationClasses ? integrationData.notificationClasses : notificationClasses);
      setGroupNotifications(integrationData.groupNotifications ? integrationData.groupNotifications : groupNotifications);
      setSwitchEnabled(integrationData.groupNotifications && integrationData.groupNotifications.length > 0);
      setApiKey(integrationData.gundiApiKey ? integrationData.gundiApiKey : apiKey);
    } else {
      clear();
    }
  };

  const clear = () => {
    setEmails([]);
    setPhoneNumbers([]);
    setAlert(true);
    setDigest(false);
    setNotificationClasses([]);
    setGroupNotifications([]);
    setApiKey("");
  }

  const handleSubmit = () => {
    dispatch(
      createUserIntegration({
        platform, 
        options: {
          gundiApiKey: apiKey,
          emails,
          phoneNumbers,
          alertSetting: alert ? AlertSettings.ENABLED : AlertSettings.DISABLED,
          digestSetting: digest ? DigestSettings.DAILY : null,
          groupNotifications: processedGroupNotifications.length === 0 || !switchEnabled ? [] : processedGroupNotifications
        },
        notificationClasses: switchEnabled ? [NotificationConstants.ALL] : (notificationClasses.length === 0 ? [NotificationConstants.NONE] : notificationClasses)
      })
    );
    refresh();
  };

  const handleEdit = () => {
    if (platformSpecificFunction) {
      dispatch(platformSpecificFunction());
    }
    dispatch(
      updateIntegrationNotifications(
        platform, 
        { 
          notificationClasses: switchEnabled ? [NotificationConstants.ALL] : (notificationClasses.length === 0 ? [NotificationConstants.NONE] : notificationClasses)
        }
      )
    );
    dispatch(
      updateIntegrationDeviceGroups(
        platform, 
        { 
          groupNotifications: processedGroupNotifications.length === 0 || !switchEnabled ? [] : processedGroupNotifications
        }
      )
    );
    refresh();
  };

  const handleDelete = () => {
    dispatch(deleteUserIntegration(platform));
    clear();
  };

  return (
    <ListItem className={classes.listItem}>
        <ListItemAvatar>
            <Avatar>
                {icon}
            </Avatar>
        </ListItemAvatar>
        <ListItemText
            primary={text}
            classes={{primary:classes.listItemText}}
        />
        {loading ? <CenteredCircularProgress size={30}/> : hasIntegration ?
          <div>
            <BasicModal 
              confirmText="Edit" 
              title={`Edit ${text} Integration?`} 
              content={editContent} 
              icon={(<EditIcon/>)} 
              confirmAction={handleEdit}
              fullWidth
              cancelAction={refresh}
            /> 
            <BasicModal 
              confirmText="Delete" 
              title={`Delete ${text} Integration?`} 
              content={deleteModalContent} 
              icon={(<DeleteIcon/>)} 
              fullWidth
              confirmAction={handleDelete}
            /> 
          </div>: 
          <BasicModal 
            confirmText="Save" 
            title={`${text} Integration`} 
            icon={(<AddIcon/>)} 
            content={createContent} 
            confirmAction={handleSubmit}
            fullWidth
            cancelAction={refresh}
          />
        }
    </ListItem>
    );
};

export default withStyles(styles)(IntegrationItem);
