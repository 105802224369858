import React, { useState, useEffect } from 'react';

import WidgetCommon from '../Common/widgetCommon'
import { withStyles } from '@material-ui/core';
import { ResponsivePie } from '@nivo/pie';
import { useMediaQuery } from 'react-responsive';

const styles = (theme) => ({});

const InsightStatuses = (props) => {
    const { isLoading, insights } = props;
    const [graphData, setGraphData] = useState([]);

    const isMobile = useMediaQuery({ query: '(max-width: 625px)'});

    useEffect(() => {
        if (insights) {
            const commsMap = {};
            for (const insight of insights) {
                const source = insight.comms_source.toLowerCase();
                if (commsMap[source]) {
                    commsMap[source] += 1;
                } else {
                    commsMap[source] = 1;
                }
            }

            const graphData = [];
            for (const key in commsMap) {
                graphData.push({ id: key, label: key, value: commsMap[key] });
            }

            setGraphData(graphData);
        }
    }, [insights])

    return (
        <WidgetCommon title="Comms Source" isLoading={isLoading} hasNoData={!insights} >
            <ResponsivePie
                data={graphData}
                height={400}
                margin={{ top: 30, right: 80, bottom: 80, left: 80}}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                colors={{ scheme: 'paired' }}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            0.2
                        ]
                    ]
                }}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            2
                        ]
                    ]
                }}
                legends={[
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: 56,
                        itemsSpacing: 0,
                        itemWidth: 80,
                        itemHeight: 18,
                        itemTextColor: '#999',
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 18,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000'
                                }
                            }
                        ]
                    }
                ]}
            />
        </WidgetCommon>
    )
}

export default withStyles(styles)(InsightStatuses);