import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Checkbox, Typography, makeStyles } from '@material-ui/core';
import { HeaderDataTypes } from '../../../util/constants';


const useStyles = makeStyles({
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  checkbox: {
    paddingLeft: 0
  }
});


export default function DownloadCSVModal(props) {
  const { rowData, filteredRowData, headers } = props;
  const [open, setOpen] = useState(false);
  const [useFilteredData, setUseFilteredData] = useState(false);
  const [exportedData, setExportedData] = useState(rowData);

  const classes = useStyles();

  const handleCheckChange = (event) => {
    const checked = event.target.checked;
    if (checked) {
      setExportedData(filteredRowData);
      setUseFilteredData(true);
    } else {
      setExportedData(rowData);
      setUseFilteredData(false);
    }
  }

  /* API DOWNLOAD */

  /* create csv */
  const createCSV = () => {
    const headerIds = headers.filter((header) => header.type !== HeaderDataTypes.ELEMENT).map((header) => header.id);
    let csvData = "";
    csvData += headerIds.join(",");
    csvData += "\n";

    exportedData.forEach((row) => {
      const dataElements = [];
      headerIds.forEach((headerId) => {
        if (headerId === "status") {
          dataElements.push(row.insight.insight_status ? row.insight.insight_status.toString() : "none");
        } else {
          dataElements.push(row[headerId] ? row[headerId].toString().split(",").join(" ") : "");
        }
      });
      csvData += dataElements.join(",");
      csvData += "\n";
    });
    return csvData;
  }

  /* download csv */
  const downloadCSV = () => {
    if (!headers || !exportedData) {
      return;
    }
    let csv = 'data:text/csv;charset=utf-8,' + createCSV(); //Creates CSV File Format
    let excel = encodeURI(csv); //Links to CSV

    let link = document.createElement('a');
    link.setAttribute('href', excel); //Links to CSV File
    link.setAttribute('download', 'sentinel_insights.csv'); //Filename that CSV is saved as
    link.click();
    setOpen(false);
  }

  useEffect(() => {
    setExportedData(rowData);
  }, [rowData]);

  useEffect(() => {
    if (useFilteredData) {
      setExportedData(filteredRowData);
    }
  }, [filteredRowData]);

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          setOpen(true);
        }}
      >
        Download CSV
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          Export to CSV
        </DialogTitle>
        <DialogContent>
          <div className={classes.checkboxContainer}>
            <Checkbox
              className={classes.checkbox}
              checked={useFilteredData}
              onChange={handleCheckChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography variant='body1'>Only export filtered data?</Typography>
        </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={downloadCSV}
            variant="contained"
          >
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
