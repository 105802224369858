import {
    FETCH_USER_INFO_SQL_BEGIN,
    FETCH_USER_INFO_SQL_SUCCESS,
    FETCH_USER_INFO_SQL_FAILURE,
  } from '../actions/userInfoSqlActions';
  
  const initialState = {
    userInfoSqlData: [],
    userInfoSqlLoading: true,
    result: '',
  };
  
  const userInfoSqlReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_USER_INFO_SQL_BEGIN:
        return {
          ...state,
          userInfoSqlLoading: true,
          result: 'BEGINNING',
        };
      case FETCH_USER_INFO_SQL_SUCCESS:
        return {
          ...state,
          userInfoSqlData: action.payload.response.data,
          userInfoSqlLoading: false,
          result: 'SUCCESS',
        };
      case FETCH_USER_INFO_SQL_FAILURE:
        return {
          ...state,
          result: 'FAILURE',
          userInfoSqlLoading: false,
        };
      default:
        return state;
    }
  };
  
  export default userInfoSqlReducer;
  