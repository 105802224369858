import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArchiveIcon from '@mui/icons-material/Archive';
import StarIcon from '@mui/icons-material/Star';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    cellIcons: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    iconInactive: {
      color: '#6A6A6A',
    },
    iconActive: {
      color: '#3893BE',
    }
  });

export default function ButtonFilters(props) {
    const classes = useStyles();
    const { handleClickButtonFilter, actionFilter } = props;

    return (
      <Box>
        <Tooltip title="Filter by acknowledged">
          <IconButton onClick={(event) => handleClickButtonFilter(event, "acknowledge")}>
            <VisibilityIcon className={actionFilter === "acknowledge" ? classes.iconActive : classes.iconInactive} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Filter by archived">
          <IconButton onClick={(event) => handleClickButtonFilter(event, "archive")}>
            <ArchiveIcon className={actionFilter === "archive" ? classes.iconActive : classes.iconInactive} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Filter by favorited">
          <IconButton onClick={(event) => handleClickButtonFilter(event, "favorite")}>
            <StarIcon className={actionFilter === "favorite" ? classes.iconActive : classes.iconInactive} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Filter by uncategorized">
          <IconButton onClick={(event) => handleClickButtonFilter(event, "none")}>
            <NotInterestedIcon className={actionFilter === "none" ? classes.iconActive : classes.iconInactive} />
          </IconButton>
        </Tooltip>
      </Box >
    )
  }