import React, { useState } from 'react';
import {
  withStyles,
  IconButton,
  TextField
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { isEmail } from '../../../util/validators';
const styles = (theme) => ({
  listItem: {
    padding: 0,
    margin: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: 0
  },
  container: {
    padding: 0,
    margin: 0,
    display: "flex",
    flexDirection: "column",
    minWidth: 0
  },
  icon: {
    marginLeft: "20px",
    flexShrink: 0
  },
  textLimit: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  }
});

const EMAIL_LIMIT = 3;

const EmailList = (props) => {
  const { classes, emails, setEmails, snackbarMessageController} = props;
  const [newEmail, setNewEmail] = useState("");

  const handleDelete = (email) => {
    const newEmails = emails.filter((value) => value !== email);
    setEmails(newEmails);
  };

  const getEmailComponents = () => {
    const components = [];
    for (const email of emails) {
      components.push(
        <div className={classes.listItem} key={email}>
          <p className={classes.textLimit}>{email}</p>
          <IconButton className={classes.icon} onClick={() => handleDelete(email)}>
            <DeleteIcon/>
          </IconButton>
        </div>
      );
    }
    components.push(
      <div className={classes.listItem} key="new_email">
        <TextField 
            fullWidth
            margin="dense"
            name="newEmail"
            disabled={emails.length >= EMAIL_LIMIT}
            variant="outlined"
            label={emails.length >= EMAIL_LIMIT ? `Maximum ${EMAIL_LIMIT} Emails` : "Add Email"}
            value={newEmail} 
            onChange={(e) => {
              setNewEmail(e.target.value);
            }}
        />
        <IconButton className={classes.icon} disabled={emails.length >= EMAIL_LIMIT} onClick={handleAdd}>
          <AddIcon/>
        </IconButton>
      </div>
    );
    return components;
  }

  const handleAdd = () => {
    if (emails.includes(newEmail)) {
      snackbarMessageController("Email has already been added.");
      return;
    } 
    if (!isEmail(newEmail)) {
      snackbarMessageController("Not a valid email.");
      return;
    }
    const newEmails = [...emails, newEmail];
    setEmails(newEmails);
    setNewEmail("");
  };

  return (
    <div className={classes.container}>
      {getEmailComponents()}
    </div>
  );
};

export default withStyles(styles)(EmailList);
