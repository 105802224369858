import React, { useState, useEffect } from 'react';

import { ResponsiveLine } from '@nivo/line'
import WidgetCommon from '../Common/widgetCommon'
import { withStyles } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';

const styles = (theme) => ({});

const InsightsLast2Weeks = (props) => {
  const { isLoading, insights } = props;
  const [graphData, setGraphData] = useState([]);

  const isMobile = useMediaQuery({ query: '(max-width: 625px)'});

  
  useEffect(() => {
    if (insights) {
      const dataMap = {};
      const currentDate = (Date.now()) + ((new Date()).getTimezoneOffset() * 60 * 1000);
      for (let i = 0; i < 14; i++) {
        const dateString = (new Date(currentDate - ((13 - i) * 24 * 60 * 60 * 1000))).toISOString().split("T")[0];
        dataMap[dateString] = {
          index: i,
          x: dateString, 
          y: 0
        };
      }
      
      for (const insight of insights) {
        if (insight.time_stamp) {
          const insightDate = (new Date(insight.time_stamp));
          if (insightDate.toISOString().split("T")[0] in dataMap) {
            dataMap[insightDate.toISOString().split("T")[0]].y += 1;
          }
        }
      }
      setGraphData([{ id: "data", data: Object.values(dataMap).sort((a, b) => a.index - b.index)}]);
    }
  }, [insights])
  
  return (
    <WidgetCommon 
      title="Insights over the Last 2 Weeks" 
      isLoading={isLoading} 
      hasNoData={graphData.filter((data) => data.y !== 0).length === 0} 
      hasTooMuchData={false} 
      tooMuchDataString=""
      info="Information only shows the last 2 weeks, regardless of the filters."
    >
      <ResponsiveLine
        data={graphData}
        margin={{ top: isMobile ? 10: 50, right: 10, bottom: 90, left: isMobile ? 40 : 60 }}
        xScale={{ type: 'point' }}
        yScale={{
            type: 'linear',
            min: 0,
            max: 'auto',
            reverse: false
        }}
        yFormat=" >-.0f"
        colors={{ scheme: 'paired' }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 90,
            legend: !isMobile && 'Date',
            legendPosition: 'middle',
            legendOffset: 80,
            truncateTickAt: 0
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: !isMobile && 'Count',
            legendPosition: 'middle',
            legendOffset: -40,
            truncateTickAt: 0
        }}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        ariaLabel="Insights in Last Month"
      />
    </WidgetCommon>
  )
}

export default withStyles(styles)(InsightsLast2Weeks);