import React from 'react';
import {
  withStyles,
  CircularProgress
} from '@material-ui/core';

const styles = (theme) => ({
  circleDiv: {
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItem: 'center', 
    justifyContent: 'center'
  },
});

const CentedCircularProgress = (props) => {
    const { classes, size } = props;
  return (
    <div className={classes.circleDiv}>
      <CircularProgress size={size}/>
    </div>
  );
};

export default withStyles(styles)(CentedCircularProgress);
