import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from '../Landing Page/components/Header/Header';
import ProductSection from '../Landing Page/components/Product/ProductSection';
import StickyHeader from '../Landing Page/components/Header/StickyHeader';
import HardwareSection from '../Landing Page/components/HardwareSection/HardwareSection';
import DashboardSection from '../Landing Page/components/DashboardSection/DashboardSection';
import MarketplaceSection from '../Landing Page/components/MarketplaceSection/MarketplaceSection';
import CustomModelSection from '../Landing Page/components/CustomModelSection/CustomModelSection';
import CommunicationSection from '../Landing Page/components/CommunicationSection/CommunicationSection';
import GetStarted from '../Landing Page/components/Bottom/GetStarted';
import Footer from '../Landing Page/components/Bottom/Footer';
import PricingCalculatorSection from '../Landing Page/components/PricingCalculatorSection/Calculator';
import PartnerSection from '../Landing Page/components/PartnerSection/PartnerSection';
import { useMediaQuery } from 'react-responsive'

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        // backgroundImage: backgroundImage,
        // backgroundImage: "url('https://images.unsplash.com/photo-1593069567131-53a0614dde1d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1489&q=80')",
        // backgroundImage: "url('https://images.unsplash.com/photo-1604213410393-89f141bb96b8?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80')",
        backgroundImage: "url('https://images.unsplash.com/photo-1596779845727-d88eb78a1b08?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1491&q=80')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        // backgroundSize: 'cover',

    }

}));


export default function Landing() {
  const classes = useStyles();

  const isDesktop = useMediaQuery({ query: '(min-width: 1224px)'})
  const isMobile = useMediaQuery({ query: '(max-width: 1224px)'})
  
  return (
      <div className={classes.root}>
          <CssBaseline />
          <Header />
          {isDesktop &&
          <StickyHeader />}
          {isDesktop &&
          <ProductSection />}
          {isMobile &&  <div style={{ marginTop: '-250px' }}>
          <ProductSection />
          </div> }
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
    <h1> Hardware </h1>
  </div>
          <HardwareSection />
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
    <h1> Software </h1>
  </div>
          <DashboardSection />
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
    <h2> Start Using Computer Vision in 1-2-3 </h2>
  </div>
          <MarketplaceSection />
          <CustomModelSection />
          <CommunicationSection />
          <PricingCalculatorSection />
          {isMobile &&  <div style={{ marginTop: '350px' }}>
          <PartnerSection />
          </div> }
          {isDesktop &&  <div>
          <PartnerSection />
          </div> }
          <GetStarted />
          <Footer />
      </div>
  );
}
