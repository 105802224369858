import {
  FETCH_USER_BEGIN,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  CHANGE_PASSWORD_BEGIN,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  UPDATE_USER_BEGIN,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_PHOTO_BEGIN,
  UPDATE_USER_PHOTO_SUCCESS,
  UPDATE_USER_PHOTO_FAILURE,
  FETCH_CARD_INFO_BEGIN,
  FETCH_CARD_INFO_SUCCESS,
  FETCH_CARD_INFO_FAILURE,
  UPDATE_CARD_INFO_BEGIN,
  UPDATE_CARD_INFO_SUCCESS,
  UPDATE_CARD_INFO_FAILURE,
  FETCH_CHARGES_BEGIN,
  FETCH_CHARGES_SUCCESS,
  FETCH_CHARGES_FAILURE,
} from '../actions/userActions';

const initialState = {
  firstName: '',
  lastName: '',
  username: '',
  email: '',
  country: '',
  phoneNumber: '',
  userIdSql: '',
  imageUrl: '',
  cardInfo: '',
  charges: [],
  fetchUserProgress: '',
  changePasswordProgress: '',
  changePasswordError: '',
  updateUserProgress: '',
  updatePhotoProgress: '',
  fetchCardInfoProgress: '',
  updateCardInfoProgress: '',
  updateCardInfoError: '',
  fetchChargesProgress: '',
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_BEGIN:
      return {
        ...state,
        fetchUserProgress: 'BEGINNING',
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        firstName: action.payload.response.data.userCredentials.firstName,
        lastName: action.payload.response.data.userCredentials.lastName,
        email: action.payload.response.data.userCredentials.email,
        country: action.payload.response.data.userCredentials.country,
        phoneNumber: action.payload.response.data.userCredentials.phoneNumber,
        username: action.payload.response.data.userCredentials.username,
        userIdSql: action.payload.response.data.userCredentials.userIdSql,
        imageUrl: action.payload.response.data.userCredentials.imageUrl,
        fetchUserProgress: 'SUCCESS',
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        fetchUserProgress: 'FAILURE',
      };
    case CHANGE_PASSWORD_BEGIN:
      return {
        ...state,
        changePasswordProgress: 'BEGINNING',
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordProgress: 'SUCCESS',
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        changePasswordProgress: 'FAILURE',
        changePasswordError: action.payload.error,
      };
    case UPDATE_USER_BEGIN:
      return {
        ...state,
        updateUserProgress: 'BEGINNING',
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        firstName: action.payload.user.firstName,
        lastName: action.payload.user.lastName,
        country: action.payload.user.country,
        updateUserProgress: 'SUCCESS',
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        updateUserProgress: 'FAILURE',
      };
    case UPDATE_USER_PHOTO_BEGIN:
      return {
        ...state,
        updatePhotoProgress: 'BEGINNING',
      };
    case UPDATE_USER_PHOTO_SUCCESS:
      return {
        ...state,
        imageUrl: action.payload.imageUrl,
        updatePhotoProgress: 'SUCCESS',
      };
    case UPDATE_USER_PHOTO_FAILURE:
      return {
        ...state,
        updatePhotoProgress: 'FAILURE',
      };
    case FETCH_CARD_INFO_BEGIN:
      return {
        ...state,
        fetchCardInfoProgress: 'BEGINNING',
      };
    case FETCH_CARD_INFO_SUCCESS:
      return {
        ...state,
        cardInfo: action.payload.cardInfo,
        fetchCardInfoProgress: 'SUCCESS',
      };
    case FETCH_CARD_INFO_FAILURE:
      return {
        ...state,
        fetchCardInfoProgress: 'FAILURE',
      };
    case UPDATE_CARD_INFO_BEGIN:
      return {
        ...state,
        updateCardInfoProgress: 'BEGINNING',
      };
    case UPDATE_CARD_INFO_SUCCESS:
      return {
        ...state,
        updateCardInfoProgress: 'SUCCESS',
      }
    case UPDATE_CARD_INFO_FAILURE:
      return {
        ...state,
        updateCardInfoProgress: 'FAILURE',
        updateCardInfoError: action.payload.error
      };
    case FETCH_CHARGES_BEGIN:
      return {
        ...state,
        fetchChargesProgress: 'BEGINNING',
      };
    case FETCH_CHARGES_SUCCESS:
      return {
        ...state,
        charges: action.payload.response.data,
        fetchChargesProgress: 'SUCCESS',
      };
    case FETCH_CHARGES_FAILURE:
      return {
        ...state,
        fetchChargesProgress: 'FAILURE',
      };
    default:
      return state;
  }
};

export default userReducer;
