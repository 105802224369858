import {
  FETCH_GROUPS_BEGIN,
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUPS_FAILURE,
  UPDATE_GROUP_BEGIN,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAILURE,
  CREATE_GROUP_BEGIN,
  CREATE_GROUP_FAILURE,
  CREATE_GROUP_SUCCESS,
  DELETE_GROUP_BEGIN,
  DELETE_GROUP_FAILURE,
  DELETE_GROUP_SUCCESS,
  ADD_DEVICE_TO_GROUP_BEGIN,
  ADD_DEVICE_TO_GROUP_SUCCESS,
  ADD_DEVICE_TO_GROUP_FAILURE,
  REMOVE_DEVICE_FROM_GROUP_BEGIN,
  REMOVE_DEVICE_FROM_GROUP_SUCCESS,
  REMOVE_DEVICE_FROM_GROUP_FAILURE,
} from '../actions/groupActions';

const initialState = {
  groupData: [],
  deployedGroupData: [],
  result: '',
  loadingGroups: false
};

const groupReducer = (state = initialState, action) => {
  const groupDataNew = state.groupData;
  let groupData;


  switch (action.type) {
    case FETCH_GROUPS_BEGIN:
      return {
        ...state,
        loadingGroups: true,
        result: 'BEGINNING',
      };
    case FETCH_GROUPS_SUCCESS:
      groupData = action.payload.response.data.message;
      return {
        ...state,
        groupData: groupData.groups,
        loadingGroups: false,
        result: 'SUCCESS',
      };
    case FETCH_GROUPS_FAILURE:
      return {
        ...state,
        loadingGroups: false,
        result: 'FAILURE',
      };
    case UPDATE_GROUP_BEGIN:
      return {
        ...state,
        result: 'BEGINNING',
      };
    case UPDATE_GROUP_SUCCESS:
      const updatedGroup = action.payload.response.data.message;
      const groupIndex = state.groupData.findIndex(
        (group) => group.group_id === updatedGroup.group_id
      );
      groupDataNew[groupIndex] = { ...groupDataNew[groupIndex], ...updatedGroup };
      return {
        ...state,
        groupData: [...groupDataNew],
        result: 'SUCCESS',
      };
    case UPDATE_GROUP_FAILURE:
      return {
        ...state,
        result: 'FAILURE',
      };
    case ADD_DEVICE_TO_GROUP_BEGIN:
      return {
        ...state,
        result: 'BEGINNING',
      };
    case ADD_DEVICE_TO_GROUP_SUCCESS:
      const addedDeviceList = action.payload.response.data.message;
      const addedDeviceGroupId = action.payload.groupId;
      const addedDeviceGroupDataNew = [...state.groupData];
      for (let i = 0; i < addedDeviceGroupDataNew.length; i++) {
        const addedDeviceGroupData = addedDeviceGroupDataNew[i];
        if (addedDeviceGroupData.group_id === addedDeviceGroupId) {
          const newData = {...addedDeviceGroupData};
          newData.devices = addedDeviceList;
          addedDeviceGroupDataNew[i] = newData;
        }
      }
      return {
        ...state,
        groupData: addedDeviceGroupDataNew,
        result: 'SUCCESS',
      };
    case ADD_DEVICE_TO_GROUP_FAILURE:
      return {
        ...state,
        result: 'FAILURE',
      };
    case REMOVE_DEVICE_FROM_GROUP_BEGIN:
      return {
        ...state,
        result: 'BEGINNING',
      };
    case REMOVE_DEVICE_FROM_GROUP_SUCCESS:
      const removedDeviceList = action.payload.response.data.message;
      const removedDeviceGroupId = action.payload.groupId;
      const removedDeviceGroupDataNew = [...state.groupData];
      for (let i = 0; i < removedDeviceGroupDataNew.length; i++) {
        const removedDeviceGroupData = removedDeviceGroupDataNew[i];
        if (removedDeviceGroupData.group_id === removedDeviceGroupId) {
          const newData = {...removedDeviceGroupData};
          newData.devices = removedDeviceList;
          removedDeviceGroupDataNew[i] = newData;
        }
      }
      return {
        ...state,
        groupData: removedDeviceGroupDataNew,
        result: 'SUCCESS',
      };
    case REMOVE_DEVICE_FROM_GROUP_FAILURE:
      return {
        ...state,
        result: 'FAILURE',
      };
    case CREATE_GROUP_BEGIN:
      return {
        ...state,
        result: 'BEGINNING',
      };
    case CREATE_GROUP_SUCCESS:
      // Copy the existing groups

      const groupDataAdd = [...state.groupData];
      let newGroup = action.payload.group;
      console.log('CREATE_GROUP_SUCCESS newGroup ', newGroup);

      newGroup['devices'] = [];

      console.log('CREATE_GROUP_SUCCESS modified newGroup ', newGroup);

      groupDataAdd.push(newGroup);
      return {
        ...state,
        groupData: groupDataAdd,
        result: 'SUCCESS',
      };
    case CREATE_GROUP_FAILURE:
      return {
        ...state,
        result: 'FAILURE',
      };

    case DELETE_GROUP_BEGIN:
      return {
        ...state,
        result: 'BEGINNING',
      };
    case DELETE_GROUP_SUCCESS:
      // Remove the newly deleted group from the list of groups
      const deletedGroupId = action.payload.group.group_id;
      return {
        ...state,
        groupData: groupDataNew.filter(
          (group) => group.group_id !== deletedGroupId
        ),
        result: 'SUCCESS',
      };
    case DELETE_GROUP_FAILURE:
      return {
        ...state,
        result: 'FAILURE',
      };

    default:
      return state;
  }
};

export default groupReducer;
