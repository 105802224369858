import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import CreateIcon from '@material-ui/icons/Create';
import { updateGroup } from '../../actions/groupActions';

/**
 * The Edit Group Modal is the icon that opens a pop-up window to modify attributes of the group.
 * An API call to modify the group is only made once the user hits the [Save] button (not while
 * entering changes) AND all of the required fields are non-empty and verified.
 */
const EditGroupModal = (props) => {
  const { group } = props;
  // True if the modal is currently shown, false otherwise
  const [open, setOpen] = React.useState(false);
  /** @type {[GroupData, React.Dispatch<React.SetStateAction<GroupData>>]} The new properties for the group, before getting sent */
  const [newGroupData, setGroupData] = React.useState(group);
  /** @type {[Map<string, string>,  React.Dispatch<React.SetStateAction<Map<string, string>>>]} Maps fields to its error message */
  const [errors, setErrors] = React.useState(new Map());
  /** @type {GroupData[]} */
  const groupData = useSelector((state) => state.group.groupData);
  /** @type {string[]} Names of existing groups in all lower case for case insensitivity */
  const existingGroupNames = groupData
    .filter((otherGroup) => otherGroup.group_id !== group.group_id)
    .map((group) => group.group_name.toLowerCase());

  const dispatch = useDispatch();

  /**
   * Triggers the function endpoint the update the group.
   */
  const handleSave = () => {
    // Verify Group Data
    const newErrors = new Map();

    // 1. Non-empty
    if (!newGroupData.group_name || newGroupData.group_name === 0)
      newErrors.set('group_name', 'Please enter a group name.');

    // 2. No duplicate with groups the user already has access to, case insensitive
    if (existingGroupNames.includes(newGroupData.group_name.toLowerCase()))
      newErrors.set('group_name', 'One of your groups already has this name.');

    setErrors(newErrors);
    // Don't do anything if there exist errors
    if (newErrors.size > 0) return;
    dispatch(updateGroup(newGroupData));
    setOpen(false);
  };

  const handleChange = (event, value) => {
    setGroupData({
      ...newGroupData,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <React.Fragment>
      <IconButton onClick={() => setOpen(true)}>
        <CreateIcon />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Editing Group: {group.group_name}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                margin="dense"
                label="Group Name"
                name="group_name"
                value={newGroupData.group_name}
                error={errors.get('group_name')}
                helperText={errors.get('group_name')}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                margin="dense"
                label="Group Description"
                name="deployment_description"
                value={newGroupData.deployment_description}
                error={errors.get('deployment_description')}
                helperText={errors.get('deployment_description')}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setOpen(false);
              setGroupData(group);
            }}
          >
            Cancel
          </Button>
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

EditGroupModal.propTypes = {
  // The existing information for the Group is required
  group: PropTypes.object.isRequired,
};

export default EditGroupModal;
