import React, { useState } from 'react';
import { styled, withStyles } from '@material-ui/core/styles';
import { Container, Tab, Tabs } from '@material-ui/core';

const styles = (theme) => ({
  centerTabs: { 
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '2px solid #e8e8e8',
    marginBottom: '2.0rem',
    marginTop: '1.0rem',
  },
});

const StyledTabs = styled(Tabs)(
  ({ theme }) => ({
    '& .MuiTabs-indicator': {
      backgroundColor: '#0093C5',
    },
  })
);

const StyledTab = styled(Tab)(
  ({ theme }) => ({
    textTransform: 'none',
    fontSize: '1rem',
    color: 'rgba(0, 0, 0, 1.0)',
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover': {
      color: '#0093C5',
      background: '#e6e6e6',
      borderRadius: '8px 8px 0 0',
    },
    '&.Mui-selected': {
      color: '#0093C5',
      background: '#e6e6e6',
      fontWeight: theme.typography.fontWeightMedium,
      borderRadius: '8px 8px 0 0',
    },
  })
);

const TabContentContainer = (props) => {
  const { classes, tabs } = props;
  const [value, setValue] = useState(0);

  const getTabPanel = (tabs) => {
    return (<Container >{tabs[value].content}</Container>);
  };
  
  const getTabs = (tabs) => {
    const tabElements = [];
    for (let i = 0; i < tabs.length; i++) {
      const tab = tabs[i];
      tabElements.push((
        <StyledTab disableRipple label={tab.label} value={i} key={`tab_${i}`} />
      ));
    }
    return tabElements;
  };

  return (
    <div>
      <div className={classes.centerTabs}>
        <StyledTabs 
          value={value} 
          onChange={(_, value) => setValue(value)} 
        >
          {getTabs(tabs)}
        </StyledTabs>
      </div>
      {getTabPanel(tabs)}
    </div>
  );
};

export default withStyles(styles)(TabContentContainer);
