import React, { useState, useEffect } from 'react';

import WidgetCommon from '../Common/widgetCommon'
import { withStyles } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArchiveIcon from '@mui/icons-material/Archive';
import StarIcon from '@mui/icons-material/Star';
import { Box } from '@mui/system';
import { makeStyles } from '@material-ui/core';
import { grayColor } from '../../../style/style';

const styles = (theme) => ({});
const useStyles = makeStyles({
    iconContainer: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    iconItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    number: {
        marginTop: 5, 
    },
    icon: {
        color: '#6A6A6A',
    }
});

const InsightStatuses = (props) => {
    const { isLoading, insights } = props;
    const [acknowledged, setAcknowledged] = useState(null);
    const [favorited, setFavorited] = useState(null);
    const [archived, setArchived] = useState(null);

    const classes = useStyles();

    useEffect(() => {
        if (insights) {
            setAcknowledged(insights.filter(insight => insight.insight.insight_status === "acknowledge").length);
            setFavorited(insights.filter(insight => insight.insight.insight_status === "favorite").length);
            setArchived(insights.filter(insight => insight.insight.insight_status === "archive").length);
        }
    }, [insights])

    return (
        <WidgetCommon height="16vh" maxHeight="200px" title="Status" isLoading={isLoading} hasNoData={!insights} >
            <Box className={classes.iconContainer}>
                <Box className={classes.iconItem}>
                    <VisibilityIcon className={classes.icon} style={{ fontSize: 55 }} />
                    <Typography variant="h4" style={{ color: grayColor[2] }}>
                        {acknowledged}
                    </Typography>
                </Box>
                <Box className={classes.iconItem}>
                    <ArchiveIcon className={classes.icon} style={{ fontSize: 55 }}/>
                    <Typography variant="h4" style={{ color: grayColor[2] }}>
                        {archived}
                    </Typography>
                </Box>
                <Box className={classes.iconItem}>
                    <StarIcon className={classes.icon} style={{ fontSize: 55 }}/>
                    <Typography variant="h4" style={{ color: grayColor[2] }}>
                        {favorited}
                    </Typography>
                </Box>
            </Box>
        </WidgetCommon>
    )
}

export default withStyles(styles)(InsightStatuses);