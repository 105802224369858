import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { getDisplayName } from '../../../util/deviceHelpers';
import { addDeviceToGroup, removeDeviceFromGroup } from '../../../actions/groupActions';
import Snackbar from '@material-ui/core/Snackbar';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
    minWidth: 150,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  rootSelect: {
    padding: '0px 1px',
  },
  inputLabel: {
    fontSize: 13,
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      // maxHeight: 400,
      // minWidth: 250,
      // maxWidth: 300,
    },
  },
  getContentAnchorEl: null,
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
};

export default function DeviceDrawer(props) {
  const classes = useStyles();
  const { group } = props;
  const dispatch = useDispatch();

  const deviceData = useSelector((state) => state.device.deviceData);

  const [deviceIds, setDeviceIds] = useState([]);
  const [deviceUpdate, setDeviceUpdate] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (group && group.devices) {
      setDeviceIds(group.devices);
    }
  }, [group]);

  const handleChange = (e) => {
    const updatedDeviceIdList = e.target.value;
    const updatedDeviceIdSet = new Set(e.target.value);
    const originalDeviceIdSet = new Set(deviceIds);
    const originalDeviceIdList = [...deviceIds];

    for (const deviceId of updatedDeviceIdList) {
      if (!originalDeviceIdSet.has(deviceId)) {
        dispatch(addDeviceToGroup(group.group_id, deviceId));
        setDeviceUpdate(group.group_name);
      }
    }
    for (const deviceId of originalDeviceIdList) {
      if (!updatedDeviceIdSet.has(deviceId)) {
        dispatch(removeDeviceFromGroup(group.group_id, deviceId));
        setDeviceUpdate(group.group_name);
      }
    }
    setDeviceIds(updatedDeviceIdList);
  }

  const handleClose = () => {
    if (deviceUpdate) {
      setOpen(true);
    }
  }

  const handleSnackClose = () => {
    setOpen(false);
    setDeviceUpdate(null);
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Snackbar
        open={open}
        autoHideDuration={1500}
        message={`Updated ${deviceUpdate}'s devices`}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      ></Snackbar>
      <FormControl
        variant="outlined"
        size="small"
        className={classes.formControl}
      >
        <InputLabel
          id="mutiple-checkbox-label-device"
          align="left"
          margin="dense"
        >
          Select Devices
        </InputLabel>
        <Select
          onClose={handleClose}
          multiple
          labelId="mutiple-checkbox-label-device"
          label="Select Devices"
          value={deviceIds}
          onChange={handleChange}
          renderValue={(selected) => {
            if (selected.length === 1) {
              const label =
                getDisplayName(deviceData.find((d) => d.device_id === selected[0])) || 'Unnamed';
              return label;
            } else if (selected.length > 1) {
              return 'Multiple Devices';
            } else {
              return 'None';
            }
          }}
          MenuProps={MenuProps}
          className={classes.rootSelect}
        >
          {deviceData.sort((a, b) => {
            if (getDisplayName(a).toLowerCase() > getDisplayName(b).toLowerCase()) {
              return 1;
            } else if (getDisplayName(a).toLowerCase() < getDisplayName(b).toLowerCase()) {
              return -1;
            }
            return 0;
          }).map((device, index) => (
            <MenuItem key={device.device_id} value={device.device_id}>
              <Checkbox checked={deviceIds.includes(device.device_id)} />
              <ListItemText primary={getDisplayName(device)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
