import { Action } from "../actions/actionsShared";
import { 
  FETCH_USER_INTEGRATIONS_PREFIX,
  CREATE_USER_INTEGRATION_PREFIX,
  DELETE_USER_INTEGRATION_PREFIX,
  UPDATE_INTEGRATION_NOTIFICATIONS,
  UPDATE_INTEGRATION_EMAILS,
  UPDATE_INTEGRATION_SMS,
  UPDATE_INTEGRATION_DEVICE_GROUPS
} from "../actions/integrationActions";


const initialState = {
  platforms: [],
  platformData: {}
};

const integrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_USER_INTEGRATIONS_PREFIX}${Action.BEGIN_SUFFIX}`:
      return {
        ...state,
        integrationsUpdated: false,
        result: 'BEGINNING',
      };
    case `${FETCH_USER_INTEGRATIONS_PREFIX}${Action.SUCCESS_SUFFIX}`:
      const message = action.payload.data.data.message;
      return {
        ...state,
        platforms: message && Object.keys(message),
        platformData: message,
        integrationsUpdated: false,
        result: 'SUCCESS',
      };
    case `${FETCH_USER_INTEGRATIONS_PREFIX}${Action.FAILURE_SUFFIX}`:
      return {
        ...state,
        integrationsUpdated: false,
        result: 'FAILURE'
      }
    case `${DELETE_USER_INTEGRATION_PREFIX}${Action.BEGIN_SUFFIX}`:
      return {
        ...state,
        integrationsUpdated: false,
        result: 'BEGINNING',
      };
    case `${DELETE_USER_INTEGRATION_PREFIX}${Action.SUCCESS_SUFFIX}`:
      return {
        ...state,
        integrationsUpdated: true,
        result: 'UPDATE_SUCCESS',
      };
    case `${DELETE_USER_INTEGRATION_PREFIX}${Action.FAILURE_SUFFIX}`:
      return {
        ...state,
        integrationsUpdated: false,
        result: 'UPDATE_FAILURE'
      };
    case `${CREATE_USER_INTEGRATION_PREFIX}${Action.BEGIN_SUFFIX}`:
      return {
        ...state,
        integrationsUpdated: false,
        result: 'BEGINNING',
      };
    case `${CREATE_USER_INTEGRATION_PREFIX}${Action.SUCCESS_SUFFIX}`:
      return {
        ...state,
        integrationsUpdated: true,
        result: 'UPDATE_SUCCESS',
      };
    case `${CREATE_USER_INTEGRATION_PREFIX}${Action.FAILURE_SUFFIX}`:
      return {
        ...state,
        integrationsUpdated: false,
        result: 'UPDATE_FAILURE'
      };
    case `${UPDATE_INTEGRATION_NOTIFICATIONS}${Action.BEGIN_SUFFIX}`:
      return {
        ...state,
        integrationsUpdated: false,
        result: 'BEGINNING',
      };
    case `${UPDATE_INTEGRATION_NOTIFICATIONS}${Action.SUCCESS_SUFFIX}`:
      return {
        ...state,
        integrationsUpdated: true,
        result: 'UPDATE_SUCCESS',
      };
    case `${UPDATE_INTEGRATION_NOTIFICATIONS}${Action.FAILURE_SUFFIX}`:
      return {
        ...state,
        integrationsUpdated: false,
        result: 'UPDATE_FAILURE'
      };
    case `${UPDATE_INTEGRATION_DEVICE_GROUPS}${Action.BEGIN_SUFFIX}`:
      return {
        ...state,
        integrationsUpdated: false,
        result: 'BEGINNING',
      };
    case `${UPDATE_INTEGRATION_DEVICE_GROUPS}${Action.SUCCESS_SUFFIX}`:
      return {
        ...state,
        integrationsUpdated: true,
        result: 'UPDATE_SUCCESS',
      };
    case `${UPDATE_INTEGRATION_DEVICE_GROUPS}${Action.FAILURE_SUFFIX}`:
      return {
        ...state,
        integrationsUpdated: false,
        result: 'UPDATE_FAILURE'
      };
    case `${UPDATE_INTEGRATION_EMAILS}${Action.BEGIN_SUFFIX}`:
      return {
        ...state,
        integrationsUpdated: false,
        result: 'BEGINNING',
      };
    case `${UPDATE_INTEGRATION_EMAILS}${Action.SUCCESS_SUFFIX}`:
      return {
        ...state,
        integrationsUpdated: true,
        result: 'UPDATE_SUCCESS',
      };
    case `${UPDATE_INTEGRATION_EMAILS}${Action.FAILURE_SUFFIX}`:
      return {
        ...state,
        integrationsUpdated: false,
        result: 'UPDATE_FAILURE'
      };
    case `${UPDATE_INTEGRATION_SMS}${Action.BEGIN_SUFFIX}`:
      return {
        ...state,
        integrationsUpdated: false,
        result: 'BEGINNING',
      };
    case `${UPDATE_INTEGRATION_SMS}${Action.SUCCESS_SUFFIX}`:
      return {
        ...state,
        integrationsUpdated: true,
        result: 'UPDATE_SUCCESS',
      };
    case `${UPDATE_INTEGRATION_SMS}${Action.FAILURE_SUFFIX}`:
      return {
        ...state,
        integrationsUpdated: false,
        result: 'UPDATE_FAILURE'
      };
    default:
      return state;
  }
};

export default integrationReducer;
