import React, { useState, useEffect } from 'react';

import WidgetCommon from '../Common/widgetCommon'
import { withStyles } from '@material-ui/core';
import { getDisplayName } from '../../../util/deviceHelpers';
import { limitString } from '../../../util/tableUtils';
import { useMediaQuery } from 'react-responsive';
import WidgetAlert from '../Common/WidgetAlert';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';

const styles = (theme) => ({});

const alertLimit = 3;

const DeviceAlerts = (props) => {
  const { isLoading, insights, devices } = props;
  const [graphData, setGraphData] = useState([]);
  const [open, setOpen] = useState(false);
  const [limit, setLimit] = useState(alertLimit);

  const isMobile = useMediaQuery({ query: '(max-width: 625px)' });

  useEffect(() => {
    if (devices && insights) {
      const currentDate = new Date((Date.now()) + ((new Date()).getTimezoneOffset() * 60 * 1000));
      const data = [];
      const insightMap = {};
      for (const insight of insights) {
        if (!(insight.device_id in insightMap)) {
          insightMap[insight.device_id] = 0;
        }
        insightMap[insight.device_id] += 1;
      }

      let idCount = 0;
      for (let i = 0; i < devices.length; i++) {
        const device = devices[i];
        if (device.startup_data & 1 === 1) {
          data.push({ id: "spypoint_issue", deviceName: device.display_name, title: "Spypoint not Connected", icon: WarningIcon, id: idCount });
          idCount++;
        }
        if (!(device.device_id in insightMap) || insightMap[device.device_id] === 0) {
          data.push({ id: "no_insights", deviceName: device.display_name, title: "No Insights", icon: InfoIcon, id: idCount });
          idCount++;
        }
        if (!device.last_seen || ((currentDate - (new Date(device.last_seen))) / (1000 * 60 * 60 * 24)) > 7) {
          data.push({ id: "no_checkin", deviceName: device.display_name, title: "No Communication in Last Week", icon: WarningIcon, id: idCount });
          idCount++;
        }
      }

      let different = false;
      const prevData = JSON.parse(localStorage.getItem("deviceAlerts") || "[]" );

      if (prevData.length !== data.length) {
        different = true;
      } else {
        for (let i = 0; i < data.length; i++) {
          if (prevData[i].id !== data[i].id || prevData[i].deviceName !== data[i].deviceName) {
            different = true;
            break;
          }
        }
      }

      if (different || !graphData.modified) {
        setGraphData({ alerts: data, modified: false });
        localStorage.setItem("deviceAlerts", JSON.stringify(data));
      }
    }
  }, [insights, devices])

  useEffect(() => {
    if (graphData?.alerts) {
      if (graphData.alerts.length > 0) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }
  }, [graphData])

  const handleClose = () => {
    setOpen(false);
  }

  const handleShowMore = () => {
    if (graphData?.alerts.length > limit) {
      setLimit(limit + 10);
    } else {
      setLimit(alertLimit);
    }
  }

  return (
    <div id="device-alerts">
      <WidgetCommon title="Device Alerts" isLoading={isLoading} hasNoData={graphData?.alerts?.length === 0} hideIfNoData={true} hasTooMuchData={false} tooMuchDataString="" dynamicHeight={true}>
        {graphData?.alerts?.slice(0, limit).map((data) => (<WidgetAlert title={data.title} items={data.deviceName} AlertIcon={data.icon} id={data.id} graphData={graphData} setGraphData={setGraphData} />))}
        {
          graphData?.alerts?.length > alertLimit ?
            <Button color="primary" style={{ paddingTop: '10px', paddingBottom: '0px' }} onClick={handleShowMore}>
              {
                graphData?.alerts?.length > limit ? "Show More" : "Show Less"
              }
            </Button>
            : null
        }

      </WidgetCommon>
      <Snackbar open={open} anchorOrigin={{ vertical: "top", horizontal: "center" }} autoHideDuration={5000}>
        <Alert
          variant="filled"
          onClose={handleClose}
          severity="info"
          action={
            <div>
              <Button color="inherit" size="small" href="#device-alerts">
                Show
              </Button>
              <Button color="inherit" size="small" onClick={handleClose}>
                Close
              </Button>
            </div>
          }
          onClick={handleClose}
        >
          You have {graphData?.alerts?.length} device alerts.
        </Alert>
      </Snackbar>
    </div>
  )
}

export default withStyles(styles)(DeviceAlerts);