import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import { fade } from '@material-ui/core/styles';

import StickyFooter from '../Footer/StickyFooter';
import MarketplaceGrid from '../Marketplace/marketplaceGrid';

// Style imports
import { successColor, whiteColor, grayColor, hexToRgb } from '../../style/style';
import HomePageWrapper from '../Common/PageWrappers/HomePageWrapper';
import { useLazyLoadSelector } from '../../util/redux';
import { fetchMarketplaceModels } from '../../actions/marketplaceActions';

const styles = (theme) => ({
  cardColor: {
    background: 'linear-gradient(60deg, #0093C5, #1195C9)',
    color: 'white',
  },
  toolbar: theme.mixins.toolbar,
  root: {
    height: '100%',
  },
  details: {
    display: 'flex',
  },
  avatar: {
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
  locationText: {
    paddingLeft: '15px',
  },
  buttonProperty: {
    position: 'absolute',
    top: '50%',
  },
  mapCard: {
    minHeight: '70vh',
  },
  uiProgess: {
    position: 'fixed',
    zIndex: '1000',
    height: '31px',
    width: '31px',
    left: '50%',
    top: '35%',
  },
  progess: {
    position: 'absolute',
  },
  successText: {
    color: successColor[0],
  },
  // ARROW SIZE ON CHART CARDS
  upArrowCardCategory: {
    width: '16px',
    height: '16px',
  },
  // 'last 24 hours' AND 'triggered 1 hour ago' INFO AT THE BOTTOM OF THE CARDS
  stats: {
    color: grayColor[0],
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '22px',
    '& svg': {
      top: '4px',
      width: '16px',
      height: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      top: '4px',
      fontSize: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
  },
  cardName: {
    color: 'black',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
  },
  cardDescription: {
    color: grayColor[0],
    margin: '0',
    fontSize: '12px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
  },
  cardDetail: {
    color: grayColor[0],
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
  },
  // HEADING TEXT ON TOP 4 CARDS 'Insights received' etc
  cardCategory: {
    color: grayColor[0],
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
  },
  // SMALL SUB-HEADING ON TABLE CARDS 'whos has their eyes open?'
  cardCategoryWhite: {
    color: 'rgba(' + hexToRgb(whiteColor) + ',.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  // INFORMATION OR HEADINGS ON CARDS (USE THESE!!)
  cardTitle: {
    color: grayColor[2],
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: grayColor[1],
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  // SAME AS ABOVE BUT FOR WHITE TEXT (ALSO USE THESE FOR HEADINGS ON COLORED CARDS)
  cardTitleWhite: {
    color: whiteColor,
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: grayColor[1],
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const Marketplace = ({ classes, setTab }) => {

  useLazyLoadSelector((state) => state.marketplace.marketplaceModels, fetchMarketplaceModels, [], false);

  return (
    <HomePageWrapper>
      <MarketplaceGrid classes={classes} />
    </HomePageWrapper>);
};

export default withStyles(styles)(Marketplace);
