import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CreateIcon from '@material-ui/icons/Create';
import Box from '@material-ui/core/Box';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@material-ui/core';
import { updateModel } from '../../actions/deviceActions';


const StyledIcon = withStyles({
  root: {
    color: '#BBBABA',
  },
})(CreateIcon);

export default function FormDialog(props) {
  const { modelState } = props;
  const [updatedState, setUpdatedState] = useState(modelState);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    dispatch(updateModel(updatedState));
    setOpen(false);
  };

  const handleChange = (e) => {
    setUpdatedState({
      ...updatedState,
      [e.target.name]: e.target.value,
    });
  };

  if (modelState === undefined) {
    return <></>;
  }

  return (
    <div>
      {' '}
      <IconButton
        variant="outlined"
        size="small"
        onClick={handleClickOpen}
        startIcon={<StyledIcon />}
      >
        <EditIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {'Editing ' + modelState.alg_name}
        </DialogTitle>
        <DialogContent>
          <Box m={1}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Model Name"
              name="alg_name"
              defaultValue={modelState.alg_name}
              onChange={handleChange}
              fullWidth
            />
          </Box>
          <Box m={1}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Description"
              name="alg_description"
              defaultValue={modelState.alg_description}
              onChange={handleChange}
              type="email"
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
