import React, { useEffect, useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

const styles = (theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2)
  },
});

const BasicModalNoButton = (props) => {
  const { classes, fullWidth, title, content, confirmText, confirmAction = () => {}, cancelAction = () => {}, noCancel, passedOpen } = props;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(passedOpen);
  }, [passedOpen])

  const handleConfirm = () => {
    confirmAction();
    setOpen(false);
  }

  const handleCancel = () => {
    cancelAction();
    setOpen(false);
  }

  return (
    <Dialog className={classes.modal} fullWidth={fullWidth} open={open} onClose={noCancel ? handleConfirm : handleCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {content}
      </DialogContent>
      <DialogActions className={classes.actions}>
        {!noCancel && 
          <Button color='primary' onClick={handleCancel}>
            Cancel
          </Button>
        }
        <Button color='primary' onClick={handleConfirm}>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(BasicModalNoButton);
