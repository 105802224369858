import {
  FETCH_USER_INSIGHTS_BEGIN,
  FETCH_USER_INSIGHTS_SUCCESS,
  FETCH_USER_INSIGHTS_FAILURE,
  FETCH_USER_INSIGHT_AGGREGATES_BEGIN,
  FETCH_USER_INSIGHT_AGGREGATES_FAILURE,
  FETCH_USER_INSIGHT_AGGREGATES_SUCCESS,
  FETCH_USER_INSIGHTS_GRAPH_BEGIN,
  FETCH_USER_INSIGHTS_GRAPH_SUCCESS,
  FETCH_USER_INSIGHTS_GRAPH_FAILURE,
  UPDATE_USER_CONFIRM_BEGIN,
  UPDATE_USER_CONFIRM_SUCCESS,
  UPDATE_USER_CONFIRM_FAILURE,
  FETCH_INSIGHT_IMAGE_BEGIN,
  FETCH_INSIGHT_IMAGE_SUCCESS,
  FETCH_INSIGHT_IMAGE_FAILURE,
  CREATE_BULK_UPDATE_BEGIN,
  CREATE_BULK_UPDATE_SUCCESS,
  CREATE_BULK_UPDATE_FAILURE,
  FETCH_BULK_UPDATE_STATUS_BEGIN,
  FETCH_BULK_UPDATE_STATUS_SUCCESS,
  FETCH_BULK_UPDATE_STATUS_FAILURE,
} from '../actions/userInsightsActions';
import { getImageKey } from '../util/photo';

const initialState = {
  userInsightsData: [],
  userAggregateData: [],
  bulkUpdateStatusData: [],
  bulkId: null,
  userNotificationsData: [],
  userInsightsGraphData: {},
  bulkUpdateProcessingLoading: false,
  bulkUpdateCreationLoading: false,
  userInsightInfoLoading: false,
  userAggregateInfoLoading: false,
  insightImages: {},
  result: '',
};

const userInsightsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_INSIGHTS_BEGIN:
      return {
        ...state,
        userInsightInfoLoading: true,
        result: 'BEGINNING',
      };
    case FETCH_USER_INSIGHTS_SUCCESS:
      const photosMap = action.payload.photosMap;
      return {
        ...state,
        userInsightsData: action.payload.insights.map((insight) => {
          const photoKey = `${insight['insight_id']}_${insight['device_id']}_${insight['table']}`;
          return {
            device_id: insight['device_id'],
            device_name: insight['device_name'],
            display_name: insight['display_name'],
            confidence: insight['confidence'],
            time_stamp: insight['time_stamp'],
            file_ctime: insight['file_ctime'],
            photoUrl: photoKey in photosMap ? photosMap[photoKey]?.path : null,
            is_from_lora: insight['is_from_lora'],
            group_confidence: insight['group_confidence'],
            userConfirmed: insight['userConfirmed'],
            insight_id: insight['insight_id'],
            latitude: insight['lat'],
            longitude: insight['long'],
            comms_source: insight['comms_source'],
            class_name: insight['class_name'],
            class_id: insight['class_id'],
            alg_id: insight['alg_id'],
            table: insight['table'],
            insight_status: insight['insightStatus'],
          };
        }),
        userInsightInfoLoading: false,
        result: 'SUCCESS',
      };
    case FETCH_USER_INSIGHTS_FAILURE:
      return {
        ...state,
        result: 'FAILURE',
        userInsightInfoLoading: false,
      };
    case FETCH_USER_INSIGHT_AGGREGATES_BEGIN:
      return {
        ...state,
        userAggregateInfoLoading: true,
        result: 'BEGINNING',
      };
    case FETCH_USER_INSIGHT_AGGREGATES_SUCCESS:
      return {
        ...state,
        userAggregateData: action.payload.aggregates,
        userAggregateInfoLoading: false,
        result: 'SUCCESS',
      };
    case FETCH_USER_INSIGHT_AGGREGATES_FAILURE:
      return {
        ...state,
        result: 'FAILURE',
        userAggregateInfoLoading: false,
      };
    case FETCH_USER_INSIGHTS_GRAPH_BEGIN:
      return {
        ...state,
        result: 'BEGINNING',
      };
    case FETCH_USER_INSIGHTS_GRAPH_SUCCESS:
      return {
        ...state,
        userInsightsGraphData: action.payload.response.data.message,
        result: 'SUCCESS',
      };
    case FETCH_USER_INSIGHTS_GRAPH_FAILURE:
      return {
        ...state,
        result: 'FAILURE',
      };
    case UPDATE_USER_CONFIRM_BEGIN:
      return {
        ...state,
        result: 'BEGINNING',
      };
    case UPDATE_USER_CONFIRM_SUCCESS:
      return {
        ...state,
        result: 'SUCCESS',
      };
    case UPDATE_USER_CONFIRM_FAILURE:
      return {
        ...state,
        result: 'FAILURE',
      };

    case FETCH_INSIGHT_IMAGE_BEGIN:
      return {
        ...state,
        result: 'BEGINNING',
      };
    case FETCH_INSIGHT_IMAGE_SUCCESS:
      const payload = action.payload;
      const imageKey = getImageKey(payload.deviceId, payload.insightId, payload.algId, payload.className, payload.table);
      const dataBuffer = payload.dataBuffer;
      if (!state.insightImages[imageKey]) {
        state.insightImages[imageKey] = {};
      }
      return {
        ...state,
        insightImages: {
          ...state.insightImages,
          [imageKey]: {
            ...state.insightImages[imageKey],
            dataBuffer
          }
        },
        result: 'SUCCESS',
      };
    case FETCH_INSIGHT_IMAGE_FAILURE:
      return {
        ...state,
        result: 'FAILURE',
      };
    case CREATE_BULK_UPDATE_BEGIN:
      return {
        ...state,
        bulkUpdateCreationLoading: true,
        result: 'BEGINNING',
      };
    case CREATE_BULK_UPDATE_SUCCESS:
      const bulkId = action.payload.bulkId;
      return {
        ...state,
        bulkId: bulkId,
        bulkUpdateProcessingLoading: true,
        bulkUpdateCreationLoading: false,
        result: 'SUCCESS',
      };
    case CREATE_BULK_UPDATE_FAILURE:
      return {
        ...state,
        result: 'FAILURE',
      };
    case FETCH_BULK_UPDATE_STATUS_BEGIN:
      return {
        ...state,
        result: 'BEGINNING',
      };
    case FETCH_BULK_UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        bulkUpdateStatusData: action.payload,
        result: 'SUCCESS',
      };
    case FETCH_BULK_UPDATE_STATUS_FAILURE:
      return {
        ...state,
        result: 'FAILURE',
      };
    default:
      return state;
  }
};

export default userInsightsReducer;
