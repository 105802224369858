import React, { useState } from 'react';
import {
  withStyles,
  IconButton,
  TextField
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { isPossiblePhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';

const styles = (theme) => ({
  listItem: {
    padding: 0,
    margin: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: 0
  },
  container: {
    padding: 0,
    margin: 0,
    display: "flex",
    flexDirection: "column",
    minWidth: 0
  },
  icon: {
    marginLeft: "20px",
    flexShrink: 0
  },
  textLimit: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  }
});

const PHONE_NUMBER_LIMIT = 2;

const SMSList = (props) => {
  const { classes, phoneNumbers, setPhoneNumbers, snackbarMessageController} = props;
  const [newPhoneNumber, setNewPhoneNumber] = useState("");

  const handleDelete = (phoneNumber) => {
    const newPhoneNumbers = phoneNumbers.filter((value) => value !== phoneNumber);
    setPhoneNumbers(newPhoneNumbers);
  };

  const getSMSComponents = () => {
    const components = [];
    for (const phoneNumber of phoneNumbers) {
      components.push(
        <div className={classes.listItem} key={phoneNumber}>
          <p className={classes.textLimit}>{formatPhoneNumberIntl(phoneNumber)}</p>
          <IconButton className={classes.icon} onClick={() => handleDelete(phoneNumber)}>
            <DeleteIcon/>
          </IconButton>
        </div>
      );
    }
    components.push(
      <div className={classes.listItem} key="new_phone">
        <TextField 
            fullWidth
            margin="dense"
            type="tel"
            name="newPhoneNumber"
            disabled={phoneNumbers.length >= PHONE_NUMBER_LIMIT}
            variant="outlined"
            label={(phoneNumbers.length >= PHONE_NUMBER_LIMIT) ? `Maximum ${PHONE_NUMBER_LIMIT} phone numbers` : 'Add phone number'}
            value={newPhoneNumber} 
            onChange={(e) => {
              setNewPhoneNumber(e.target.value);
            }}
        />
        <IconButton className={classes.icon} disabled={phoneNumbers.length >= PHONE_NUMBER_LIMIT} onClick={handleAdd}>
          <AddIcon/>
        </IconButton>
      </div>
    );
    return components;
  }

  const handleAdd = () => {
    const tempPhoneNumber = formatPhoneNumberIntl(newPhoneNumber.startsWith('+') ? newPhoneNumber : `+1${newPhoneNumber}`);
    const tempPhoneNumbers = phoneNumbers.map((phoneNumber) => formatPhoneNumberIntl(phoneNumber));
    if (tempPhoneNumbers.includes(tempPhoneNumber)) {
      snackbarMessageController("Phone Number has already been added.");
      return;
    } 
    if (!isPossiblePhoneNumber(tempPhoneNumber)) {
      snackbarMessageController("Not a valid phone number.");
      return;
    }
    const newPhoneNumbers = [...tempPhoneNumbers, tempPhoneNumber];
    setPhoneNumbers(newPhoneNumbers);
    setNewPhoneNumber("");
  };

  return (
    <div className={classes.container}>
      {getSMSComponents()}
    </div>
  );
};

export default withStyles(styles)(SMSList);
