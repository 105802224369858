import React, { useState, useEffect } from 'react';

import { ResponsiveBar } from '@nivo/bar'
import WidgetCommon from '../Common/widgetCommon'
import { withStyles } from '@material-ui/core';
import { getDisplayName } from '../../../util/deviceHelpers';
import { limitString } from '../../../util/tableUtils';
import { useMediaQuery } from 'react-responsive';

const styles = (theme) => ({});

const InsightsByClass = (props) => {
  const { isLoading, insights, devices } = props;
  const [graphData, setGraphData] = useState([]);
  const [graphType, setGraphType] = useState('linear');

  const isMobile = useMediaQuery({ query: '(max-width: 625px)'});
  
  useEffect(() => {
    if (devices && insights) {
      const dataMap = {};
      for (const device of devices) {
        dataMap[device.device_id] = {
          device_name: limitString(getDisplayName(device), 13),
          insight_count: 0,
          image_count: device.images_processed || device?.summary?.image_count || 0,
        }
      }
      for (const insight of insights) {
        if (insight.device_id && insight.device_id in dataMap) {
          dataMap[insight.device_id].insight_count += 1;
        }
      }
      for (const deviceId of Object.keys(dataMap)) {
        if (dataMap[deviceId].insight_count === 0) {
          delete dataMap[deviceId];
        }
      }
      setGraphData(Object.values(dataMap));
    }
  }, [insights, devices])
  
  return (
    <WidgetCommon 
      title="Insights / Images per Device" 
      isLoading={isLoading} hasNoData={graphData.length === 0} 
      hasTooMuchData={graphData.length >= (isMobile ? 20 : 25)} 
      tooMuchDataString="Too many devices. Please filter to fewer devices."
      info="Devices with older firmware did not record image counts. This can cause the data to show more insights than images."
      graphSwitch={true} setGraphType={setGraphType} graphType={graphType}
    >
      <ResponsiveBar
        data={graphData}
        keys={["insight_count", "image_count"]}
        indexBy="device_name"
        margin={{ top: isMobile ? 10: 50, right: isMobile ? 10: 130, bottom: 100, left: isMobile ? 40 : 60 }}
        padding={0.3}
        valueScale={{ type: graphType }}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'paired' }}
        groupMode="grouped"
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 90,
            legend: !isMobile && 'Devices',
            legendPosition: 'middle',
            legendOffset: 90,
            truncateTickAt: 0
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: !isMobile && 'Count',
            legendPosition: 'middle',
            legendOffset: -40,
            truncateTickAt: 0
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        role="application"
        ariaLabel="Insights vs Images"
        legends={!isMobile ? [
          {
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                  {
                      on: 'hover',
                      style: {
                          itemOpacity: 1
                      }
                  }
              ]
          }
      ] : []}
      />
    </WidgetCommon>
  )
}

export default withStyles(styles)(InsightsByClass);