import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import moment from 'moment-timezone';
import Button from '@material-ui/core/Button';
import { Page } from '../../util/constants';

const styles = (theme) => ({
  button: {
    margin: '0px 5px'
  },
  text: {
    margin: 0
  },
  warning: {
    margin: 0,
    color: '#f44336'
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    padding: 8,
    flexDirection: 'column'
  },
  insightContainer: {
    padding: '10px 0',
    margin: 0
  },
  insightTitle: {
    marginBottom: "5px"
  }
});

const DeviceView = (props) => {
  const { classes, deviceId, insightCount, latitude, longitude, deviceName, mostRecentClassName, mostRecentTimestamp, setTab, location, lastSeen, displayWarning } = props;

  const count = insightCount ? insightCount : 0;
  const hasRecent = mostRecentClassName && mostRecentTimestamp;

  return (
    <div className={classes.container}>
      <h1>{`${deviceName}`}</h1>
      {!!location && (
        <p className={classes.text}>
          <b>Location: </b> {location}
        </p>
      )}
      {!!latitude && !!longitude && (
        <p className={classes.text}>
          <b>Lat/Long: </b> {`${latitude}, ${longitude}`}
        </p>
      )}
      {!!lastSeen && (
        <p className={classes.text}>
          <b>Last Seen (UTC): </b> {`${moment(lastSeen)
                      .tz('UTC')
                      .format('MMM Do YYYY, h:mm a')}`}
        </p>
      )}
      {!!displayWarning && (
        <p className={classes.warning}>
          <b>Last Check In: </b> {Math.floor(((new Date()).valueOf() - (new Date(lastSeen)).valueOf()) / (1000 * 60 * 60 * 24))} days ago
        </p>
      )}
      <p className={classes.text}>
        <b>Insight Count: </b> {count}
      </p>

      {hasRecent &&
        <div className={classes.insightContainer}>
          <h3 className={classes.insightTitle}>Most Recent Insight</h3>
          <p className={classes.text}>
            <b>Class Name: </b> {mostRecentClassName}
          </p>
          <p className={classes.text}>
            <b>Timestamp (UTC): </b>
            {
              moment(mostRecentTimestamp)
                .tz('UTC')
                .format('MMM Do YYYY, h:mm a')
            }{' '}
          </p>
        </div>
      }

      {count > 0 &&

        <Button
          className={classes.button}
          variant="contained"
          onClick={() => {
            setTab([
              Page.USER_INSIGHTS,
              { filters: [{ "id": "device_name", "value" : deviceName }] },
            ]);
          }}
          color='primary'
        >
          See All Insights
        </Button>
      }
    </div>
  );
};

export default withStyles(styles)(DeviceView);
