import { Action } from "../actions/actionsShared";
import { 
  FETCH_USER_FEATURES_PREFIX,
  UPDATE_USER_FEATURE_PREFIX
} from "../actions/featureActions";


const initialState = {
  features: [],
  loadingFeatures: false
};

const featuresReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_USER_FEATURES_PREFIX}${Action.BEGIN_SUFFIX}`:
      return {
        ...state,
        loadingFeatures: true,
        result: 'BEGINNING',
      };
    case `${FETCH_USER_FEATURES_PREFIX}${Action.SUCCESS_SUFFIX}`:
      const message = action.payload.data.data.message;
      return {
        ...state,
        features: message,
        loadingFeatures: false,
        result: 'SUCCESS',
      };
    case `${FETCH_USER_FEATURES_PREFIX}${Action.FAILURE_SUFFIX}`:
      return {
        ...state,
        loadingFeatures: false,
        result: 'FAILURE'
      }
    case `${UPDATE_USER_FEATURE_PREFIX}${Action.BEGIN_SUFFIX}`:
      return {
        ...state,
        result: 'BEGINNING',
      };
    case `${UPDATE_USER_FEATURE_PREFIX}${Action.SUCCESS_SUFFIX}`:
      const updateData = action.payload.passedInfo.data;
      state.features.forEach((feature) => {
        if (feature.id === updateData.featureId) {
          feature.enabled = updateData.enabled;
        }
      });
      return {
        ...state,
        result: 'UPDATE_SUCCESS',
      };
    case `${UPDATE_USER_FEATURE_PREFIX}${Action.FAILURE_SUFFIX}`:
      return {
        ...state,
        result: 'UPDATE_FAILURE'
      };
    default:
      return state;
  }
};

export default featuresReducer;
