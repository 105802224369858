import React, { useState } from 'react';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Button, createTheme } from '@mui/material';
import { Paper, Popover, makeStyles } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import { Checkbox, ListItem, ListItemButton, ListItemIcon, ListItemText, List } from '@mui/material';

const theme_bp = createTheme({
    breakpoints: {
        values: {
            sm: 600
        },
    },
});


const useStyles = makeStyles({
    filterRow: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    formControl: {
        margin: 5,
        width: "100%"
    },
    resize: {
        [theme_bp.breakpoints.down('sm')]: {
            fontSize: 10,
        },
    },
    dates: {
        display: "flex"
    },
    space: {
        width: 10
    }
});

export default function CustomizeDashboard(props) {
    const { selectedWidgets, setSelectedWidgets } = props;
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const isMobile = useMediaQuery({ query: '(max-width: 625px)' });

    const classes = useStyles();

    const toggleArrow = () => {
        return dropdownOpen ? (<ArrowDownIcon />) : (<ArrowRightIcon />);
    };

    const handleToggle = (widget, index) => {
        const newWidgets = [...selectedWidgets];
        if (widget.id === newWidgets[index].id && index < newWidgets.length) {
            newWidgets[index].display = !newWidgets[index].display;
            localStorage.setItem("selectedWidgets", JSON.stringify(newWidgets));
            setSelectedWidgets(newWidgets)
        }
    };

    const handleClose = () => {
        setDropdownOpen(false);
        setAnchorEl(null);
    }

    const handleClick = (event) => {
        setDropdownOpen(true);
        setAnchorEl(event.currentTarget);
    }

    return (
        <>
            <Button onClick={handleClick} variant="outlined" className={classes.filterButton}>
                <span>
                    Customize Dashboard
                </span>
                {toggleArrow()}
            </Button>
            <Popover
                open={dropdownOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Paper variant="elevation" >
                    <Box sx={{ padding: 2 }}>
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            {selectedWidgets.map((widget, index) => {
                                const labelId = `checkbox-list-label-${widget.id}`;
                                return (
                                    <ListItem
                                        key={widget.id}
                                        disablePadding
                                    >
                                        <ListItemButton role={undefined} onClick={() => handleToggle(widget, index)} dense>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={widget.display}
                                                    tabIndex={-1}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={labelId} primary={widget.title} />
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Box>
                </Paper>
            </Popover>
        </>
    );
}