import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import StickyFooter from '../Footer/StickyFooter';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Snackbar } from '@material-ui/core';

import { fetchModelClasses, getImproveModelImages } from '../../actions/modelActions';

import withStyles from '@material-ui/core/styles/withStyles';
import { defaultFont, infoColor } from '../../style/style';
import moment from 'moment-timezone';
import ModelModal from '../Modal/modelModal';
import DeviceDrawer from '../Drawer/models/deviceDrawer';
import GeneralSwipeableView from '../Common/ContentContainers/GeneralSwipeableView';
import ModelView from '../Model/ModelView';

import clsx from 'clsx';

import '../../util/typedefs';
import { useLazyLoadSelector } from '../../util/redux';

import { createTheme } from '@mui/material';
import HomePageWrapper from '../Common/PageWrappers/HomePageWrapper';
import { fetchDeployedModel, fetchDevice, fetchModels } from '../../actions/deviceActions';
import { getDisplayName } from '../../util/deviceHelpers';
import { isObjectEmpty } from '../../util/isObjectEmpty'

const theme_bp = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 1050,
      lg: 1200,
      xl: 1536,
    },
  },
});

const styles = (theme) => ({
  devicesCard: {
    width: '20vw',
  },
  toolbar: theme.mixins.toolbar,
  root: {},
  details: {
    display: 'flex',
  },
  cardColor: {
    background: 'linear-gradient(60deg, #0093C5, #1195C9)',
    color: 'white',
  },
  avatar: {
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
  locationText: {
    paddingLeft: '15px',
  },
  buttonProperty: {
    position: 'absolute',
    top: '50%',
  },
  uiProgess: {
    position: 'fixed',
    zIndex: '1000',
    height: '31px',
    width: '31px',
    left: '50%',
    top: '35%',
  },
  progess: {
    position: 'absolute',
  },
  uploadButton: {
    marginLeft: '8px',
    margin: theme.spacing(1),
  },
  customError: {
    color: 'red',
    fontSize: '0.8rem',
    marginTop: 10,
  },
  submitButton: {
    marginTop: '10px',
  },
  table: {
    marginBottom: '0',
    // width: '42vw',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse',
    objectFit: 'contain',
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    '&, &$tableCell': {
      fontSize: '1em',
    },
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    fontSize: '0.8125rem',
  },

  tableHeadRow: {
    height: '56px',
    color: '#00ACC1',
    display: 'table-row',
    outline: 'none',
    verticalAlign: 'middle',
  },
  tableBodyRow: {
    height: '48px',
    color: 'inherit',
    display: 'table-row',
    outline: 'none',
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
  actions: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  bigTable: {
    [theme_bp.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  smallTable: {
    [theme_bp.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  nullResult: {
    align: 'right',
    fontSize: 28,
    lineHeight: '150%',
  },
});


const Models = (props) => {
  const { classes, ...rest } = props;
  // Index of the currently selected model
  const [selectedModel, setSelectedModel] = useState(-1);

  const marketplaceDataClasses = useSelector(
    (state) => state.model.modelClasses
  );

  const dispatch = useDispatch();

  const deviceModelData = useLazyLoadSelector((state) => state.device.deviceModelData, fetchModels, [], false);
  const deployedModelData = useLazyLoadSelector((state) => state.device.deployedModelData, fetchDeployedModel, [], false);
  const deviceData = useLazyLoadSelector((state) => state.device.deviceData, fetchDevice, [], false);
  
  /**
   * Toggles whether the model with the given index is selected or not.
   *
   * @param {DeviceModelData} model The model data
   * @param {number} index The index of the model
   */
  const handleClick = (model, index) => {
    setSelectedModel((selectedModel) => (selectedModel === index ? -1 : index));
    dispatch(getImproveModelImages(model.alg_id));
  };

  /** @type {object} maps the ID of the device to the name of the device */
  const deviceStore = {};
  deviceData.forEach((device) => {
    deviceStore[device.device_id] = getDisplayName(device);
  });

  const [failDownload, setFailDownload] = useState(false);

  const handleSnackbarClose = () => {
    setFailDownload('');
  };

  const nullData = (in_str) => {
    return [(<TableRow>
      <TableCell/>
      <TableCell className={classes.nullResult}>{in_str}</TableCell>
      </TableRow>)];
  }

  function add_models() {
    
    if (isObjectEmpty(deviceModelData)) {
      return nullData("No models are available; models can be found in the model marketplace");
    } else {
      return deviceModelData.map((model, index) => {
        let modelDateCreated = moment(model.date_created)
          .tz('UTC')
          .format('MMM Do YYYY');
        const marketPlaceModelClasses =
          marketplaceDataClasses == null
            ? null
            : marketplaceDataClasses[model['alg_id']];

        let modelDevicesList = [];
        for (let deployment of deployedModelData) {
          let name = deviceStore[deployment.device_id];
          if (
            name &&
            model.alg_id === deployment.alg_id &&
            !modelDevicesList.includes(name)
          ) {
            modelDevicesList.push(name);
          }
        }
        let modelDevices = modelDevicesList.join(', ');

        return (
          <React.Fragment key={model['alg_id']}>
            <TableRow
              hover
              selected={index === selectedModel}
              className={classes.tableBodyRow}
              onClick={() => handleClick(model, index)}
            >
              <TableCell
                className={
                  classes.tableCell + ' ' + classes.tableHeadCell
                }
              >
                {model['alg_name']}
              </TableCell>

              <TableCell
                className={
                  classes.tableCell + ' ' + classes.tableHeadCell
                }
              >
                {marketPlaceModelClasses &&
                  marketPlaceModelClasses
                    .map((el) => el.original_label)
                    .join(', ')}
              </TableCell>

              {/* <TableCell
                className={
                  classes.tableCell + ' ' + classes.tableHeadCell
                }
              >
                <DeviceDrawer
                  algId={model.alg_id}
                />
              </TableCell> */}
            </TableRow>
            <TableRow>
              <TableCell
                style={
                  index === selectedModel
                    ? {}
                    : { paddingBottom: 0, paddingTop: 0 }
                }
                colSpan={6}
              >
                <Collapse
                  in={index === selectedModel}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box sx={{ margin: 1 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={3}>
                        <b>Model ID</b> <br />
                        {model.alg_id}
                      </Grid>
                      <Grid item xs={3}>
                        <b>Description</b> <br />
                        {model.alg_description}
                      </Grid>
                      <Grid item xs={3}>
                        <b>Data Type</b> <br />
                        {model.alg_type}
                      </Grid>
                      <Grid item xs={3}>
                        <b>Date Created (UTC)</b> <br />
                        {modelDateCreated}
                      </Grid>
                      {/* <Grid item xs={3}>
                        <b>Devices</b> <br />
                        {modelDevices}
                      </Grid> */}
                      <Grid item xs={3}>
                        <b>Author</b> <br />
                        {model.author}
                      </Grid>
                      <Grid item xs={3}>
                        <b>Framework</b> <br />
                        {model.framework}
                      </Grid>
                      <Grid item xs={12}>
                        <ModelModal modelState={model} />
                      </Grid>
                    </Grid>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </React.Fragment>
        );
      })
    }
  }

  function add_models_mobile () {
    if (isObjectEmpty(deviceModelData)) {
      return nullData("No models are available; models can be found in the model marketplace");
    } else {
      return deviceModelData.map((dm) => (
        <ModelView
        model={dm}
        deployedModelData={deployedModelData}
        marketplaceDataClasses={marketplaceDataClasses}
        deviceStore={deviceStore}
        />
      ))
    }
  }

  useEffect(() => {
    const algSet = new Set([...deviceModelData.map((data) => data.alg_id)]);
    for (const algId of [...algSet]) {
      if (!(algId in marketplaceDataClasses)) {
        dispatch(fetchModelClasses(algId));
      }
    }
  }, [deviceModelData])

  return (
    <HomePageWrapper>
      <h1>My Models</h1>
      <br />

      {/* MODELS TABLE  */}
      <div className={`${classes.setWidth} ${classes.bigTable}`}>
        <Card width={1}>
          <CardContent>
            <Table
              {...rest}
              align="center"
              className={clsx(classes.root, classes, classes.table)}
            >
              {/* The head of the Table */}
              <TableHead className={classes[infoColor[0] + 'TableHeader']}>
                <TableRow className={classes.tableHeadRow}>
                  <TableCell
                    className={classes.tableCell + ' ' + classes.tableHeadCell}
                    color="red"
                  >
                    {'Model Name'}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + ' ' + classes.tableHeadCell}
                  >
                    {'Animals'}
                  </TableCell>
                  {/* <TableCell
                    className={classes.tableCell + ' ' + classes.tableHeadCell}
                  >
                    {'Deploy to Device'}
                  </TableCell> */}
                  {/* <TableCell
                    className={classes.tableCell + ' ' + classes.tableHeadCell}
                  >
                    {'Download'}
                  </TableCell> */}
                </TableRow>
              </TableHead>

              {/* The body of the table */}
              <TableBody>
                {add_models()}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </div>
      <div className={classes.smallTable}>
        <GeneralSwipeableView
          content={add_models_mobile()}
        />
      </div>
      <br />
      <Snackbar
        open={failDownload}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={`Download for model ${failDownload} failed. Please contact support.`}
      />
      <br />
    </HomePageWrapper>
  );
};

export default withStyles(styles)(Models);
