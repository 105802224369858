import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add'; 
import RemoveIcon from '@mui/icons-material/Remove'; 
import { Box, Button, ListItemButton, createTheme } from '@mui/material';
import { Checkbox, FormControl, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Popover, Select, TextField, Typography, makeStyles } from '@material-ui/core';

const theme_bp = createTheme({
  breakpoints: {
    values: {
      sm: 600
    },
  },
});


const useStyles = makeStyles({
  filterRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  formControl: {
    margin: 5,
    [theme_bp.breakpoints.down('sm')]: {
      minWidth: 100,
    },
    [theme_bp.breakpoints.up('sm')]: {
      minWidth: 250,
    }
  },
  resize: {
    [theme_bp.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
});

export default function CustomTableHeaderSelector(props) {
  const { headers, updateHeaders } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);


  const classes = useStyles();

  const handleClick = (event) => {
    setDropdownOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setDropdownOpen(false);
    setAnchorEl(null);
  };

  const handleToggle = (header, index) => {
    const newHeaders = [...headers];
    if (header.id === newHeaders[index].id && index < newHeaders.length) {
      newHeaders[index].display = !newHeaders[index].display;
      updateHeaders(newHeaders)
    }
  };

  return (
    <>
      <IconButton onClick={(event) => handleClick(event)}>
        <MoreVertIcon/>
      </IconButton>
      <Popover
        open={dropdownOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Paper variant="elevation" >
          <Box sx={{ padding: 2 }}>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {headers.map((header, index) => {
                if (header.id === "image") return null;
                const labelId = `checkbox-list-label-${header.id}`;
                return (
                  <ListItem
                    key={header.id}
                    disablePadding
                  >
                    <ListItemButton role={undefined} onClick={() => handleToggle(header, index)} dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={header.display}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={header.label} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Paper>
      </Popover>
    </>
  );
}