import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  withStyles,
  Grid,
  Chip,
  Button,
  CircularProgress,
  TablePagination,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';

import { fetchCardInfo, fetchCharges } from '../../actions/userActions.js';
import EditCardInfo from './EditCardInfo.js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// testing stripe key to use 4242 4242 4242 4242 as a valid
// const promise = loadStripe(
//   'pk_test_51JT7FpCWRolrQQkGu5BwRH4l9f6w4i9cUAwieWo4XkG6KK1gLDSBnOypNC2kJFrFFFw2OW9aYszRQhAlPrjMBAdo00eZMdTndz'
// );

// real stripe key!
const promise = loadStripe(
  'pk_live_51JT7FpCWRolrQQkGhWBD3jlnehacIJFr5IyfHBs64jIBWYkVsQkHHreL0etoArGOdwfp6y5YyNM7ag9UVP06iC9y00mGAJzWgu'
);

const styles = (theme) => ({
  cardInfoHeader: {
    [theme.breakpoints.up('md')]: {
      marginLeft: '25%'
    },
  },
  cardInfoCard: {
    [theme.breakpoints.up('md')]: {
      width: '50%'
    },
    borderRadius: '10px',
    margin: `${theme.spacing(1)}px auto`,
  },
  cardInfo: {
    fontSize: '0.875rem',
    color: '#808080',
  },
  cardBox: {
    width: '100px',
    height: '50px',
    backgroundColor: '#132979',
    borderRadius: '5px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  cardInfoButton: {
    marginBottom: theme.spacing(2),
    float: 'right',
    color: '#0093C5',
  },
  invoicesCard: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    borderRadius: '20px',
  },
  invoiceDescription: {
    marginBottom: theme.spacing(1),
  },
  invoiceDate: {
    fontSize: '0.875rem',
    color: '#808080',
  },
  invoiceStatus: {
    fontSize: '0.875rem',
    marginTop: theme.spacing(1),
  },
  invoiceAmount: {
    fontSize: '1.25rem',
    color: '#0093C5',
    marginLeft: 'auto',
  },
  invoiceDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '95%',
    color: '#D7D7D7',
    height: '0.5px',
  },
  pagination: {
    marginTop: theme.spacing(3),
  },
});

const AccountBilling = (props) => {
  const { classes, openSnackbar } = props;

  const [cardInfoOpen, setCardInfoOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const dispatch = useDispatch();

  const cardInfo = useSelector((state) => state.user.cardInfo);
  const charges = useSelector((state) => state.user.charges);
  const fetchCardInfoProgress = useSelector(
    (state) => state.user.fetchCardInfoProgress
  );
  const fetchChargesProgress = useSelector(
    (state) => state.user.fetchChargesProgress
  );

  const fetchCardInfoLoading = fetchCardInfoProgress === 'BEGINNING';
  const fetchChargesLoading = fetchChargesProgress === 'BEGINNING';

  useEffect(() => {
    dispatch(fetchCardInfo());
    dispatch(fetchCharges());
    // eslint-disable-next-line
  }, [props.history]);

  // ensure the page number is valid
  useEffect(() => {
    let dataLength = charges.length;
    if (page * rowsPerPage >= dataLength) {
      let newPage = Math.max(Math.floor((dataLength - 1) / rowsPerPage), 0);
      setPage(newPage);
    }
  }, [charges, rowsPerPage, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getInvoiceStatusChip = (status) => {
    switch (status) {
      case 'succeeded':
        return (
          <Chip
            label="Succeeded"
            color="primary"
            className={classes.invoiceStatus}
          />
        );
      case 'pending':
        return (
          <Chip
            label="Pending"
            color="secondary"
            className={classes.invoiceStatus}
          />
        );
      case 'failed':
        return (
          <Chip
            label="Failed"
            color="secondary"
            className={classes.invoiceStatus}
          />
        );
      default:
        return <Chip label="Unknown" className={classes.invoiceStatus} />;
    }
  };

  let editCardInfo = (
    <Elements stripe={promise}>
      <EditCardInfo
        open={cardInfoOpen}
        setOpen={setCardInfoOpen}
        openSnackbar={openSnackbar}
      />
    </Elements>
  );

  let cardInfoCard;
  if (fetchCardInfoLoading) {
    cardInfoCard = (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress size={60} />
      </div>
    );
  } else {
    let cardContent;
    if (cardInfo) {
      cardContent = (
        <Box>
          <Typography variant="caption" className={classes.cardInfo}>
            {cardInfo.brand} ending in {cardInfo.last4}
          </Typography>
          <br />
          <Typography variant="caption" className={classes.cardInfo}>
            Expiring {cardInfo.exp_month}/{cardInfo.exp_year}
          </Typography>
          <div className={classes.cardBox} />
          {!cardInfoOpen && (
            <Button
              className={classes.cardInfoButton}
              variant="outlined"
              startIcon={<CreateIcon />}
              onClick={() => setCardInfoOpen(true)}
            >
              Edit Card Information
            </Button>
          )}
          {editCardInfo}
        </Box>
      );
    } else {
      cardContent = (
        <Box>
          <Typography variant="body1" style={{ marginBottom: '1rem' }}>
            No card on file
          </Typography>
          {!cardInfoOpen && (
            <Button
              className={classes.cardInfoButton}
              variant="outlined"
              startIcon={<CreateIcon />}
              onClick={() => setCardInfoOpen(true)}
            >
              Add Card Information
            </Button>
          )}
          {editCardInfo}
        </Box>
      );
    }

    cardInfoCard = (
      <Card className={classes.cardInfoCard}>
        <CardContent>{cardContent}</CardContent>
      </Card>
    );
  }

  let invoiceCard;
  if (fetchChargesLoading) {
    invoiceCard = (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress size={60} />
      </div>
    );
  } else {
    let invoiceContent;
    if (charges && charges.length > 0) {
      invoiceContent = (
        <div>
          {charges
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((charge, index) => (
              <React.Fragment>
                <Grid
                  key={charge.id}
                  container
                  justify={'space-between'}
                  spacing={3}
                >
                  <Grid item sm={9}>
                    <Typography className={classes.invoiceDescription}>
                      {/* put charge.description but up to the character '*' */}
                      {charge.description.substring(
                        0,
                        charge.description.indexOf('*')
                      )}
                    </Typography>
                    <Typography className={classes.invoiceDate}>
                      {moment.unix(charge.created).format('MMM Do, YYYY')}{' '}
                    </Typography>
                    {getInvoiceStatusChip(charge.status)}
                  </Grid>
                  <Grid item sm={3} style={{ display: 'flex' }}>
                    <Typography
                      className={classes.invoiceAmount}
                      style={{ alignSelf: 'flex-end' }}
                    >
                      ${(charge.amount / 100).toFixed(2)}{' '}
                      {charge.currency.toUpperCase()}{' '}
                    </Typography>
                  </Grid>
                </Grid>
                {index + page * rowsPerPage !== charges.length - 1 &&
                  index !== rowsPerPage - 1 && (
                    <hr className={classes.invoiceDivider} />
                  )}
              </React.Fragment>
            ))}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={charges.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            className={classes.pagination}
          />
        </div>
      );
    } else {
      invoiceContent = (
        <Typography variant="body1">No charges found</Typography>
      );
    }

    invoiceCard = (
      <Card className={classes.invoicesCard}>
        <CardContent>{invoiceContent}</CardContent>
      </Card>
    );
  }

  return (
    <Box>
      <Typography className={classes.cardInfoHeader} variant="h6">
        Card Information
      </Typography>
      {cardInfoCard}
      <Typography variant="h6">Billing Invoices</Typography>
      {invoiceCard}
    </Box>
  );
};

export default withStyles(styles)(AccountBilling);
