import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '../Card/card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import ModelInfoModal from './modelInfoModal';
import { useDispatch } from 'react-redux';
import { addModelToUserAccount, fetchMarketplaceModels } from '../../actions/marketplaceActions';
import { fetchModels } from '../../actions/deviceActions';

const styles = (theme) => ({
  disabled: {
    cursor: 'not-allowed',
  },
  overlay: {
    position: 'absolute',
    top: '3px',
    right: '3px',
    opacity: 0.8,
  },
  roott: {
    paddingTop: '0px',
    paddingLeft: '0px',
    paddingRight: '0px',
    height: '100%',
    paddingBottom: 0,
  },
});

const MarketplaceCard = ({
  model
}) => {
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    await addModelToUserAccount(model.alg_id);
    dispatch(fetchMarketplaceModels());
    dispatch(fetchModels());
  }

  return (
    <Card>
      <CardContent>
        <ModelInfoModal
          width="30vw"
          model={model}
          onSubmit={handleSubmit}
        />
      </CardContent>
      <CardActions>
        {model?.inAccount ? (
          <Button disabled="true" size="small" color="primary">
            Model Already Present in Account
          </Button>
        ) : (
          <Button
            size="small"
            color="primary"
            onClick={handleSubmit}
          >
            Add Model to Account
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default withStyles(styles)(MarketplaceCard);
