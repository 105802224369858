import React, { useState, useEffect } from 'react';

import WidgetCommon from '../Common/widgetCommon'
import { Card, withStyles } from '@material-ui/core';
import { getDisplayName } from '../../../util/deviceHelpers';
import { limitString } from '../../../util/tableUtils';
import { useMediaQuery } from 'react-responsive';
import CardBody from '../../Card/cardBody';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
  card: {
    backgroundColor: "#f2cb96",
    marginTop: 10
  },
  cardBody: {
    display: 'flex',
    alignItems: 'center',
    opacity: 0.8
  },
  icon: {
    marginRight: 10,
    opacity: 0.8
  },
  closeButton: {
    position: 'absolute',
    right: '10px'
  }
});

const WidgetAlert = (props) => {
  const { classes, title, items, AlertIcon, setGraphData, graphData, id } = props;

  const handleClose = () => {
    setGraphData({alerts: graphData.alerts.filter((item) => item.id !== id), modified: true});
  }

  return (
    <Card className={classes.card}>
      <CardBody className={classes.cardBody}>
        <AlertIcon className={classes.icon} />
        <div>
          <b>
            {`${title}: `}
          </b>
          <br />
          {items}
        </div>
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </CardBody>
    </Card>
  )
}

export default withStyles(styles)(WidgetAlert);