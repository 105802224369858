import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Card from '../Card/card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import { grayColor } from '../../style/style';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.up('md')]: {
      width: '80%',
      maxWidth: '60vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      maxWidth: '90vw',
    },
    position: 'absolute',
    backgroundColor: 'white',
    boxShadow: theme.shadows[5],
    borderRadius: 10,
    padding: theme.spacing(2, 4, 3),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  image: {
    display: 'flex',
    margin: 'auto',
    borderRadius: 20,
    width: '30vw',
  },
  caption: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  cardName: {
    color: 'black',
    margin: '0',
    fontSize: '20px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
  },
  cardNameLink: {
    color: 'black',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
    cursor: 'pointer',
  },
  cardDescription: {
    color: grayColor[0],
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    paddingTop: '2px',
    marginBottom: '0',
  },
  cardDetail: {
    color: grayColor[0],
    margin: '0',
    fontSize: '16px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
  },
  outerCardName: {
    color: 'black',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
  },
  outerCardDescription: {
    color: grayColor[0],
    margin: '0',
    fontSize: '12px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
  },
  outerCardDetail: {
    color: grayColor[0],
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
  },
  circle: {
    height: '100%',
    size: '100%',
    width: '100%',
  },
  disabled: {
    cursor: 'not-allowed',
    flexGrow: '1',
  },
  download: {
    flexGrow: '1',
  },
  topBox: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '0',
    marginBottom: '0',
  },
  rightBox: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'end',
    alignItems: 'flex-end',
  },
  leftBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  bottomRight: {
    position: 'relative',
    display: 'inline-flex',
  },
  overlay: {
    margin: '0',
    marginTop: '10px',
    paddingTop: '0',
    marginBottom: '0',
    opacity: 0.8,
    position: 'relative',
  },
  overlaid: {
    position: 'absolute',
    top: '3px',
    right: '3px',
    opacity: 0.8,
  },
  root: {
    height: '100%',
    paddingTop: '0px',
    marginTop: '0px',
    boxShadow: theme.shadows[0],
  },
  roott: { height: 200 },
}));

export default function ModelInfoModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const {
    model,
    onSubmit
  } = props;

  const handleSubmit = () => {
    onSubmit();
    setOpen(false);
  }

  const body = (
    <Card className={classes.paper} style={modalStyle}>
      <CardMedia
        component="img"
        alt="Model Image"
        sx={{ objectFit: "contain" }}
        image={model?.image_path}
        title="Model Image"
      />
      <CardContent className={classes.root}>
        <div className={classes.topBox}>
          <div className={classes.leftBox}>
            <p className={classes.cardName}>{model?.name}</p>
            <p className={classes.cardDescription}>{model?.description}</p>
          </div>
          <div className={classes.rightBox}>
            {model?.inAccount ? (
              <Button
                disabled="true"
                className={classes.download}
                color="primary"
              >
                Model Already Present in Account
              </Button>
            ) : (
              <Button
                onClick={handleSubmit}
                className={classes.download}
                color="primary"
              >
                Add Model to Account
              </Button>
            )}
          </div>
        </div>
        <Divider variant="middle" />
        <div className={classes.topBox}>
          <div className={classes.leftBox}>
            <p className={classes.cardDetail}>
              <b>Name: </b>
              {model?.alg_name}
            </p>
            <p className={classes.cardDetail}>
              <b>Author: </b>
              {model?.author}
            </p>
            <p className={classes.cardDetail}>
              <b>Framework: </b>
              {model?.framework}
            </p>
          </div>
        </div>
      </CardContent>
    </Card>
  );

  return (
    <Card className={classes.root}>
      <CardActionArea className={classes.root} onClick={() => setOpen(true)}>
        <CardMedia
          component="img"
          alt="Model Image"
          sx={{ objectFit: "contain" }}
          image={model?.image_path}
          title="Model Image"
        />
        <CardContent className={classes.root}>
          <p className={classes.outerCardName}>{model?.name}</p>
          <p className={classes.outerCardDetail}>Name: {model?.alg_name}</p>
          <p className={classes.outerCardDetail}>Author: {model?.author}</p>
          <p className={classes.outerCardDetail}>Framework: {model?.framework}</p>
          <p className={classes.outerCardDescription}>Species: {model?.classes?.join(", ")}</p>
          <p className={classes.outerCardDescription}>{model?.description}</p>
        </CardContent>
      </CardActionArea>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </Card>
  );
}
