import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar } from "@material-ui/core";
import { Link as Scroll } from 'react-scroll';

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: "Nunito",
    fontSize: "1.5vw",
    margin: '10px 10px',
    marginTop: '5%',
  },
  appbar: {
    background: "white",
    width: '100%',
  },
  list: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

  },
  sections: {
    textDecoration: 'none',
    color: 'black',
    fontFamily: 'Nunito',
    fontWeight: 'bold',
  },
}));

export default function StickyHeader() {
  const classes = useStyles();
  const [scrollY, setScrollY] = useState({ position: "relative" });

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const heightVh = document.querySelector("#mainheader").offsetHeight / 100;

      if (window.scrollY > heightVh * 100) {
        setScrollY({ position: "fixed", top: "80px" });
      } else {
        setScrollY({ position: "relative" });
      }
    });
    return () => {
      window.removeEventListener("scroll");
    };
  }, []);

  return (
    <>
      {console.log(scrollY)}
      <div className={classes.root} id="stickyheader">
        <AppBar className={classes.appbar} style={scrollY} elevation={1}>
          <Toolbar className={classes.appbarWrapper}>
            <ul className={classes.list} style={{ listStyleType: "none" }}>
              <li className={classes.root}>
                <Scroll to="info-card" smooth={true}>
                <a className={classes.sections} href="#product" data-id="product" data-is-active="true">
                  Product
                </a>
                    </Scroll>
              </li>
              <li className={classes.root}>
                <Scroll to="hardware-card" smooth={true}>
                <a className={classes.sections} href="#sentinel" data-id="sentinel" data-is-active="false">
                  Sentinel
                </a>
                    </Scroll>
              </li>
              <li className={classes.root}>
                <Scroll to="dashboard-card" smooth={true}>
                <a className={classes.sections} href="#dashboard" data-id="dashboard" data-is-active="false">
                  Dashboard
                </a>
                    </Scroll>
              </li>
              <li className={classes.root}>
                <Scroll to="marketplace-card" smooth={true}>
                <a className={classes.sections} href="#marketplace" data-id="marketplace" data-is-active="false">
                  Marketplace
                </a>
                    </Scroll>
              </li>
              <li className={classes.root}>
                <Scroll to="custom-card" smooth={true}>
                <a className={classes.sections} href="#custom" data-id="custom" data-is-active="false">
                  Custom Models
                </a>
                    </Scroll>
              </li>
            </ul>
          </Toolbar>
        </AppBar>
      </div>
    </>
  );
}