import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import { TextField, Typography } from '@material-ui/core';
import { clearDevice } from '../../../actions/internalActions';

export default function DeviceClearModal(props) {
  const { deviceId, open, handleClose } = props;
  const [confirmedDeviceId, setConfirmedDeviceId] = useState("");
  const [confirmation, setConfirmation] = useState("");
  const [errorMessage, setErrorMessage] = useState("");


  const handleSave = async () => {
    setErrorMessage("");
    if (deviceId && confirmedDeviceId && parseInt(confirmedDeviceId) === parseInt(deviceId) && confirmation === "I Understand") {
      await clearDevice(deviceId);
      handleClose();
    } else {
      setErrorMessage("Confirmation information incorrect. Please double check confirmation and try again.")
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Clear Device: {deviceId}</DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <Typography variant='body1'>Clearing a device will archive all insights, remove all device links, and will remove user specific information from the device. Please enter the device id and a confirmation message below to confirm you want to clear the device.</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            rows={4}
            inputProps={{
              maxLength: 100
            }}
            margin="dense"
            label="Device ID"
            name="deviceId"
            value={confirmedDeviceId}
            onChange={(event) => setConfirmedDeviceId(event.currentTarget.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            rows={4}
            inputProps={{
              maxLength: 100
            }}
            margin="dense"
            label={`Enter "I Understand"`}
            name="confirmation"
            value={confirmation}
            onChange={(event) => setConfirmation(event.currentTarget.value)}
          />
        </Grid>
        {errorMessage && errorMessage.length > 0 &&
        <Grid item xs={12}>
        <Typography variant='body1' color="textSecondary">{errorMessage}</Typography>
      </Grid>}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSave}>
          Clear
        </Button>
      </DialogActions>
    </Dialog>
  );
}
