import { Action } from "../actions/actionsShared";
import { FETCH_DEVICE_UPDATES_PREFIX, FETCH_USER_ALG_RELEASES_PREFIX } from "../actions/deviceUpdateActions";


const initialState = {
  updates: [],
  allReleases: []
};

const deviceUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_DEVICE_UPDATES_PREFIX}${Action.BEGIN_SUFFIX}`:
      return {
        ...state,
        result: 'BEGINNING',
      };
    case `${FETCH_DEVICE_UPDATES_PREFIX}${Action.SUCCESS_SUFFIX}`:
      return {
        ...state,
        updates: action.payload.data.data.message,
        result: 'SUCCESS',
      };
    case `${FETCH_DEVICE_UPDATES_PREFIX}${Action.FAILURE_SUFFIX}`:
      return {
        ...state,
        result: 'FAILURE'
      }
    case `${FETCH_USER_ALG_RELEASES_PREFIX}${Action.BEGIN_SUFFIX}`:
      return {
        ...state,
        result: 'BEGINNING',
      };
    case `${FETCH_USER_ALG_RELEASES_PREFIX}${Action.SUCCESS_SUFFIX}`:
      return {
        ...state,
        allReleases: action.payload.data.data.message,
        result: 'SUCCESS',
      };
    case `${FETCH_USER_ALG_RELEASES_PREFIX}${Action.FAILURE_SUFFIX}`:
      return {
        ...state,
        result: 'FAILURE'
      }
    default:
      return state;
  }
};

export default deviceUpdateReducer;
