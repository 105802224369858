import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createGroup } from '../../actions/groupActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Add from '@material-ui/icons/Add';

/**
 * @typedef {object} NewGroupData
 * @property {string} group_name  - The name to give to the new group
 * @property {string} description - The description to give to the new group
 */

const NewGroupModal = (props) => {
  // By default, none of the fields have errors
  const requiredFields = ['group_name'];

  // By default, all the parameters of the group is empty
  const defaultGroup = {
    group_name: '',
    deployment_description: '',
  };

  // By default, there are initially no errors on the form
  const defaultErrorState = new Map(
    requiredFields.map((field) => [field, false])
  );

  // True if the modal is currently shown, false otherwise
  const [open, setOpen] = React.useState(false);

  // Current value of fields in the form
  const [group, setGroup] = React.useState(defaultGroup);

  // Maps field name to whether it has an error
  const [errorState, setErrorState] = React.useState(defaultErrorState);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    console.log('group submitted through frontend ', group);

    // Maps names of required fields to whether the field is empty
    const error = new Map(
      requiredFields.map((field) => [field, !group[field]])
    );

    setErrorState(error);

    // if there is an error, cease! leaves dialog open and sets error message
    if (requiredFields.some((fields) => error.get(fields))) {
      return;
    }

    // submit group to database, clear form close
    dispatch(createGroup(group));
    setGroup(defaultGroup);
    setOpen(false);
  };

  const handleChange = (e) => {
    setGroup({
      ...group,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <React.Fragment>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        <Add /> &nbsp; Add New Group
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <h2 style={{ margin: 0 }}>Create New Group</h2>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                margin="dense"
                id="group_name"
                label="Group Name"
                name="group_name"
                onChange={handleChange}
                error={errorState.get('group_name')}
                value={group.group_name}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="description"
                label="Description"
                name="deployment_description"
                onChange={handleChange}
                value={group.deployment_description}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleSubmit} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default NewGroupModal;
