import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Login from './pages/Login';
import Signup from './pages/Signup';
import Home from './pages/Home';
import Demo from './pages/Demo';
import Landing from './pages/Landing';
import ResetPassword from './pages/ResetPassword';

function App() {
  return (
    <div>
      <Router>
        <div>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/resetpassword" component={ResetPassword} />
            <Route exact path="/signup" component={Signup} />
            <Route exact path="/demo" component={Demo} />
            <Route exact path="/landing" component={Landing} />
          </Switch>
        </div>
      </Router>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
