import React, { useState, useEffect } from 'react';

import WidgetCommon from '../Common/widgetCommon'
import { withStyles } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import { grayColor } from '../../../style/style';

const styles = (theme) => ({});

const TotalInsights = (props) => {
    const { isLoading, insights } = props;
    const [totalInsights, setTotalInsights] = useState(null);

    useEffect(() => {
        if (insights) {
            setTotalInsights(insights.length);
        }
    }, [insights])

    return (
        <WidgetCommon height="17vh" maxHeight="200px" title="Total Insights" isLoading={isLoading} hasNoData={!totalInsights} >
            <br></br>
            <Typography variant="h2" style={{color: grayColor[2]}}>
                {totalInsights}
            </Typography>
        </WidgetCommon>
    )
}

export default withStyles(styles)(TotalInsights);