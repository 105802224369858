import axios from 'axios';
import {server} from '../actions/backend-url';

export class Action {

  static BEGIN_SUFFIX = "_BEGIN";
  static SUCCESS_SUFFIX = "_SUCCESS";
  static FAILURE_SUFFIX = "_FAILURE";

  static #formattedDispatch = (prefix, suffix, data = undefined, passedInfo = undefined) => {
    return {
      type: `${prefix}${suffix}`,
      payload: data ? { data } : undefined,
      passedInfo: passedInfo ? { data: passedInfo } : undefined,
    };
  }

  static GET = (prefix, url, auth = true) => {
    return (dispatch) => {
      dispatch(Action.#formattedDispatch(prefix, Action.BEGIN_SUFFIX));
      if (auth) {
        const authToken = localStorage.getItem('AuthToken');
        axios.defaults.headers.common = { Authorization: `${authToken}` };
      }
      return axios
        .get(`${server}${url}`)
        .then((response) => {
          dispatch(Action.#formattedDispatch(prefix, Action.SUCCESS_SUFFIX, response));
          return response;
        })
        .catch((error) => dispatch(Action.#formattedDispatch(prefix, Action.FAILURE_SUFFIX, error)));
    };
  };

  static DELETE = (prefix, url, auth = true) => {
    return (dispatch) => {
      dispatch(Action.#formattedDispatch(prefix, Action.BEGIN_SUFFIX));
      if (auth) {
        const authToken = localStorage.getItem('AuthToken');
        axios.defaults.headers.common = { Authorization: `${authToken}` };
      }
      return axios
        .delete(`${server}${url}`)
        .then((response) => {
          dispatch(Action.#formattedDispatch(prefix, Action.SUCCESS_SUFFIX, response));
          return response;
        })
        .catch((error) => dispatch(Action.#formattedDispatch(prefix, Action.FAILURE_SUFFIX, error)));
    };
  };

  static POST = (prefix, url, data, auth = true) => {
    return (dispatch) => {
      dispatch(Action.#formattedDispatch(prefix, Action.BEGIN_SUFFIX));
      if (auth) {
        const authToken = localStorage.getItem('AuthToken');
        axios.defaults.headers.common = { Authorization: `${authToken}` };
      }
      return axios
        .post(`${server}${url}`, data)
        .then((response) => {
          dispatch(Action.#formattedDispatch(prefix, Action.SUCCESS_SUFFIX, response));
          return response;
        })
        .catch((error) => dispatch(Action.#formattedDispatch(prefix, Action.FAILURE_SUFFIX, error)));
    };
  };

  static PUT = (prefix, url, data, auth = true, passedInfo = undefined) => {
    return (dispatch) => {
      dispatch(Action.#formattedDispatch(prefix, Action.BEGIN_SUFFIX));
      if (auth) {
        const authToken = localStorage.getItem('AuthToken');
        axios.defaults.headers.common = { Authorization: `${authToken}` };
      }
      return axios
        .put(`${server}${url}`, data)
        .then((response) => {
          dispatch(Action.#formattedDispatch(prefix, Action.SUCCESS_SUFFIX, response, passedInfo));
          return response;
        })
        .catch((error) => dispatch(Action.#formattedDispatch(prefix, Action.FAILURE_SUFFIX, error)));
    };
  };
}