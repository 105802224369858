import React, { useState, useEffect, useRef } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useSelector, useDispatch } from 'react-redux';

import { updateCardInfo } from '../../actions/userActions.js';

import '../../style/paymentFormStyle.css';
import { CircularProgress } from '@material-ui/core';

export default function EditCardInfo(props) {
  const { open, setOpen, openSnackbar } = props;

  const stripe = useStripe();
  const elements = useElements();

  const [disabled, setDisabled] = useState(true);

  const updateCardInfoProgress = useSelector(
    (state) => state.user.updateCardInfoProgress
  );
  const updateCardInfoError = useSelector(
    (state) => state.user.updateCardInfoError
  );
  const processing = updateCardInfoProgress === 'BEGINNING';

  // set to true when component mounts, used in useEffect() to check if this is 
  // when the component is first mounting
  const isMounted = useRef(false);

  const dispatch = useDispatch();

  const cardStyle = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#32325d',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  const onChange = (event) => {
    setDisabled(event.empty);
  };

  const handleCancel = (event) => {
    setOpen(false);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const payload = await stripe.createSource(
      elements.getElement(CardElement),
      { type: 'card', usage: 'reusable' }
    );

    if (payload.error) {
      openSnackbar(`Failed to set card info (${payload.error.message})`, 'error');
    } else {
      dispatch(updateCardInfo(payload));
    }
  };

  // send a message when the progress changes, but don't send when the component first mounts
  // if we didn't have the component first mounts check, then it would show the snackbar again from the previous update
  useEffect(() => {
    if(isMounted.current) {
      if (updateCardInfoProgress === 'FAILURE') {
        openSnackbar(`Failed to set card info (${updateCardInfoError})`, 'error');
      } else if (updateCardInfoProgress === 'SUCCESS') {
        setOpen(false);
        openSnackbar('Card updated successfully!', 'success');
      }
    }
    else {
      isMounted.current = true;
    }
    // eslint-disable-next-line
  }, [updateCardInfoProgress]);

  if (!open) {
    return null;
  }

  return (
    <form id="payment-form">
      <CardElement id="card-element" options={cardStyle} onChange={onChange} />
      <button disabled={processing} id="cancel" onClick={handleCancel}>
        <span id="button-text">
          Cancel
        </span>
      </button>
      <button disabled={disabled} id="submit" onClick={handleSubmit}>
        <span id="button-text">
          Add card
        </span>
        {processing && (
          <CircularProgress size={30} style={{ position: 'absolute' }} />
        )}
      </button>
    </form>
  );
}
