import { HeaderDataTypes } from "./constants"

export const getFilterableHeaders = (headers) => {
  const allowedHeaders = [HeaderDataTypes.STRING, HeaderDataTypes.DATE, HeaderDataTypes.BUTTONS]
  return headers.filter((header) => allowedHeaders.includes(header.type));
}

export const filterData = (filters, data, actions) => {
  let filteredData = [...data];
  for (const filter of filters) {
    if (filter && filter.header && filter.header.type && filter.header.id && (filter.value || (filter.to && filter.from) || (filter.value === null && filter.header.id === "buttons"))) {
      switch(filter.header.type) {
        case (HeaderDataTypes.STRING): 
          filteredData = filteredData.filter((row) => (filter.header.restrict_to && !filter.header.restrict_to.includes(row.data_type)) || !!row[filter.header.id])
            .filter((row) => (filter.header.restrict_to && !filter.header.restrict_to.includes(row.data_type)) || row[filter.header.id].toLowerCase().includes(filter.value.toLowerCase())); 
          break;
        case (HeaderDataTypes.DATE): 
          filteredData = filteredData.filter((row) => (filter.header.restrict_to && !filter.header.restrict_to.includes(row.data_type)) || !!row[filter.header.id])
            .filter((row) => (filter.header.restrict_to && !filter.header.restrict_to.includes(row.data_type)) || (new Date(row[filter.header.id])) >= (new Date(filter.from)))
            .filter((row) => (filter.header.restrict_to && !filter.header.restrict_to.includes(row.data_type)) || (new Date(row[filter.header.id])) <= (new Date(filter.to))); 
          break;
        case (HeaderDataTypes.BUTTONS):
          if (actions){
            filteredData = filteredData.filter((row) => actions[row.id] === filter.value);
          } else {
            filteredData = filteredData.filter((row) => row.insight?.insight_status === filter.value || row.data_type === "device");
          }

          break;
      }
    }
  }
  return filteredData;
}

