import React from 'react';
import {
  List,
  withStyles,
  Divider
} from '@material-ui/core';

const styles = (theme) => ({
  list: {
    padding: 0,
    margin: 0
  },
});

const getItems = (items) => {
  const itemElements = [];
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    itemElements.push(item);
    if (i < items.length - 1) {
      itemElements.push((<Divider data-testid="item-list-divider" key={`divider_${i}`}/>));
    }
  }
  return itemElements;
};

const ItemList = (props) => {
    const { classes, items } = props;
  return (
    <List className={classes.list}>
        {getItems(items)}
    </List>
  );
};

export default withStyles(styles)(ItemList);
