import axios from 'axios';
import {server} from '../actions/backend-url';


export const FETCH_USER_INFO_SQL_BEGIN = 'FETCH_USER_INFO_SQL_BEGIN';
export const FETCH_USER_INFO_SQL_SUCCESS = 'FETCH_USER_INFO_SQL_SUCCESS';
export const FETCH_USER_INFO_SQL_FAILURE = 'FETCH_USER_INFO_SQL_FAILURE';


export const fetchUserInfoSqlBegin = () => {
  return {
    type: FETCH_USER_INFO_SQL_BEGIN,
  };
};

export const fetchUserInfoSqlSuccess = (response) => {
  return {
    type: FETCH_USER_INFO_SQL_SUCCESS,
    payload: { response },
  };
};

export const fetchUserInfoSqlFailure = (error) => {
  return {
    type: FETCH_USER_INFO_SQL_FAILURE,
    payload: { error },
  };
};

export function fetchUserInfoSql(userIdSql) {
  return (dispatch) => {
    dispatch(fetchUserInfoSqlBegin());
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    return axios
      .get(
        `${server}/api/getUserInfo/${userIdSql}`
      )
      .then((response) => {
        dispatch(fetchUserInfoSqlSuccess(response));
        return response;
      })
      .catch((error) => dispatch(fetchUserInfoSqlFailure(error)));
  };
}
