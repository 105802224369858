import React, { useEffect, useState } from 'react';
import {
  ListItem,
  withStyles,
  ListItemText,
  Switch,
  Tooltip
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { useDispatch } from 'react-redux';
import { updateUserFeature } from '../../../actions/featureActions';


const styles = (theme) => ({
  listItem: {
    padding: theme.spacing(3),
    margin: 0
  },
  listItemText: {
    marginLeft: theme.spacing(1),
    fontSize: '1rem'
  },
  integrationSettingsContainer: {
    marginBottom: theme.spacing(3)
  },
  overallContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
    margin: 0,
    padding: 0
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center'
    
  },
  checkbox: {
    paddingLeft: 0
  },
  tooltip: {
    marginLeft: 0,
    paddingBottom: 0,
    cursor: 'pointer'
  }
});

const FeatureItem = (props) => {
  const { classes, featureId, title, enabled, instructions } = props;

  const [switchStatus, setSwitchStatus] = useState(enabled);

  const dispatch = useDispatch();


  const handleChange = () => {
    const newStatus = !switchStatus;
    dispatch(updateUserFeature(featureId, newStatus));
    setSwitchStatus(newStatus);
  };

  useEffect(() => {
    setSwitchStatus(enabled);
  }, [enabled])

  return (
    <ListItem className={classes.listItem}>
        <ListItemText
            primary={title}
            classes={{primary:classes.listItemText}}
        />
        <Switch checked={switchStatus} onChange={handleChange} color="primary" />
        <Tooltip
          title={instructions}
          className={classes.tooltip}
        >
          <InfoIcon />
        </Tooltip>
    </ListItem>
    );
};

export default withStyles(styles)(FeatureItem);
