import React, { useState, useEffect } from 'react';

import { ResponsiveScatterPlotCanvas } from '@nivo/scatterplot'
import WidgetCommon from '../Common/widgetCommon'
import { withStyles } from '@material-ui/core';
import { getDisplayName } from '../../../util/deviceHelpers';
import { limitString } from '../../../util/tableUtils';
import { useMediaQuery } from 'react-responsive';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);
const styles = (theme) => ({});

const InsightDelay = (props) => {
  const { isLoading, insights, devices } = props;
  const [graphData, setGraphData] = useState([]);
  const [graphType, setGraphType] = useState('linear');

  const isMobile = useMediaQuery({ query: '(max-width: 625px)' });

  useEffect(() => {
    if (devices && insights) {
      const dataMap = {};
      const graphData = [{ id: "delay_time", data: [] }, { id: "average_delay", data: [] }];

      for (const device of devices) {
        if (device.last_seen) {
          dataMap[device.device_id] = {
            device_name: limitString(getDisplayName(device), 13),
            average_delay_time: 0, // Initialize average delay time
            delay_times: [],
            insights_count: 0
          }
        }
      }

      for (const insight of insights) {
        if (insight.device_id && insight.device_id in dataMap) {
          const receivedTime = new Date(insight.time_stamp);
          const createdTime = new Date(insight.file_ctime);
          const delayHours = Math.floor((receivedTime - createdTime) / (1000 * 60 * 60));

          dataMap[insight.device_id].average_delay_time += delayHours;
          dataMap[insight.device_id].insights_count++;
          dataMap[insight.device_id].delay_times.push(delayHours);

          graphData[0].data.push({
            x: dataMap[insight.device_id].device_name,
            y: delayHours,
          })
        }
      }

      Object.values(dataMap).forEach(deviceData => {
        if (deviceData.delay_times.length > 0) {
          deviceData.average_delay_time = Math.round(deviceData.delay_times.reduce((a, b) => a + b, 0) / deviceData.delay_times.length);
        }
      });

      for (const device_id in dataMap) {
        graphData[1].data.push({
          x: dataMap[device_id].device_name,
          y: dataMap[device_id].average_delay_time,
        })
      }

      setGraphData(graphData);
    }
  }, [insights, devices])

  return (
    <WidgetCommon
      title="Insight Delay (Hours)"
      isLoading={isLoading} hasNoData={graphData.length === 0}
      hasTooMuchData={devices.length >= (isMobile ? 20 : 25)}
      tooMuchDataString="Too many devices. Please filter to fewer devices."
      info="Insight delay only changes when filtered by devices."
      graphSwitch={true}
      setGraphType={setGraphType}
      graphType={graphType}
    >
      <ResponsiveScatterPlotCanvas
        data={graphData}
        margin={{ top: isMobile ? 10: 50, right: isMobile ? 10: 130, bottom: 100, left: isMobile ? 40 : 80 }}
        xScale={{ type: 'point' }}
        yScale={{ type: graphType, min: 0, max: 'auto' }}
        colors={{ scheme: 'paired' }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 90,
          legend: isMobile ? '' : 'Device Name',
          legendPosition: 'middle',
          legendOffset: 90
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Delay Time (Hours)',
          legendPosition: 'middle',
          legendOffset: -60
        }}
        tooltip={({ node }) => (
          <>
            <strong>{`${node.data.x}: `}</strong>{node.data.y} hours
          </>
        )}
        labelTextColor={{
          from: 'color',
          modifiers: [
              [
                  'darker',
                  1.6
              ]
          ]
      }}
        role="application"
        ariaLabel="Scatter plot of Delay Time and Average Delay Time by Device"
        legends={!isMobile ? [
          {
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                  {
                      on: 'hover',
                      style: {
                          itemOpacity: 1
                      }
                  }
              ]
          }
      ] : []}
      />

    </WidgetCommon>
  )
}

export default withStyles(styles)(InsightDelay);