import React, { useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import HomePageWrapper from '../Common/PageWrappers/HomePageWrapper';
import { useLazyLoadSelector } from '../../util/redux';
import { fetchGroups } from '../../actions/groupActions';
import { fetchDevice } from '../../actions/deviceActions';
import DeviceTable from '../Device/deviceTable';
import { useSelector } from 'react-redux';
import { fetchDeviceUpdates, fetchUserAlgReleases } from '../../actions/deviceUpdateActions';
import DeviceUpdatesTable from '../DeviceUpdates/DeviceUpdatesTable';

const styles = (theme) => ({
  devicesCard: {
    width: '20vw',
  },
  toolbar: theme.mixins.toolbar,
  root: {},
  details: {
    display: 'flex',
  },
  cardColor: {
    background: 'linear-gradient(60deg, #0093C5, #1195C9)',
    color: 'white',
  },
  avatar: {
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
  locationText: {
    paddingLeft: '15px',
  },
  buttonProperty: {
    position: 'absolute',
    top: '50%',
  },
  uiProgess: {
    position: 'fixed',
    zIndex: '1000',
    height: '31px',
    width: '31px',
    left: '50%',
    top: '35%',
  },
  progess: {
    position: 'absolute',
  },
  uploadButton: {
    marginLeft: '8px',
    margin: theme.spacing(1),
  },
  customError: {
    color: 'red',
    fontSize: '0.8rem',
    marginTop: 10,
  },
  submitButton: {
    marginTop: '10px',
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // paddingTop: '2em',
  },
  // contentPadding: {
  //   padding: theme.spacing(3),
  // },
  tableContainers: {
    gap: '2em',
    flexWrap: 'wrap-reverse',
  },
  button: {
    '&:focus': {
      color: 'white',
      backgroundColor: '#0A94C7',
    },
    '&:hover': {
      color: 'white',
      backgroundColor: '#0A94C7',
    },
  },
});

const DeviceUpdates = (props) => {
  const { classes, ...rest } = props;

  useLazyLoadSelector((state) => state.group.groupData, fetchGroups, [], false);
  useLazyLoadSelector((state) => state.device.deviceData, fetchDevice, [], false);
  useLazyLoadSelector((state) => state.deviceUpdates.updates, fetchDeviceUpdates, [], false);
  useLazyLoadSelector((state) => state.deviceUpdates.allReleases, fetchUserAlgReleases, [], false);
  const loading = useSelector((state) => state.device.loadingDevices || false);

  return (
    <HomePageWrapper loading={loading}>
      <div>
        <div
          className={[classes.contentPadding, classes.sectionHeader].join(' ')}
        >
          <h1>Device Updates</h1>
        </div>
        <br />
        <DeviceUpdatesTable />
        <br />
      </div>
    </HomePageWrapper>
  );
};

export default withStyles(styles)(DeviceUpdates);
