import axios from "axios";
import { Action } from "./actionsShared";
import { server } from "./backend-url";
import { DEVICE_UPDATE_STATUS } from "../util/constants";

export const FETCH_DEVICE_UPDATES_PREFIX = "FETCH_DEVICE_UPDATES";
export const FETCH_USER_ALG_RELEASES_PREFIX = "FETCH_USER_ALG_RELEASES";

export const fetchDeviceUpdates = () => {
  const url = "/api/devices/update";
  return Action.GET(FETCH_DEVICE_UPDATES_PREFIX, url, true);
};

export const fetchUserAlgReleases = () => {
  const url = "/api/modelRelease/approved";
  return Action.GET(FETCH_USER_ALG_RELEASES_PREFIX, url, true);
};

export const createDeviceUpdate = async (updateObject) => {
  const authToken = localStorage.getItem('AuthToken');
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  try {
    await axios.post(`${server}/api/devices/update`, updateObject);
    return {
      status: true
    };
  } catch (e) {
    console.error(e);
    return {
      status: false,
      errorCode: e?.response?.status,
      errorMessage: e?.response?.data?.message
    };
  }
}

export const dropDeviceUpdate = async (updateId) => {
  const authToken = localStorage.getItem('AuthToken');
  axios.defaults.headers.common = { Authorization: `${authToken}` };
  try {
    await axios.put(`${server}/api/devices/update/${updateId}`, { status: DEVICE_UPDATE_STATUS.DROPPED });
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}