import { Action } from "./actionsShared";

export const FETCH_USER_INTEGRATIONS_PREFIX = "FETCH_USER_INTEGRATIONS";
export const CREATE_USER_INTEGRATION_PREFIX = "CREATE_USER_INTEGRATION";
export const DELETE_USER_INTEGRATION_PREFIX = "DELETE_USER_INTEGRATION";
export const UPDATE_INTEGRATION_NOTIFICATIONS = "UPDATE_INTEGRATION_NOTIFICATIONS";
export const UPDATE_INTEGRATION_DEVICE_GROUPS = "UPDATE_INTEGRATION_DEVICE_GROUPS";
export const UPDATE_INTEGRATION_EMAILS = "UPDATE_INTEGRATION_EMAILS";
export const UPDATE_INTEGRATION_SMS = "UPDATE_INTEGRATION_SMS";

export const fetchUserIntegrations = () => {
  const url = "/api/integrations";
  return Action.GET(FETCH_USER_INTEGRATIONS_PREFIX, url, true);
};

export const updateIntegrationNotifications = (platform, data) => {
  const url = `/api/integrations/${platform}/notifications`;
  return Action.PUT(UPDATE_INTEGRATION_NOTIFICATIONS, url, data, true);
};

export const updateIntegrationDeviceGroups = (platform, data) => {
  const url = `/api/integrations/${platform}/groups`;
  return Action.PUT(UPDATE_INTEGRATION_DEVICE_GROUPS, url, data, true);
};

export const updateIntegrationEmails = (data) => {
  const url = `/api/integrations/emails`;
  return Action.PUT(UPDATE_INTEGRATION_EMAILS, url, data, true);
};

export const updateIntegrationSMS = (data) => {
  const url = `/api/integrations/sms`;
  return Action.PUT(UPDATE_INTEGRATION_SMS, url, data, true);
};

export const createUserIntegration = (data) => {
  const url = "/api/integrations";
  return Action.POST(CREATE_USER_INTEGRATION_PREFIX, url, data, true);
};

export const deleteUserIntegration = (platform) => {
  const url = `/api/integrations/${platform}`;
  return Action.DELETE(DELETE_USER_INTEGRATION_PREFIX, url, true);
};