import axios from 'axios';
import { server } from '../actions/backend-url';
import { Action } from './actionsShared';

export const FETCH_DEVICE_BEGIN = 'FETCH_DEVICE_BEGIN';
export const FETCH_DEVICE_SUCCESS = 'FETCH_DEVICE_SUCCESS';
export const FETCH_DEVICE_FAILURE = 'FETCH_DEVICE_FAILURE';

//export const FETCH_DEVICE_MODEL_BEGIN = 'FETCH_DEVICE_MODEL_BEGIN';
//export const FETCH_DEVICE_MODEL_SUCCESS = 'FETCH_DEVICE_MODEL_SUCCESS';
//export const FETCH_DEVICE_MODEL_FAILURE = 'FETCH_DEVICE_MODEL_FAILURE';

export const FETCH_MODELS_BEGIN = 'FETCH_MODELS_BEGIN';
export const FETCH_MODELS_SUCCESS = 'FETCH_MODELS_SUCCESS';
export const FETCH_MODELS_FAILURE = 'FETCH_MODELS_FAILURE';

export const FETCH_DEPLOYED_MODEL_BEGIN = 'FETCH_DEPLOYED_MODEL_BEGIN';
export const FETCH_DEPLOYED_MODEL_SUCCESS = 'FETCH_DEPLOYED_MODEL_SUCCESS';
export const FETCH_DEPLOYED_MODEL_FAILURE = 'FETCH_DEPLOYED_MODEL_FAILURE';

export const UPDATE_DEVICE_BEGIN = 'UPDATE_DEVICE_BEGIN';
export const UPDATE_DEVICE_SUCCESS = 'UPDATE_DEVICE_SUCCESS';
export const UPDATE_DEVICE_FAILURE = 'UPDATE_DEVICE_FAILURE';

export const UPDATE_DEVICE_DISPLAY_PREFIX = 'UPDATE_DEVICE_DISPLAY';

export const CREATE_DEVICE_BEGIN = 'CREATE_DEVICE_BEGIN';
export const CREATE_DEVICE_SUCCESS = 'CREATE_DEVICE_SUCCESS';
export const CREATE_DEVICE_FAILURE = 'CREATE_DEVICE_FAILURE';

export const UPDATE_MODEL_BEGIN = 'UPDATE_MODEL_BEGIN';
export const UPDATE_MODEL_SUCCESS = 'UPDATE_MODEL_SUCCESS';
export const UPDATE_MODEL_FAILURE = 'UPDATE_MODEL_FAILURE';

export const UPDATE_MODEL_NOTIFS_BEGIN = 'UPDATE_MODEL_NOTIFS_BEGIN';
export const UPDATE_MODEL_NOTIFS_SUCCESS = 'UPDATE_MODEL_NOTIFS_SUCCESS';
export const UPDATE_MODEL_NOTIFS_FAILURE = 'UPDATE_MODEL_NOTIFS_FAILURE';

export const DELETE_MODEL_BEGIN = 'DELETE_MODEL_BEGIN';
export const DELETE_MODEL_SUCCESS = 'DELETE_MODEL_SUCCESS';
export const DELETE_MODEL_FAILURE = 'DELETE_MODEL_FAILURE';

export const UPDATE_DEPLOY_BEGIN = 'UPDATE_DEPLOY_BEGIN';
export const UPDATE_DEPLOY_SUCCESS = 'UPDATE_DEPLOY_SUCCESS';
export const UPDATE_DEPLOY_FAILURE = 'UPDATE_DEPLOY_FAILURE';

export const UPDATE_DEPLOY_GROUP_DEVICES_BEGIN =
  'UPDATE_DEPLOY_GROUP_DEVICES_BEGIN';
export const UPDATE_DEPLOY_GROUP_DEVICES_SUCCESS =
  'UPDATE_DEPLOY_GROUP_DEVICES_SUCCESS';
export const UPDATE_DEPLOY_GROUP_DEVICES_FAILURE =
  'UPDATE_DEPLOY_GROUP_DEVICES_FAILURE';

export const FETCH_MODEL_NOTIFS_BEGIN = 'FETCH_MODEL_NOTIFS_BEGIN';
export const FETCH_MODEL_NOTIFS_SUCCESS = 'FETCH_MODEL_NOTIFS_SUCCESS';
export const FETCH_MODEL_NOTIFS_FAILURE = 'FETCH_MODEL_NOTIFS_FAILURE';

//Device
export const fetchDeviceBegin = () => {
  return {
    type: FETCH_DEVICE_BEGIN,
  };
};

export const fetchDeviceSuccess = (response) => {
  return {
    type: FETCH_DEVICE_SUCCESS,
    payload: { response },
  };
};

export const fetchDeviceFailure = (error) => {
  return {
    type: FETCH_DEVICE_FAILURE,
    payload: { error },
  };
};

export function fetchDevice() {
  return (dispatch) => {
    dispatch(fetchDeviceBegin());
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    return axios
      .get(`${server}/api/getDevicesForUser`)
      .then((response) => {
        dispatch(fetchDeviceSuccess(response));
        return response;
      })
      .catch((error) => dispatch(fetchDeviceFailure(error)));
  };
}

export const updateDeviceDisplay = (device) => {
  return Action.PUT(UPDATE_DEVICE_DISPLAY_PREFIX, `/api/devices/${device.device_id}/display`, device, true);
};

//Fetch Models based on User
export const fetchModelsBegin = () => {
  return {
    type: FETCH_MODELS_BEGIN,
  };
};

export const fetchModelsSuccess = (response) => {
  return {
    type: FETCH_MODELS_SUCCESS,
    payload: { response },
  };
};

export const fetchModelsFailure = (error) => {
  return {
    type: FETCH_MODELS_FAILURE,
    payload: { error },
  };
};

export function fetchModels(userIdSql) {
  return (dispatch) => {
    dispatch(fetchModelsBegin());
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    return axios
      .get(`${server}/api/getModelsForUser/${userIdSql}`)
      .then((response) => {
        dispatch(fetchModelsSuccess(response));
        return response;
      })
      .catch((error) => dispatch(fetchModelsFailure(error)));
  };
}

//Updating a Device
export const updateDeviceBegin = () => {
  return {
    type: UPDATE_DEVICE_BEGIN,
  };
};

export const updateDeviceSuccess = (device) => {
  return {
    type: UPDATE_DEVICE_SUCCESS,
    payload: { device },
  };
};

export const updateDeviceFailure = (error) => {
  return {
    type: UPDATE_DEVICE_FAILURE,
    payload: { error },
  };
};

export function updateDevice(device) {
  return (dispatch) => {
    // Only run dispatch on non-null devices
    if (!device) return;

    dispatch(updateDeviceBegin());

    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = { Authorization: `${authToken}` };

    // Verify data
    if (!device.cycle_time) {
      console.error('Attempted to update device with null cycle_time value.');
      return dispatch(updateDeviceFailure('No cycle time given'));
    }
    return axios
      .post(`${server}/api/updateDevice`, {
        device_id: device.device_id.toString(),
        device_name: device.device_name.toString(),
        cycle_time: device.cycle_time.toString(),
        sync_time: device.sync_time.toString(),
        // Allow for null group ID to repr no group
        group_id: device.group_id?.toString() || null,
        // Allow for null location
        location: device.location_name?.toString() || null,
        lat: device.latitude?.toString() || null,
        long: device.longitude?.toString() || null,
      })
      .then((response) => {
        dispatch(updateDeviceSuccess(device));
        return response;
      })
      .catch((error) => dispatch(updateDeviceFailure(error)));
  };
}

//Fetch deployed Models
export const fetchDeployedModelBegin = () => {
  return {
    type: FETCH_DEPLOYED_MODEL_BEGIN,
  };
};

export const fetchDeployedModelSuccess = (response) => {
  return {
    type: FETCH_DEPLOYED_MODEL_SUCCESS,
    payload: { response },
  };
};

export const fetchDeployedModelFailure = (error) => {
  return {
    type: FETCH_DEPLOYED_MODEL_FAILURE,
    payload: { error },
  };
};

export function fetchDeployedModel() {
  return (dispatch) => {
    dispatch(fetchDeployedModelBegin());
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    return axios
      .get(
        `${server}/api/getDeployedModels`
      )
      .then((response) => {
        dispatch(fetchDeployedModelSuccess(response));
        return response;
      })
      .catch((error) => dispatch(fetchDeployedModelFailure(error)));
  };
}

//Updating a Model
export const updateModelBegin = () => {
  return {
    type: UPDATE_MODEL_BEGIN,
  };
};

export const updateModelSuccess = (model) => {
  return {
    type: UPDATE_MODEL_SUCCESS,
    payload: { model },
  };
};

export const updateModelFailure = (error) => {
  return {
    type: UPDATE_MODEL_FAILURE,
    payload: { error },
  };
};

export function updateModel(model) {
  return (dispatch) => {
    if (!model) return;

    dispatch(updateModelBegin());
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    return axios
      .post(`${server}/api/updateModel`, {
        alg_id: model.alg_id.toString(),
        alg_name: model.alg_name.toString(),
        alg_description: model.alg_description.toString(),
        send_all_images: model.send_all_images.toString(),
      })
      .then((response) => {
        console.log(response);
        dispatch(updateModelSuccess(model));
        return response;
      })
      .catch((error) => dispatch(updateModelFailure(error)));
  };
}

export const updateModelNotifsSuccess = (
  alg_id,
  send_email,
  send_sms,
  cell_image,
  sat_image
) => {
  return {
    type: UPDATE_MODEL_NOTIFS_SUCCESS,
    payload: { alg_id, send_email, send_sms, cell_image, sat_image },
  };
};

export const updateModelNotifsBegin = () => {
  return {
    type: UPDATE_MODEL_NOTIFS_BEGIN,
  };
};

export const updateModelNotifsFailure = (error) => {
  return {
    type: UPDATE_MODEL_NOTIFS_FAILURE,
    payload: { error },
  };
};

export function updateModelNotifs(
  algId,
  sendEmail,
  sendSms,
  cellImage,
  satImage
) {
  return (dispatch) => {
    dispatch(updateModelNotifsBegin());
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    return axios
      .post(`${server}/api/modelNotifs`, {
        alg_id: algId,
        send_email: sendEmail,
        send_sms: sendSms,
        cell_image: cellImage,
        sat_image: satImage,
      })
      .then((response) => {
        console.log(response);
        dispatch(
          updateModelNotifsSuccess(
            algId,
            sendEmail,
            sendSms,
            cellImage,
            satImage
          )
        );
        return response;
      })
      .catch((error) => dispatch(updateModelNotifsFailure(error)));
  };
}

//deleteModel
export const deleteModelBegin = () => {
  return {
    type: DELETE_MODEL_BEGIN,
  };
};

export const deleteModelSuccess = (model) => {
  return {
    type: DELETE_MODEL_SUCCESS,
    payload: { model },
  };
};

export const deleteModelFailure = (error) => {
  return {
    type: DELETE_MODEL_FAILURE,
    payload: { error },
  };
};

export function deleteModel(model) {
  return (dispatch) => {
    if (model) {
      dispatch(deleteModelBegin());
      // console.log(model.alg_name.toString())
      const authToken = localStorage.getItem('AuthToken');
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      return axios
        .post(`${server}/api/deleteModel`, {
          alg_id: model.alg_id.toString(),
        })
        .then((response) => {
          console.log(response);
          dispatch(deleteModelSuccess(model));
          return response;
        })
        .catch((error) => dispatch(deleteModelFailure(error)));
    } else {
      return;
    }
  };
}

// Update deployed status
export const updateDeployBegin = () => {
  return {
    type: UPDATE_DEPLOY_BEGIN,
  };
};

export const updateDeploySuccess = (deploy) => {
  return {
    type: UPDATE_DEPLOY_SUCCESS,
    payload: { deploy },
  };
};

export const updateDeployFailure = (error) => {
  return {
    type: UPDATE_DEPLOY_FAILURE,
    payload: { error },
  };
};

export function updateDeploy(deploy) {
  return (dispatch) => {
    if (deploy) {
      dispatch(updateDeployBegin());
      //console.log("actions: " + deploy.alg_id.toString() + " " + deploy.deployed_status.toString());
      const authToken = localStorage.getItem('AuthToken');
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      return axios
        .post(`${server}/api/updateDeploy`, {
          alg_id: deploy.alg_id.toString(),
          device_id: deploy.device_id.toString(),
          status: deploy.deployed_status.toString(),
        })
        .then((response) => {
          console.log(response);
          dispatch(updateDeploySuccess(deploy));
          return response;
        })
        .catch((error) => dispatch(updateDeployFailure(error)));
    } else {
      return;
    }
  };
}

// Update deployed status
export const updateDeployGroupDevicesBegin = () => {
  return {
    type: UPDATE_DEPLOY_GROUP_DEVICES_BEGIN,
  };
};

export const updateDeployGroupDevicesSuccess = (devIds, deploy) => {
  return {
    type: UPDATE_DEPLOY_GROUP_DEVICES_SUCCESS,
    payload: { devIds: devIds, deploy: deploy },
  };
};

export const updateDeployGroupDevicesFailure = (error) => {
  return {
    type: UPDATE_DEPLOY_GROUP_DEVICES_FAILURE,
    payload: { error },
  };
};

export function updateDeployGroupDevices(deploy) {
  return (dispatch) => {
    if (!deploy) return;
    dispatch(updateDeployGroupDevicesBegin());
    // console.log(Deploy.alg_name.toString())
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    return axios
      .post(`${server}/api/updateDeployGroupDevices`, {
        group_id: deploy.group_id.toString(),
        alg_id: deploy.alg_id.toString(),
        status: deploy.deployed_status.toString(),
      })
      .then((response) => {
        dispatch(updateDeployGroupDevicesSuccess(response.data, deploy));
      })
      .catch((error) => dispatch(updateDeployGroupDevicesFailure(error)));
  };
}

export const fetchModelNotifsBegin = () => {
  return {
    type: FETCH_MODEL_NOTIFS_BEGIN,
  };
};

export const fetchModelNotifsSuccess = (response) => {
  return {
    type: FETCH_MODEL_NOTIFS_SUCCESS,
    payload: { response },
  };
};

export const fetchModelNotifsFailure = (error) => {
  return {
    type: FETCH_MODEL_NOTIFS_FAILURE,
    payload: { error },
  };
};

export function fetchModelNotifs(userId) {
  return (dispatch) => {
    dispatch(fetchModelNotifsBegin());
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    return axios
      .get(`${server}/api/userModelNotifs/${userId}`)
      .then((response) => {
        console.log('FETCHED USER NOTIFS THROUGH API', response);
        dispatch(fetchModelNotifsSuccess(response));
        return response;
      })
      .catch((error) => dispatch(fetchModelNotifsFailure(error)));
  };
}
