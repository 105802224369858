import { Action } from '../actions/actionsShared';
import {
  FETCH_MARKETPLACE_MODELS_PREFIX
} from '../actions/marketplaceActions';

const initialState = {
  marketplaceModels: []
};

const modelMarketplaceReducer = (state = initialState, action) => {
  switch (action.type) {
    // Marketplace Models
    case `${FETCH_MARKETPLACE_MODELS_PREFIX}${Action.BEGIN_SUFFIX}`:
      return {
        ...state,
        result: 'BEGINNING',
      };
    case `${FETCH_MARKETPLACE_MODELS_PREFIX}${Action.SUCCESS_SUFFIX}`:
      return {
        ...state,
        marketplaceModels: action.payload.data.data.message,
        result: 'SUCCESS',
      };
    case `${FETCH_MARKETPLACE_MODELS_PREFIX}${Action.FAILURE_SUFFIX}`:
      return {
        ...state,
        result: 'FAILURE',
      };
    default:
      return state;
  }
};

export default modelMarketplaceReducer;
