import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  deleteGroup, fetchGroups
} from '../../actions/groupActions';
import {
  fetchDevice
} from '../../actions/deviceActions';


export default function DeleteGroup(props) {
  const { group } = props;
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  /**
   * The callback function for when the user confirms they want to delete the group.
   * Begins the call to the backend to delete the group and closes the Popup.
   */
  const handleConfirm = () => {
    // Delete the group
    dispatch(deleteGroup(group))
    // Feth any updates to devices to reflect any changes to device.group_id on the front-end
      .then(() => {
        const userIdSql = localStorage.getItem('userId');
        dispatch(fetchDevice(userIdSql));
        dispatch(fetchGroups(userIdSql));
      });

    setOpen(false);
  }

  return (
    <React.Fragment>
      <IconButton onClick={() => setOpen(true)}>
        <DeleteIcon/>
      </IconButton>

      <Dialog open={open} onClose={() => setOpen(false)}>

        {/* Title of the Popup */}
        <DialogTitle> Delete Group </DialogTitle>

        {/* The body of the Popup */}
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this group? 
          </DialogContentText>
        </DialogContent>

        {/* The confirmation options */}
        <DialogActions>
          <Button color="primary" onClick={handleConfirm} variant="contained">
            Yes
          </Button>
          <Button color="primary" onClick={() => setOpen(false)} autoFocus>
            No
          </Button>
        </DialogActions>

      </Dialog>
    </React.Fragment>
  );
}