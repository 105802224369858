import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { createTheme } from '@mui/material';

import { useSelector } from 'react-redux';
import CustomTableContainer from '../Common/Table/CustomTableContainer';
import { HeaderDataTypes } from '../../util/constants';
import UserConfigModel from './modals/userConfig';

const theme_bp = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 680,
      md: 850,
      lg: 1200,
      xl: 1536,
    },
  },
});

const useStyles = makeStyles({

});

export default function UserAccounts(props) {
  const [processedUsers, setProcessedUsers] = useState([]);
  const usersData = useSelector((state) => state.internal.users);
  const [modalData, setModalData] = useState({ open: false});

  const handleClick = (event, row) => {
    const newModalData = {
      open: true,
      userId: row.user_id,
      email: row.email
    };
    setModalData(newModalData);
  }

  const handleClose = () => {
    setModalData({ open: false });
  }

  const defaultHeaders = [
    {
      id: 'user_id',
      type: HeaderDataTypes.NUMBER,
      label: 'User ID',
      display: true
    },
    {
      id: 'email',
      type: HeaderDataTypes.STRING,
      label: 'Email',
      display: true
    },
    {
      id: 'username',
      type: HeaderDataTypes.STRING,
      label: 'Username',
      display: true
    },
    {
      id: 'first_name',
      type: HeaderDataTypes.STRING,
      label: 'First Name',
      display: true,
    },
    {
      id: 'last_name',
      type: HeaderDataTypes.STRING,
      label: 'Last Name',
      display: true
    },
    {
      id: 'phone_number',
      type: HeaderDataTypes.STRING,
      label: 'Phone Number',
      display: true
    }
  ];

  useEffect(() => {
    if (usersData) {
      setProcessedUsers(
        usersData.map((user, index) => {
          return {
            id: index,
            user_id: user.user_id,
            username: user.username,
            email: user.email,
            first_name: user.first_name,
            last_name: user.last_name,
            phone_number: user.phone_number,
          }
        })
      );
    }
    
  }, [usersData]);

  return (
    <>
      <CustomTableContainer
        defaultOrder="desc"
        defaultOrderHeader="user_id"
        headers={defaultHeaders}
        rowData={processedUsers}
        paginationOptions={[5, 10, 25, 50, 100]}
        defaultPaginationOption={25}
        clickAction={handleClick}
      /> 
    
    {
      modalData && modalData.userId && modalData.email && 
      (<UserConfigModel
        userId={modalData.userId}
        open={modalData.open}
        handleClose={handleClose}
        email={modalData.email}
      />)
    }
    </>
  );
}
