import React from 'react';
// import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
// import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
// import Avatar from '@material-ui/core/Avatar';
// import BubbleChart from '@material-ui/icons/BubbleChart';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import FullAppBar from '../components/Appbar/appbarfull';

import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import img from '../images/Gorilla1.png';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    height: 50,
    width: 50,
  },
}));

export default function Demo() {
  const classes = useStyles();

  return (
    <main>
      <FullAppBar />
      <CssBaseline />

      <PerfectScrollbar>
        <div className={classes.paper}>
          {/* <center>
          <div>
            <Avatar className={classes.avatar}>
              <BubbleChart />
            </Avatar>
            <Typography
              style={{ display: 'inline-block' }}
              component="h1"
              variant="h4"
            >
              Model Demo
            </Typography>
          </div>
        </center> */}
          <Card className={classes.root}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="Model Image"
                height="400"
                image={img}
                title="Model Image"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Model Name
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Model Description
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary">
                Upload my own Data
              </Button>
              <Button size="small" color="primary">
                Test with Sample Data
              </Button>
            </CardActions>
            <CardContent>
              <Typography
                style={{ display: 'inline-block' }}
                component="h1"
                variant="h4"
              >
                {' '}
                Model Demo Instructions
              </Typography>
              <p>
                Instructions on how to run a model demonstration to test it's
                accuracy on personal or sample data
              </p>
            </CardContent>
          </Card>
        </div>
      </PerfectScrollbar>
    </main>
  );
}
