import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useMediaQuery } from 'react-responsive'


const useStyles = makeStyles(theme => ({
    root: {
      fontFamily: "Nunito",
    },
    appbarTitle: {
      flexGrow: "1",
      fontSize: "1vw",
    },
    appbarWrapper: {
      width: "80%",
      margin: "0 auto"
    },
    colorText: {
      color: "#34cbc2"
    },
    container: {
      textAlign: "center",
      height: "50vh",
      marginTop: "80px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: '#2bbcd4',

    },
    button: {
        borderRadius: 30,
        background: 'white',
    },
    title: {
      color: "#fff",
      fontSize: "2vw",
    },
    titleMobile: {
      color: "#fff",
      fontSize: "4vw",
    },
    buttontext: {
        fontFamily: "Nunito",
        fontWeight: 'bold',
        color: '#2bbcd4',
    },
    goDown: {
      color: "#2bbcd4",
      fontSize: "4vw"
    }
  }));

  export default function GetStarted() {
    const classes = useStyles();
    
    const isDesktop = useMediaQuery({ query: '(min-width: 740px)'})
    const isMobile = useMediaQuery({ query: '(max-width: 740px)'})

    return (
        <div className={classes.root}>
                <div id="getstarted" className={classes.container}>
                {isDesktop &&
                    <h1 className={classes.title}>
                        See what AI can do for you today
                    </h1>}

                    {isMobile &&
                    <h1 className={classes.titleMobile}>
                        See what AI can do for you today
                    </h1>}
                    <Button className={classes.button} href="/signup" size="large" variant="contained">
                        <span className={classes.buttontext}>Get started for free </span>
                    </Button>
                </div>
        </div>
    );
}