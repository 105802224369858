import React from 'react';

import { withStyles } from '@material-ui/core';
import QuestionMarkIcon from '@material-ui/icons/Help';
import BasicModal from '../../Common/Modals/BasicModal';

const styles = (theme) => ({
  textContainer: {
    wordWrap: "break-word"
  }
});

const HelpIcon = (props) => {
  const { classes } = props;

  const content = (
    <span className={classes.textContainer}>
      <p>If you have any questions about our product, please email us at: </p>
      <a href="mailto:sentinel-support@conservationxlabs.org">
        sentinel-support@conservationxlabs.org</a>
    </span>
  );

  return (
    <BasicModal
      icon={<QuestionMarkIcon/>}
      title="Support Information"
      content={content}
      confirmText="Close"
      noCancel={true}
      iconColor="inherit"
    />
  );
}

export default withStyles(styles)(HelpIcon);
